export const BACKEND_URL = window.location.hostname.startsWith('bi3.') ?
	'https://bi3.red27mobile.com:8080/api' :
	'http://dev5.pareva.umelimited.com:8080/api';

// CAMPAIGNS
export const sublists = [
	{ id: 'recent', name: 'Recent' },
	{ id: 'all', name: 'All' },
	{ id: 'live', name: 'Live' },
	{ id: 'offers', name: 'Offers' },
	{ id: 'inactive', name: 'Inactive' }
];

export const offerTypes = [
	{ name: 'CPA' },
	{ name: 'CC submits' },
	{ name: 'Media Buy' },
	{ name: 'Apps' }
];

export const purposes = [
	{ name: 'campaign' },
	{ name: 'offer' }
];

export const campaignTypes = [
	{ name: 'api' },
	{ name: 'web' },
	{ name: 'app' }
];

export const categories = [
	{ name: 'adult' },
	{ name: 'mainstream' },
	{ name: 'sweepstake' }
];

export const landingPageModes = [
	{ name: 'Schedule' },
	{ name: 'Direct Link' }
];

export const payoutTypes = [
	{ name: 'CPA' },
	{ name: 'CPM' },
	{ name: 'CPC' },
	{ name: 'RevShare' },
	{ name: 'CPL' }
];

export const payoutCurrencies = [
	{ name: 'EUR' },
	{ name: 'GBP' },
	{ name: 'USD' },
	{ name: 'ZAR' }
];

export const paramsModes = [
	{ id: 'DEFAULT', name: 'Default' },
	{ id: 'CUSTOM', name: 'Custom' },
	{ id: 'BLENDED', name: 'Blended' }
];

export const cpaTypes = [
	{ name: 'subscription' },
	{ name: 'billing' }
];

export const cpaSpecialRules = [
	{ name: 'sonic' },
	{ name: 'xnotify' }
];

export const serviceTypes = [
	{ name: 'General' },
	{ name: 'ImpulsePay Paywall' },
	{ name: 'ImpulsePay Games Paywall' },
	{ name: 'ImpulsePay API ESC Template' },
	{ name: 'IMI ESC Template' }
];

export const flows = [
	{ name: 'Click Flow' },
	{ name: 'PIN' },
	{ name: 'Click2SMS' }
];

export const evinaTypes = [
	{ name: 'standard' },
	{ name: 'redirect' }
];

// SCREENSHOTS
export const connectionsForShots = [
	{ name: '3G' },
	{ name: 'WIFI' }
];

// CUSTOMER SERVICE
export const contactMethods = [
	{ id: 'call', name: 'Call' },
	{ id: 'email', name: 'Email' },
	{ id: 'voicemail', name: 'Voicemail' }
];

export const contactReasons = [
	{ id: 'stop_request', name: 'Stop request (unsubscribe)' },
	{ id: 'unknown_sub', name: 'Unknown subscription' },
	{ id: 'child_or_vulnerable', name: 'Child / vulnerable (unauthorised)' },
	{ id: 'misleading_ad', name: 'Misleading ad' },
	{ id: 'content_issue', name: 'Content issue (download issue)' },
	{ id: 'other', name: 'Other' },
	{ id: 'log_request', name: 'Log Request' },
	{ id: 'refund_request', name: 'Refund Request' },
];

export const MCP_USERNAME = 'univer_API_red27';
export const MCP_SECRET = 'MCP-GenAPI0912!';

// NETWORKS
export const regions = [
	{ id: 'AE', name: 'UAE' },
	{ id: 'AT', name: 'Austria' },
	{ id: 'AU', name: 'Australia' },
	{ id: 'BE', name: 'Belgium' },
	{ id: 'BH', name: 'Bahrian' },
	{ id: 'BR', name: 'Brazil' },
	{ id: 'BG', name: 'Bulgaria' },
	{ id: 'CA', name: 'Canada' },
	{ id: 'CH', name: 'Switzerland' },
	{ id: 'CI', name: 'Ivory Coast' },
	{ id: 'CY', name: 'Cyprus' },
	{ id: 'CZ', name: 'Czech Republic' },
	{ id: 'DE', name: 'Germany' },
	{ id: 'DK', name: 'Denmark' },
	{ id: 'EG', name: 'Egypt' },
	{ id: 'ES', name: 'Spain' },
	{ id: 'FE', name: 'FE' },
	{ id: 'FI', name: 'Finland' },
	{ id: 'FR', name: 'France' },
	{ id: 'GR', name: 'Greece' },
	{ id: 'HU', name: 'Hungary' },
	{ id: 'IE', name: 'Ireland' },
	{ id: 'IN', name: 'India' },
	{ id: 'IQ', name: 'Iraq' },
	{ id: 'IT', name: 'Italy' },
	{ id: 'KE', name: 'Kenya' },
	{ id: 'KW', name: 'Kuwait' },
	{ id: 'LV', name: 'Latvia' },
	{ id: 'LU', name: 'Luxembourg' },
	{ id: 'MX', name: 'Mexico' },
	{ id: 'MY', name: 'Malaysia' },
	{ id: 'NL', name: 'Netherlands' },
	{ id: 'NO', name: 'Norway' },
	{ id: 'NZ', name: 'New Zealand' },
	{ id: 'OM', name: 'Oman' },
	{ id: 'PH', name: 'Philippines' },
	{ id: 'PL', name: 'Poland' },
	{ id: 'PT', name: 'Portugal' },
	{ id: 'QA', name: 'Qatar' },
	{ id: 'RU', name: 'Russia' },
	{ id: 'SA', name: 'Saudi Arabia' },
	{ id: 'RS', name: 'Serbia' },
	{ id: 'SK', name: 'Slovakia' },
	{ id: 'SI', name: 'Slovenia' },
	{ id: 'SG', name: 'Singapore' },
	{ id: 'SE', name: 'Sweden' },
	{ id: 'TH', name: 'Thailand' },
	{ id: 'TR', name: 'Turkey' },
	{ id: 'UA', name: 'Ukraine' },
	{ id: 'UK', name: 'UK' },
	{ id: 'US', name: 'USA' },
	{ id: 'ZA', name: 'South Africa' },
	{ id: 'ZI', name: 'Zimbabwe' },
];

// ROUTE53 DOMAINS
export const ukServiceTypes = [
	{ id: 'ume_uk_pareva_imi_disposable;0732172908231CDS;pfi.imimobile.net', name: 'IMI PfI (ESC)' },
	{ id: 'ume_uk_pareva_imi_disposable;3824583922341llun;pfiajax.imimobile.net', name: 'IMI PfI AJAX (X-Stream Subscription)' },

];

export const webfactionApps = [
	{ id: 'ume_uk_mixem_disposable_domains', name: 'API Template' },
	{ id: 'ume_uk_paywall_mixem_disposable', name: 'Paywall' },
];

export const webfactionDomains = [
	{ id: '442570985593168CDS', name: 'API Template Domain' },
	{ id: '769106028404140CDS', name: 'Paywall Site Domain' },
];

// EDIT USER
export const areasTypes = [
	{ name: 'admin' },
	{ name: 'stats' },
	{ name: 'clients' }
];

// PAYOUTS
export const payoutSublists = [
	{ name: 'unfiltered' },
	{ name: 'filtered' }
];

export const payoutTypesPayout = [
	{ name: 'DCB' },
	{ name: 'PSMS' },
	{ name: 'CC' }
];

// CLUBS LIST
export const classifications = [
	{ id: "topless", name: "topless" },
	{ id: "fullnude", name: "fullnude" },
	{ id: "hardcore", name: "hardcore" },
	{ id: "safe", name: "safe" },
	{ id: "bikini", name: "bikini" },
];

export const clubTypes = [
	{ id: "credit", name: "Credits" },
	{ id: "perday", name: "Item per Day" },
	{ id: "subscription", name: "Subscription" },
	{ id: "competition", name: "Competition" },
	{ id: "ppd", name: "PPD" },
	{ id: "admin", name: "Admin" },
	{ id: "api", name: "API" },
];

export const clubServiceTypes = [
	{ id: "Content", name: "Content" },
	{ id: "Competition", name: "Competition" },
	{ id: "Mobiplanet", name: "Melodi" },
];

export const paymentTypes = [
	{ id: "PSMS", name: "PSMS" },
	{ id: "DCB", name: "DCB" },
	{ id: "EXTERNAL", name: "EXTERNAL" },
];

export const servedBys = [
	{ id: "UME", name: "UME" },
	{ id: "ThirdParty", name: "Third Party" },
];

// CPA PARAMS MENU
export const cpaParamsSublists = [
	{ id: 'default', name: 'Default' },
	{ id: 'all', name: 'All' },
	{ id: 'custom', name: 'Custom' }
];

// REFUND EMAILS
export const companyCodes = [
	{ name: 'ume' },
	{ name: 'moonlight' },
	{ name: 'red27' },
	{ name: 'clausmobi' },
];

export const defaultRefundEmails = [
	{ company: 'UME', email: 'admin@umelimited.com' },
	{ company: 'Red27', email: 'support@red27mobile.com' },
	{ company: 'Moonlight', email: 'admin@moonlightmobile.co.uk' },
	{ company: 'Clausmobi', email: 'admin@clausmobi.com' },
];

// METHODS
export const isInt = (value) => {
	if (isNaN(value)) {
		return false;
	}
	let num = Number(value);
	if (num === parseInt(num, 10)) {
		return true;
	}
	return false;
};

export const rand = (min, max) => {
	min = min || 0;
	max = max || Number.MAX_SAFE_INTEGER;

	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertFileToBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.rawFile);

		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export const timeSince = (date) => {
	if (typeof date !== 'object') {
		date = new Date(date);
	}

	var seconds = Math.floor((new Date() - date) / 1000);
	var intervalType;

	var interval = Math.floor(seconds / 31536000);
	if (interval >= 1) {
		intervalType = 'year';
	} else {
		interval = Math.floor(seconds / 2592000);
		if (interval >= 1) {
			intervalType = 'month';
		} else {
			interval = Math.floor(seconds / 86400);
			if (interval >= 1) {
				intervalType = 'day';
			} else {
				interval = Math.floor(seconds / 3600);
				if (interval >= 1) {
					intervalType = "hour";
				} else {
					interval = Math.floor(seconds / 60);
					if (interval >= 1) {
						intervalType = "minute";
					} else {
						interval = seconds;
						intervalType = "second";
					}
				}
			}
		}
	}

	if (interval > 1 || interval === 0) {
		intervalType += 's';
	}

	return interval + ' ' + intervalType;
};

// scrolls to element with specific ref assigned to it
export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const containsIgnoreCase = (parent, child) => {
	if (!(child && child.length)) {
		return true;
	}
	if (!(parent && !!parent.length)) {
		return false;
	}
	return parent.toLowerCase().includes(child.toLowerCase());
};

export const convertPHPDateToJS = (dateTimeStr) => {
	if (dateTimeStr && !!dateTimeStr.length) {
		let timestamp = Date.parse(dateTimeStr);
		if (isNaN(timestamp)) {
			return new Date();
		}
		return new Date(timestamp);
	}
	return new Date();
};

