import React from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
//import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
    noWrapText: {
        whiteSpace: 'nowrap',        
    },
    overflowScroll: {
        overflow: 'auto',
        textOverflow: 'inherit',
    },
    cellId: {
        maxWidth: 20,
    },
    cellMccId: {
        maxWidth: 110,
    },
    cellMccName: {
        maxWidth: 110,
    },
    cellDeveloperToken: {
        maxWidth: 180,
    },
    cellClientId: {
        maxWidth: 110,
    },
    cellClientSecret: {
        maxWidth: 110,
    },
    cellRefreshToken: {
        maxWidth: 110,
    },
}));

const AccountsInfoGoogleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="mccId" label="MCC ID" />
        <TextInput source="mccName" label="MCC Name" />
        <TextInput source="developerToken" label="Developer Token" />
        <TextInput source="clientId" label="Client ID" />
        <TextInput source="clientSecret" label="Client Secret" />
        <TextInput source="refreshToken" label="Refresh Token" />
    </Filter>
);

export const AccountsInfoGoogleList = (props) => {
    const classes = useStyles();
    return (
    <List {...props} title="Google Ads Accounts settings" filters={<AccountsInfoGoogleFilter />}>
        <Datagrid>
            <ReferenceField source="id" label="ID" reference="accounts_info_google" 
                headerClassName={classes.cellId} 
                className={classes.noWrapText}
            >
                <TextField source="id" />
            </ReferenceField>
            <TextField source="mccId" label="MCC ID" 
                headerClassName={classes.cellMccId} 
                className={classes.noWrapText} 
            />
            <TextField source="mccName" label="MCC Name" 
                headerClassName={classes.cellMccName} 
                className={classes.noWrapText} 
            />
            <TextField source="developerToken" label="Developer Token"
                headerClassName={classes.cellDeveloperToken}
                className={classes.noWrapText} 
            />
            <TextField source="clientId" label="Client ID" 
                headerClassName={classes.cellClientId} 
                
                className={"trimmedText spacingShrinked"}
            />
            <TextField source="clientSecret" label="Client Secret" 
                headerClassName={classes.cellClientSecret}
                
                className={"trimmedText spacingShrinked"}
            />
            <TextField source="refreshToken" label="Refresh Token" 
                headerClassName={classes.cellRefreshToken} 
                
                className={"trimmedText spacingShrinked"}
            />
            <EditButton basePath="/accounts_info_google" />
        </Datagrid>
    </List>
    );
};

const AccountsInfoGoogleForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" /> }
        <TextInput source="mccId" label="MCC ID" />
        <TextInput source="mccName" label="MCC Name" />
        <TextInput source="developerToken" label="Developer Token" validate={[required()]} />
        <TextInput source="clientId" label="Client ID" validate={[required()]} />
        <TextInput source="clientSecret" label="Client Secret" validate={[required()]} />
        <TextInput source="refreshToken" label="Refresh Token" validate={[required()]} />
    </SimpleForm>
);

const AccountsInfoGoogleTitle = ({ record }) => {
    return <span>Google Ads Account {record ? `#${record.id}` : ''}</span>;
};

export const AccountsInfoGoogleCreate = (props) => (
    <Create title="Create New Google Ads Account" {...props}>
        <AccountsInfoGoogleForm withId={false} redirect="list" />
    </Create>
);

export const AccountsInfoGoogleEdit = (props) => (
    <Edit undoable={false} title={<AccountsInfoGoogleTitle />} {...props}>
        <AccountsInfoGoogleForm withId={true} />
    </Edit>
);
