import React, { useEffect } from 'react';
import { createForm } from 'final-form';
import { useNotify, SimpleForm, TextInput, required } from 'react-admin';
import { BACKEND_URL } from '../common/constants';

export default ({ record, ...props }) => {
    const notify = useNotify();

    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        if (!record) {
            return;
        }

        const obj = { notes: values.notes };

        fetch(BACKEND_URL + '/service_notes/' + record.platform_domain, { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.success) {
                            notify('Service notes were saved sucessfully.');
                        }
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    };

    const form = createForm({ onSubmit: handleSubmit });

    useEffect(() => {
        if (!record) {
            return;
        }

        fetch(BACKEND_URL + '/service_notes/' + record.platform_domain, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                if (json.notes) {
                    form.change('notes', json.notes);
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    }, [form, record, notify]);

    return (
        <SimpleForm {...props} form={form} reference="regions">
            <TextInput source="notes" label="Notes" multiline validate={required()} />
        </SimpleForm>
    );
};
