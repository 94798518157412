import React, { forwardRef } from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import UserIcon from '@material-ui/icons/Person';

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to={'/users/' + localStorage.getItem('uid')}
        primaryText="Your Account"
        leftIcon={<UserIcon />}
        onClick={onClick} // close the menu on click
    />
));

export default (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

