import React from 'react';
import { Link } from 'react-router-dom';
import { List, Datagrid, Pagination, TextField, ReferenceField, Filter, TextInput, NumberInput, SelectInput, ReferenceInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const AdhocQueryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="name" label="Name" />
        <ReferenceInput source="database_connection" label="Database Connection" reference="db_connections" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
    </Filter>
);

const RunQueryButton = ({ record, basePath, inList, ...props}) => (
    <Button {...props} color="primary" variant={inList? 'text' : 'contained'} component={Link} to={`/run_adhoc_query/${record.id}`} startIcon={<PlayArrowIcon />}>Run Query</Button>
);

const AdhocQueryPagination = (props) => <Pagination {...props} rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]} />;

export const AdhocQueryList = (props) => (
    <List {...props} title="AdHoc Queries" pagination={<AdhocQueryPagination />} filters={<AdhocQueryFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" label="ID"/>
            <TextField source="name" label="Name" />
            <ReferenceField source="database_connection" label="Database Connection" reference="db_connections" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <EditButton basePath="/adhoc_queries" />
            <RunQueryButton inList={true} />
        </Datagrid>
    </List>
);

const AdhocQueryForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <RunQueryButton inList={false} /> }
        { withId && <TextInput disabled source="id" label="ID" /> }
        <TextInput source="name" label="Name" validate={[required()]}/>
        <ReferenceInput source="database_connection" label="Database Connection" reference="db_connections" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="query" label="Query" multiline rows={10} columns={50} validate={[required()]} />
        <NumberInput source="priv_level" label="Priv Level" validate={[required()]} />
    </SimpleForm>
);

const AdhocQueryTitle = ({ record }) => {
    return <span>Adhoc Query {record ? `"${record.name}"` : ''}</span>;
};

export const AdhocQueryCreate = (props) => (
    <Create title="Create New Adhoc Query" {...props}>
        <AdhocQueryForm withId={false} redirect="list" />
    </Create>
);

export const AdhocQueryEdit = (props) => (
    <Edit undoable={false} title={<AdhocQueryTitle />} {...props}>
        <AdhocQueryForm withId={true} />
    </Edit>
);
