import React, { useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import MySubMenu from './MySubMenu';
import DashboardIcon from '@material-ui/icons/Dashboard';
//import ViewListIcon from '@material-ui/icons/ViewList';
import BallotIcon from '@material-ui/icons/Ballot';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import LanguageIcon from '@material-ui/icons/Language';
import InfoIcon from '@material-ui/icons/Info';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SourceIcon from '@material-ui/icons/BusinessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SsUsersIcon from '@material-ui/icons/PeopleAlt';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import MuiLink from '@material-ui/core/Link';

const MuiLinkRef = forwardRef(({activeClassName, ...rest}, ref) => ( 
    <MuiLink innerRef={ref} {...rest} />
));

const MyMenu = ({ onMenuClick, logout }) => {
    const { permissions } = usePermissions();
    const hasAccess = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
    const hasAccessCustService = permissions && permissions.priv && (permissions.priv >= 6) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    const hasAccessBulkStop = permissions && permissions.priv && (permissions.priv >= 9) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    const hasAccessNetworks = permissions && permissions.priv && (permissions.priv >= 10);
    const hasAccessRoute53Domains = permissions && permissions.priv && (permissions.priv >= 10);
    const hasAccessAdvDomains = permissions && permissions.priv && (permissions.priv >= 10);
    const hasAccessSSUsers = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
    const hasAccessClearTestMsisdn = permissions && permissions.priv && (permissions.priv >= 6) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    const hasAccessUsers = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
    const hasAccessPayouts = permissions && permissions.priv && (permissions.priv >= 10);
    const hasAccessAdhocQueries = permissions && permissions.priv && (permissions.priv >= 10);
    const hasAccessEBEnvironmentIP = permissions && permissions.priv && (permissions.priv >= 10);
    const hasAccessAccountsInfoGoogle = permissions && permissions.priv && (permissions.priv >= 14);
    const hasAccessNotificationsInfoGoogle = permissions && permissions.priv && (permissions.priv >= 14);
    const hasAccessMobileClubsList = permissions && permissions.priv && (permissions.priv >= 5);
    const hasAccessCPAAdvertiser = permissions && permissions.priv && (permissions.priv >= 14);
    const hasAccessCampaignParamsMenu = permissions && permissions.priv && (permissions.priv >= 5);
    const hasAccessCampaignStats = permissions && permissions.priv && (permissions.priv >= 5);
    const hasAccessRefundEmail = permissions && permissions.priv && (permissions.priv >= 6) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    const hasAccessIPAddresses = permissions && permissions.priv && (permissions.priv >= 10);
                
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    
    const [openCampaignAdmin, setOpenCampaignAdmin] = useState(false);
    const [openCustomerCare, setOpenCustomerCare] = useState(false);
    const [openDomains, setOpenDomains] = useState(false);
    const [openInformation, setOpenInformation] = useState(false);
    const [openStats, setOpenStats] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    
    return (
        <div>
            <MenuItemLink
                key="dashboard"
                to="/"
                primaryText="Dashboard"
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

                        
            <MySubMenu 
                handleToggle = {() => { setOpenCampaignAdmin(!openCampaignAdmin); }}
                isOpen = {openCampaignAdmin}
                sidebarIsOpen={open}
                name="Campaign Admin"
                icon={<BallotIcon />}
            >

            { hasAccess && 
                <MenuItemLink
                    key="campaigns"
                    to={{ pathname: "/campaigns", search: `?filter={"sublist":"all"}` }}
                    primaryText="Campaigns"
                    leftIcon={<BallotIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            { hasAccess && 
                <MenuItemLink
                    key="offers"
                    to={{ pathname: '/campaigns', search: `?filter={"sublist":"offers"}` }}
                    primaryText="Offers"
                    leftIcon={<AssignmentIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            { hasAccess && 
                <MenuItemLink
                    key="sources"
                    to="/sources"
                    primaryText="Sources"
                    leftIcon={<SourceIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            { hasAccessSSUsers && 
                <MenuItemLink
                    key="ss_users"
                    to="/ss_users"
                    primaryText="Self-Service Users"
                    leftIcon={<SsUsersIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }          
            { hasAccessAccountsInfoGoogle && 
                <MenuItemLink
                    key="accounts_info_google"
                    to="/accounts_info_google"
                    primaryText="Google Ads Accounts"
                    leftIcon={<GTranslateIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 
            { hasAccessNotificationsInfoGoogle && 
                <MenuItemLink
                    key="notifications_info_google"
                    to="/notifications_info_google"
                    primaryText="Google Ads Notifications"
                    leftIcon={<GTranslateIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 
            { hasAccessCPAAdvertiser && 
                <MenuItemLink
                    key="cpa_advertisers"
                    to="/cpa_advertisers"
                    primaryText="CPA Advertisers"
                    leftIcon={<BallotIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 
            { hasAccessCampaignParamsMenu && 
                <MenuItemLink
                    key="campaign_params_menu"
                    to={{ pathname: "/campaign_params_menu", search: `?filter={"sublist":"default"}` }}
                    primaryText="Campaign Defaults"
                    leftIcon={<BallotIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 

            </MySubMenu>
            

            <MySubMenu 
                handleToggle = {() => { setOpenCustomerCare(!openCustomerCare); }}
                isOpen = {openCustomerCare}
                sidebarIsOpen={open}
                name="Customer Care"
                icon={<HeadsetMicIcon />}
            >

            { hasAccessCustService && 
                <MenuItemLink
                    key="customer_contact_log"
                    to="/customer_contact_log"
                    primaryText="Customer Contact Log"
                    leftIcon={<HeadsetMicIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }            
            { hasAccessCustService && 
                <MenuItemLink
                    key="customer_service"
                    to="/customer_service"
                    primaryText="Customer Service"
                    leftIcon={<HeadsetMicIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            { hasAccessBulkStop && 
                <MenuItemLink
                    key="bulk_stop"
                    to="/bulk_stop"
                    primaryText="Bulk Stop"
                    leftIcon={<HeadsetMicIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            { hasAccessClearTestMsisdn && 
                <MenuItemLink
                    key="clear_test_msisdn"
                    to="/clear_test_msisdn"
                    primaryText="Clear Test MSISDN"
                    leftIcon={<HeadsetMicIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }          
            { hasAccessRefundEmail && 
                <MenuItemLink
                    key="refund_emails"
                    to="/refund_emails"
                    primaryText="Refund Emails"
                    leftIcon={<HeadsetMicIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }

            </MySubMenu>
            
            
            <MySubMenu 
                handleToggle = {() => { setOpenDomains(!openDomains); }}
                isOpen = {openDomains}
                sidebarIsOpen={open}
                name="Domains"
                icon={<LanguageIcon />}
            >

            { hasAccessRoute53Domains && 
                <MenuItemLink
                    key="aws_route53_domains"
                    to="/aws_route53_domains"
                    primaryText="Domains Administration"
                    leftIcon={<LanguageIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }    
            { hasAccessAdvDomains && 
                <MenuItemLink
                    key="advertised_domains_menu"
                    to="/advertised_domains_menu"
                    primaryText="Advertised Domains"
                    leftIcon={<LanguageIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }          

            </MySubMenu>
            
            
            <MySubMenu 
                handleToggle = {() => { setOpenInformation(!openInformation); }}
                isOpen = {openInformation}
                sidebarIsOpen={open}
                name="Information"
                icon={<InfoIcon />}
            >

            { hasAccessEBEnvironmentIP && 
                <MenuItemLink
                    key="eb_environment_ip_list"
                    to="/eb_environment_ip_list"
                    primaryText="EB Environment IPs"
                    leftIcon={<InfoIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 
            { hasAccessMobileClubsList && 
                <MenuItemLink
                    key="mobile_clubs_list"
                    to="/mobile_clubs_list"
                    primaryText="Clubs List"
                    leftIcon={<InfoIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 
            { hasAccessNetworks && 
                <MenuItemLink
                    key="mobile_networks_menu"
                    to="/mobile_networks_menu"
                    primaryText="Networks"
                    leftIcon={<InfoIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
            { hasAccessPayouts && 
                <MenuItemLink
                    key="payouts"
                    to="/payouts"
                    primaryText="Payouts"
                    leftIcon={<InfoIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }            
            { hasAccessIPAddresses &&
                <MenuItemLink
                    key="ip_addresses"
                    to="/ip_addresses"
                    primaryText="IP Addresses"
                    leftIcon={<InfoIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }
                        
            </MySubMenu>
            

            <MySubMenu 
                handleToggle = {() => { setOpenStats(!openStats); }}
                isOpen = {openStats}
                sidebarIsOpen={open}
                name="Stats"
                icon={<AssessmentIcon />}
            >

            { hasAccessCampaignStats && 
                <MenuItemLink
                    key="campaign_stats"
                    to="/campaign_stats"
                    primaryText="Tableau"
                    leftIcon={<AssessmentIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    component={MuiLinkRef}
                    href="https://dub01.online.tableau.com/#/site/cliqdigitaleu/views/BIReport/BIReport?:iid=5"
                />
            } 
            { hasAccessAdhocQueries && 
                <MenuItemLink
                    key="adhoc_queries"
                    to="/adhoc_queries"
                    primaryText="AdHoc Queries"
                    leftIcon={<AssessmentIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            } 
            
            </MySubMenu>
            
            
            <MySubMenu 
                handleToggle = {() => { setOpenSettings(!openSettings); }}
                isOpen = {openSettings}
                sidebarIsOpen={open}
                name="Settings"
                icon={<SettingsApplicationsIcon />}
            >
  
            { hasAccessUsers && 
                <MenuItemLink
                    key="users"
                    to="/users"
                    primaryText="Users"
                    leftIcon={<SettingsApplicationsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            }            
            
            </MySubMenu>
            
            {isXSmall && logout}
        </div>
    );
};

export default withRouter(MyMenu);
