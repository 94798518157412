import React from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput, SelectInput, ReferenceInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import { companyCodes, defaultRefundEmails } from '../common/constants';
import { SelectInputWithTip } from '../components/TooltipComponents';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const RefundEmailFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <ReferenceInput source="aRegion" label="Region" reference="regions" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="aRegion" />
        </ReferenceInput>        
        <ReferenceInput source="aAggregator" label="Aggregator" reference="aggregators" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>        
        <SelectInput source="aCompanyCode" label="Company" choices={companyCodes} optionText="name" optionValue="name" />
        <TextInput source="aEmail" label="Email" />
    </Filter>
);

const RefundMailListComponent = ({children, ...rest}) => (
    <Card {...rest}>
    <CardContent>
        <Typography variant="subtitle2" style={{padding: '1rem'}} align="center">
            The default Refund Emails for each company are:
            {defaultRefundEmails.map((dre, index) => <p key={index}>For <strong>{dre.company}</strong> - <i>{dre.email}</i></p>)}
            You can replace these emails according to the settings below:
        </Typography>
        
        {children}
    </CardContent>
    </Card>
);

export const RefundEmailList = (props) => (
    <List {...props} component={RefundMailListComponent} title="Refund Emails" filters={<RefundEmailFilter />} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="aRegion" label="Region" />
            <ReferenceField source="aAggregator" label="Aggregator" reference="aggregators" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="aCompanyCode" label="Company" />
            <TextField source="aEmail" label="Email" />
            <EditButton basePath="/refund_emails" />
        </Datagrid>
    </List>
);

const RefundEmailForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" label="ID" /> }
        <ReferenceInput source="aRegion" label="Region" reference="regions" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="aRegion" validate={[required()]} />
        </ReferenceInput>        
        <ReferenceInput source="aAggregator" label="Aggregator" reference="aggregators" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInputWithTip optionText="name" title="Leave this empty if you want to apply these settings for ALL aggregators." />
        </ReferenceInput>         
        <SelectInput source="aCompanyCode" label="Company" choices={companyCodes} optionText="name" optionValue="name" validate={[required()]} />
        <TextInput source="aEmail" label="Email" validate={[required()]} />
    </SimpleForm>
);

const RefundEmailTitle = ({ record }) => {
    return <span>Refund Email {record ? `#${record.id}` : ''}</span>;
};

export const RefundEmailCreate = (props) => (
    <Create title="Create New Refund Email" {...props}>
        <RefundEmailForm withId={false} redirect="list" />
    </Create>
);

export const RefundEmailEdit = (props) => (
    <Edit undoable={false} title={<RefundEmailTitle />} {...props}>
        <RefundEmailForm withId={true} />
    </Edit>
);

