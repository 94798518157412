import React from 'react';
import { List, Datagrid, TextField, ReferenceField, ReferenceManyField, SingleFieldList, Filter, 
        TextInput, SelectInput, ReferenceInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const NotificationsInfoGoogleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="accountName" label="Account Name" />
        <TextInput source="clientCustomerId" label="Client Customer ID" />
        <ReferenceInput source="source" label="Source" reference="sources_names" perPage={100000} filter={{ name: 'Google' }} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="accountsInfoId" label="Account" reference="accounts_info_google" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="mccName" optionValue="id" />
        </ReferenceInput>
        <TextInput source="conversionName" label="Conversion Name" />
        <TextInput source="timeZone" label="Time Zone" />
        <TextInput source="notes" label="Notes" />
        <TextInput source="param1" label="Param 1" />
        <TextInput source="param2" label="Param 2" />
        <TextInput source="param3" label="Param 3" />
    </Filter>
);

const NotificationsInfoGoogleListTitle = (props) => (
    <span>Google Ads Notifications settings</span>
);

const ListComponent = ({children, ...rest}) => (
    <Card {...rest}>
        <Typography variant="subtitle1" style={{padding: '1rem'}} align="center">
            These records are used for both Offline Conversion Notifications and CPC Spend extraction using the Google Ads API  
        </Typography>
        
        {children}
    </Card>
);

export const NotificationsInfoGoogleList = (props) => (
    <List {...props} component={ListComponent} title={<NotificationsInfoGoogleListTitle />} filters={<NotificationsInfoGoogleFilter />}>
        <Datagrid>
            <ReferenceField source="id" label="ID" reference="notifications_info_google">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="accountName" label="Account Name" />
            <TextField source="clientCustomerId" label="Client Customer ID" />
            <ReferenceManyField source="source" label="Source" reference="sources" target="name_exact" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceField source="accountsInfoId" label="Account" reference="accounts_info_google">
                <TextField source="mccName" />
            </ReferenceField>
            <TextField source="conversionName" label="Conversion Name" />
            <TextField source="timeZone" label="Time Zone" />
            <TextField source="notes" label="Notes" />
            <TextField source="param1" label="Param 1" />
            <TextField source="param2" label="Param 2" />
            <TextField source="param3" label="Param 3" />
            <EditButton basePath="/notifications_info_google" />
        </Datagrid>
    </List>
);

const NotificationsInfoGoogleForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" /> }
        <TextInput source="accountName" label="Account Name" validate={[required()]} />
        <TextInput source="clientCustomerId" label="Client Customer ID" validate={[required()]} />
        <ReferenceInput source="source" label="Source" reference="sources_names" perPage={100000} filter={{ name: 'Google' }} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput source="accountsInfoId" label="Account" reference="accounts_info_google" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="mccName" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="conversionName" label="Conversion Name" validate={[required()]} />
        <TextInput source="timeZone" label="Time Zone" validate={[required()]} />
        <TextInput source="notes" label="Notes" multiline rows={5}/>
        <TextInput source="param1" label="Param 1" validate={[required()]} />
        <TextInput source="param2" label="Param 2" />
        <TextInput source="param3" label="Param 3" />
    </SimpleForm>
);

const NotificationsInfoGoogleTitle = ({ record }) => {
    return <span>Google Ads Notification {record ? `#${record.id}` : ''}</span>;
};

export const NotificationsInfoGoogleCreate = (props) => (
    <Create title="Create New Google Ads Notification" {...props}>
        <NotificationsInfoGoogleForm withId={false} redirect="list" />
    </Create>
);

export const NotificationsInfoGoogleEdit = (props) => (
    <Edit undoable={false} title={<NotificationsInfoGoogleTitle />} {...props}>
        <NotificationsInfoGoogleForm withId={true} />
    </Edit>
);
