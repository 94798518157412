import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'ra-core';
import { convertFileToBase64 } from './constants';
import { BACKEND_URL } from './constants';

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		});
	}
	const api_token = localStorage.getItem('api_token');
	options.headers.set('Authorization', 'Bearer ' + api_token);
	return fetchUtils.fetchJson(url, options);
};

const myDataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    ...jsonServerProvider(apiUrl, httpClient),

    create: (resource, params) => {
        if (resource !== 'campaign_screenshots') {
            return jsonServerProvider(apiUrl, httpClient).create(resource, params);
        }

        let newPictures = [];
        let url1 = params.data.url1;
        if (url1 && url1.rawFile && (url1.rawFile instanceof File)) {
            newPictures.push(url1);
        }

        let url2 = params.data.url2;
        if (url2 && url2.rawFile && (url2.rawFile instanceof File)) {
            newPictures.push(url2);
        }

        let url3 = params.data.url3;
        if (url3 && url3.rawFile && (url3.rawFile instanceof File)) {
            newPictures.push(url3);
        }

        let url4 = params.data.url4;
        if (url4 && url4.rawFile && (url4.rawFile instanceof File)) {
            newPictures.push(url4);
        }

        let url5 = params.data.url5;
        if (url5 && url5.rawFile && (url5.rawFile instanceof File)) {
            newPictures.push(url5);
        }

        if (!(newPictures && newPictures.length)) {
            return jsonServerProvider(apiUrl, httpClient).create(resource, params);
        }

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures => {
                let index = 0;
                if (newPictures.indexOf(url1) >= 0) {
                    url1.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url2) >= 0) {
                    url2.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url3) >= 0) {
                    url3.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url4) >= 0) {
                    url4.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url5) >= 0) {
                    url5.picture64 = base64Pictures[index];
                    index++;
                }

                return jsonServerProvider(apiUrl, httpClient).create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        url1, 
                        url2, 
                        url3, 
                        url4, 
                        url5
                    }
                });

            });
    },
    
    update: (resource, params) => {
        if (resource !== 'campaign_screenshots') {
            return jsonServerProvider(apiUrl, httpClient).update(resource, params);
        }

        let newPictures = [];
        let url1 = params.data.url1;
        if (url1 && url1.rawFile && (url1.rawFile instanceof File)) {
            newPictures.push(url1);
        }

        let url2 = params.data.url2;
        if (url2 && url2.rawFile && (url2.rawFile instanceof File)) {
            newPictures.push(url2);
        }

        let url3 = params.data.url3;
        if (url3 && url3.rawFile && (url3.rawFile instanceof File)) {
            newPictures.push(url3);
        }

        let url4 = params.data.url4;
        if (url4 && url4.rawFile && (url4.rawFile instanceof File)) {
            newPictures.push(url4);
        }

        let url5 = params.data.url5;
        if (url5 && url5.rawFile && (url5.rawFile instanceof File)) {
            newPictures.push(url5);
        }

        if (!(newPictures && newPictures.length)) {
            return jsonServerProvider(apiUrl, httpClient).update(resource, params);
        }

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures => {
                let index = 0;
                if (newPictures.indexOf(url1) >= 0) {
                    url1.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url2) >= 0) {
                    url2.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url3) >= 0) {
                    url3.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url4) >= 0) {
                    url4.picture64 = base64Pictures[index];
                    index++;
                }
                if (newPictures.indexOf(url5) >= 0) {
                    url5.picture64 = base64Pictures[index];
                    index++;
                }

                return jsonServerProvider(apiUrl, httpClient).update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        url1, 
                        url2, 
                        url3, 
                        url4, 
                        url5
                    }
                });

            });
    }
});

export default myDataProvider(BACKEND_URL, httpClient);

