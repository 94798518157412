import React from 'react';
import { List, Datagrid, TextField, ReferenceField, BooleanField, DateField, Filter, 
    TextInput, SelectInput, ReferenceInput, BooleanInput, DateInput, DateTimeInput, 
    ReferenceArrayInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import { TextInputWithTip, /*NumberInputWithTip, SelectInputWithTip,*/ BooleanInputWithTip, AutocompleteArrayInputWithTip } from '../components/TooltipComponents';

const AdvDomainFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn margin='none'/>
        <ReferenceArrayInput margin='dense' source="sources_ids" label="Sources" reference="sources" perPage={100000} filter={{ not_name: 'ALL' }} sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteArrayInputWithTip optionText="name" optionValue="id" 
                title="You can select one or more sources and it will return the advertised domains that contain all those selected sources." />
        </ReferenceArrayInput>        
        <TextInput source="id" label="ID" />
        <TextInput source="link" label="Link" />
        <ReferenceInput source="platform_domain" label="Service" reference="services" perPage={100000} /*sort={{ field: 'name', order: 'ASC' }}*/>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <BooleanInput source="should_be_checked" label="Should be checked?" defaultValue={false} />
        {/*<BooleanInput source="is_https" label="Is HTTPS?" defaultValue={false} />*/}
        <BooleanInput source="is_smartlink" label="Is a Smartlink?" defaultValue={false} />        
        <DateInput source="created_at" label="Created At" />
        <ReferenceInput source="created_by" label="Created By" reference="users" perPage={100000} sort={{ field: 'fullname', order: 'ASC' }}>
            <SelectInput optionText="fullname" optionValue="id" /> 
        </ReferenceInput>
    </Filter>
);

export const AdvDomainList = (props) => (
    <List {...props} title="Advertised Domains" filters={<AdvDomainFilter />}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="link" label="Link" />
            <ReferenceField source="platform_domain" label="Service" reference="services" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="should_be_checked" label="Should be checked?" />
            <TextField source="last_check_result" label="Last check result" />
            {/*<BooleanField source="is_https" label="Is HTTPS?" />*/}
            <BooleanField source="is_smartlink" label="Is a Smartlink?" /> 
            <DateField source="created_at" showTime label="Created At" />
            <ReferenceField source="created_by" label="Created By" reference="users">
                <TextField source="fullname" />
            </ReferenceField>
            <EditButton basePath="/advertised_domains_menu" />
        </Datagrid>
    </List>
);

const AdvDomainForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" /> }
        <TextInput source="link" label="Link" validate={[required()]} />
        <ReferenceInput source="platform_domain" label="Service" reference="services" perPage={100000} /*sort={{ field: 'name', order: 'ASC' }}*/>
            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <BooleanInputWithTip source="should_be_checked" label="Should be checked?" 
            title="Should the cron job include this domain for checks? Normally yes." />
        <TextInputWithTip source="last_check_result" disabled label="Last check result" 
            title="Set by the cron job to show current status of this domain" />
        {/*<BooleanInputWithTip source="is_https" label="Is HTTPS?" 
            title="Whether HTTPS is expected to be supported on this domain (changes the checks to be more accommodating)" />*/}
        <BooleanInputWithTip source="is_smartlink" label="Is a Smartlink?" 
            title="Is a Smartlink and therefore should be shown for every platform domain in the CM dropdown" />
        { withId && <DateTimeInput source="created_at" disabled label="Created At" /> }
        { withId && <ReferenceInput source="created_by" disabled label="Created By" reference="users">
            <SelectInput optionText="fullname" optionValue="id" /> 
        </ReferenceInput> }
        <ReferenceArrayInput source="sources_ids" label="Sources" reference="sources" perPage={100000} filter={{ not_name: 'ALL' }} sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteArrayInputWithTip optionText="name" optionValue="id" 
                title="Leave empty if you want this domain to be visible to ALL sources. Or select specific sources and this domain will be available ONLY to the selected sources." />
        </ReferenceArrayInput>
    </SimpleForm>
);

const AdvDomainTitle = ({ record }) => {
    return <span>Advertised Domain {record ? `"${record.link}"` : ''}</span>;
};

export const AdvDomainCreate = (props) => (
    <Create title="Create New Advertised Domain" {...props}>
        <AdvDomainForm withId={false} redirect="list" />
    </Create>
);

export const AdvDomainEdit = (props) => (
    <Edit undoable={false} title={<AdvDomainTitle />} {...props}>
        <AdvDomainForm withId={true} />
    </Edit>
);
