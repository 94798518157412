import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { useNotify, usePermissions, fetchStart, fetchEnd, Title, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { BACKEND_URL } from '../common/constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';

export default (props) => {
    const { permissions } = usePermissions();
    
    const hasAccess = permissions && permissions.priv && (permissions.priv >= 10);
    
    const notify = useNotify();
    const dispatch = useDispatch();
    
    const [environments, setEnvironments] = useState([]);
    const [formEBE, setFormEBE] = useState(null);
    
    const EBEnvironmentToolbar = (ebetbprops) => {
        const form = useForm();
    
        useEffect(() => {
            setFormEBE(form);
        }, [form]);
     
        return (
            <Toolbar {...ebetbprops}>
                <SaveButton variant="contained" color="primary" label="Refresh" icon={<RefreshIcon />} />
            </Toolbar>
        );
    };
    
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/eb_environment_ip_list', { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                }
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.success && json.environments) {
                            setEnvironments(json.environments);
                        } else {
                            setEnvironments([]);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });        
            
    };
    
    useEffect(() => {
        if (formEBE) {
            formEBE.submit();
        }
    }, [formEBE]);

    return (
        <Card>
            <Title title="EB Environment IP list" />
            <CardContent>
                { hasAccess && 
                <div>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleSubmit}
                        toolbar={<EBEnvironmentToolbar />}
                    />
                    
                    { environments &&
                        Object.keys(environments).map(name => {
                            let environment = environments[name];
                            return ( 
                                <div key={name}>
                                    <p>{name}</p>
                                    <ul>
                                        { environment &&
                                            Object.keys(environment).map(id => {
                                                let env = environment[id];
                                                return (
                                                    <li key={id}>
                                                        <strong>{id}</strong> : { env.pub_ip } | { env.priv_ip } 
                                                        &nbsp; 
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary"
                                                            component={MuiLink} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer" 
                                                            href={ `http://${ env.priv_ip }:8080/manager/status` }>
                                                                Tomcat (use VPN)
                                                        </Button>
                                                        &nbsp; 
                                                        <Button
                                                            variant="contained" 
                                                            color="primary"
                                                            component={MuiLink} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer" 
                                                            href={ `https://eu-west-1.console.aws.amazon.com/ec2/v2/home?region=eu-west-1#Instances:search=${ id };` }>
                                                                Instance
                                                        </Button>
                                                    </li> 
                                                ); 
                                            } ) 
                                        }
                                    </ul>
                                </div> 
                            ); 
                        } ) 
                    }
                </div> }
            </CardContent>
        </Card>
    );
};
