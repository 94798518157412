import React from 'react';
import { TextField, EmailField, BooleanField, NumberField, ReferenceField, TextInput, BooleanInput, NumberInput, SelectInput,
    SimpleForm, Create, Edit, List, Datagrid, EditButton, Toolbar, SaveButton, Filter, required } from 'react-admin';
import { TextInputWithTip, NumberInputWithTip, BooleanInputWithTip, AutocompleteArrayInputWithTip } from '../components/TooltipComponents';
import Chip from '@material-ui/core/Chip';
import { areasTypes, regions } from '../common/constants';


const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="fullname" label="Name" />
        <TextInput source="username" label="Username" />
        <TextInput source="email" label="E-mail" />
        <TextInput source="msisdn" label="Mobile Number" />
        <BooleanInput source="receive_sms_alerts" label="Receive SMS alerts" defaultValue={false} />
        <SelectInput source="sms_alerts_regions" label="SMS alerts regions" choices={regions} optionText="id" optionValue="id" />
        <TextInput source="courseaid_email" label="Courseaid Email" />
        <TextInput source="hashcode" label="Hashcode" />
        <BooleanInput source="twofactor_enabled" label="Two factor auth Enabled" defaultValue={false} />
        <TextInput source="notes" label="Notes" />
        <SelectInput source="regions" label="Regions" choices={regions} optionText="id" optionValue="id" />
        <SelectInput source="areas" label="Areas" choices={areasTypes} optionText="name" optionValue="name" />
        <NumberInput source="priv" label="Privilege level" />
        <NumberInput source="max_auth_amount" label="Max Auth Amount" />
        <BooleanInput source="active" label="Is Active" defaultValue={true} />
    </Filter>
);

const AreasField = ({ record, ...rest }) => {
    const areas = (record && record.areas && !!record.areas.length)? record.areas.split(',') : [];
    return (
        <div style={{ display: 'inline-flex' }}>
            {areas.map(a => <Chip key={a} label={a} style={{ margin: '0 2px' }} />)}
        </div>
    );
};

export const UserList = (props) => (
    <List {...props} filters={<UserFilter />} bulkActionButtons={false}>
        <Datagrid>
            <NumberField source="id" label="ID" />
            <ReferenceField source="id" label="Name" reference="users">
                <TextField source="fullname" />
            </ReferenceField>            
            <TextField source="username" label="Username" />
            <EmailField source="email" label="E-mail" />
            <TextField source="msisdn" label="Mobile Number" />
            <BooleanField source="receive_sms_alerts" label="Receive SMS alerts" />
            <TextField source="sms_alerts_regions" label="SMS alerts regions" />
            {/*<EmailField source="courseaid_email" label="Courseaid Email" />
            <TextField source="hashcode" label="Hashcode" />
            <NumberField source="max_auth_amount" label="Max Auth Amount" />*/}
            <BooleanField source="twofactor_enabled" label="Two factor auth Enabled" />
            <TextField source="notes" label="Notes" />
            <TextField source="regions" label="Regions" />
            <AreasField label="Areas" />
            <NumberField source="priv" label="Privilege level" />
            <BooleanField source="active" label="Is Active" />
            
            <EditButton basePath="/users" />
        </Datagrid>
    </List>
);

const PasswordWarningMessage = (props) => (
    <label><strong>PLEASE LEAVE "PASSWORD" AND "REPEAT PASSWORD" EMPTY IF YOU DON'T WANT TO CHANGE THE PASSWORD!<br/>
        YOU DON'T NEED TO ENTER THE PASSWORD IF YOU WANT TO CHANGE SOME OTHER SETTINGS OF THIS USER.</strong></label>
);

const areasFormat = (src) => (src && !!src.length)? src.split(',') : [];
const areasParse = (input) => (input && !!input.length)? input.join(',') : '';

const UserForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" label="Person ID" /> }
        <TextInput source="fullname" validate={[required()]} />
        <TextInput source="username" validate={[required()]} />
        <TextInput source="email" label="Email" />
        <TextInputWithTip source="msisdn" label="Mobile Number" 
            title="If one is given, then daemon error notifications will be received by this user." />
        <BooleanInputWithTip source="receive_sms_alerts" label="Receive SMS alerts"
            title="If selected, user will receive alerts by SMS from Daemon Monitoring" />
        <TextInputWithTip source="sms_alerts_regions" label="SMS alerts regions" 
            title="List, comma-separated, the regions which this user should receive alerts for. Leave blank for all. Use ALL for only generalised alerts" />
        <TextInput source="courseaid_email" label="Courseaid Email" />
        <TextInput source="hashcode" label="Hashcode" />
        { withId && <PasswordWarningMessage /> }
        <TextInputWithTip source="password" label="Password" type="password"
            title="Password can be changed by and admin, but not recovered" />
        <TextInput source="password_confirmation" label="Repeat password" type="password" />
        <BooleanInput source="twofactor_enabled" label="Two factor auth Enabled" />
        <TextInput source="notes" label="Notes" multiline />
        <TextInputWithTip source="regions" label="Regions" 
            title="Which regions the user is allowed to view (comma-separated)" />
        <AutocompleteArrayInputWithTip 
            source="areas" 
            label="Areas" 
            choices={areasTypes} 
            optionText="name" 
            optionValue="name" 
            format={areasFormat} 
            parse={areasParse}
            title="The areas of CourseAid in which this person can operate" 
        />
        <NumberInputWithTip source="priv" label="Privilege level" validate={[required()]} 
            title="Admin-assigned privilege level" />
        <NumberInput source="max_auth_amount" label="Max Auth Amount" />
        <BooleanInputWithTip source="active" label="Is Active" defaultValue={true} 
            title="You can activate / deactivate this user with this option" />
    </SimpleForm>
);

const UserTitle = ({ record }) => {
    return <span>User {record ? `"${record.fullname}"` : ''}</span>;
};

const UserToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);


export const UserCreate = (props) => (
    <Create title="Create New User" {...props}>
        <UserForm withId={false} redirect="list" />
    </Create>
);


export const UserEdit = (props) => {
    const { id } = props;
    if (id && (id === localStorage.getItem('uid'))) {
        return (
            <Edit undoable={false} title={<UserTitle />} successMessage="User updated. Please logout and login again to apply the changes!" {...props}>
                <UserForm withId={true} redirect={false} toolbar={<UserToolbar />} />
            </Edit>
        );
    } else { 
        return (
            <Edit undoable={false} title={<UserTitle />} {...props}>
                <UserForm withId={true} />
            </Edit>
        );
    }
};
