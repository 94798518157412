import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BACKEND_URL } from '../common/constants';

export default ({ record, ...props }) => {
    const [cpaLog, setCpaLog] = useState([]);

    const notify = useNotify();

    useEffect(() => {
        if (!record) {
            return;
        }

        let country_code; //filler_digits
	
        switch (record.region) {
            case 'AU':
                country_code = '61';
                //filler_digits = '52'; // to get the length right (matching a real MSISDN)
                break;
            case 'CH':
                country_code = '41';
                //filler_digits = '34';
                break;
            case 'ES':
                country_code = '34';
                //filler_digits = '34';
                break;
            case 'IE':
                country_code = '353';
                //filler_digits = '2';
                break;
            case 'IN':
                country_code = '91';
                //filler_digits = '34';
                break;
            case 'IT':
                country_code = '39';
                //filler_digits = '341';
                break;
            case 'KE':
                country_code = '254';
                //filler_digits = '341';
                break;
            case 'MX':
                country_code = '52';
                //filler_digits = '34';
                break;
            case 'NO':
                country_code = '47';
                //filler_digits = '341';
                break;
            case 'PO':
                country_code = '48';
                //filler_digits = '34';
                break;
            case 'PT':
                country_code = '383';
                //filler_digits = '34';
                break;
            case 'SE':
                country_code = '46';
                //filler_digits = '341';
                break;
            case 'UK':
                country_code = '44';
                //filler_digits = '34';
                break;
            case 'ZA':
                country_code = '27';
                //filler_digits = '34';
                break;
            case 'ZI':
            default:
                country_code = '263';
                //filler_digits = '341';
                break;
        }

        if (record.platform_aunique && record.platform_aunique.length) {
            fetch(BACKEND_URL + '/get_fake_cpa_log?platform_aunique=' + record.platform_aunique + '&country_code=' + country_code, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => response.json())
                .then(json => {
                    if (json && json.length) {
                        setCpaLog(json);
                    } else {
                        setCpaLog([]);
                    }
                })
                .catch(e => { 
                    notify(e.message, 'warning'); 
                });
        }
    }, [record, notify]);

    const useStyles = makeStyles(theme => ({
        root: {
            width: '500px'
        }
    }));

    const classes = useStyles();

    if (cpaLog && cpaLog.length) {
    return (
        <div className={classes.root}>
            <Link name="cpaLog" /><h3>CPA tests on this campaign this month</h3>
            <div style={{ overflowX: 'auto' }}>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {Object.keys(cpaLog[0]).map(k => 
                                <TableCell key={k}>{k}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { cpaLog.map(log => (
                        <TableRow key={log.id}>
                            {Object.keys(log).map(k =>
                                <TableCell key={k}>{log[k]}</TableCell>
                            )}
                        </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
    } else {
        return null;
    }
};
