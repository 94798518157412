import React from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin';
import myDataProvider from './common/myDataProvider';
import myAuthProvider from './common/myAuthProvider';
import MyLoginPage from './common/MyLoginPage';
import MyLayout from './common/MyLayout';
import myRoutes from './common/myRoutes';
import Dashboard from './components/Dashboard';
import { CampaignList, CampaignCreate, CampaignEdit } from './views/campaigns';
import { SourceList, SourceCreate, SourceEdit } from './views/sources';
import { CampaignParamList, CampaignParamCreate, CampaignParamEdit } from './views/campaign_params';
import { CampaignScreenshotList, CampaignScreenshotCreate, CampaignScreenshotEdit } from './views/campaign_screenshots';
import { UserList, UserCreate, UserEdit } from './views/users';
import { ContactLogList } from './views/customer_contact_log.jsx';
import CustomerService from './components/CustomerService';
import { MobileNetworkList, MobileNetworkCreate, MobileNetworkEdit } from './views/mobile_networks';
import AWSRoute53Domains from './components/AWSRoute53Domains';
import { AdvDomainList, AdvDomainCreate, AdvDomainEdit } from './views/advertised_domains';
import { SSUserList, SSUserEdit } from './views/ss_users';
import { PayoutList, PayoutCreate, PayoutEdit } from './views/payouts';
import { AdhocQueryList, AdhocQueryCreate, AdhocQueryEdit } from './views/adhoc_queries';
import { AccountsInfoGoogleList, AccountsInfoGoogleCreate, AccountsInfoGoogleEdit } from './views/accounts_info_google';
import { NotificationsInfoGoogleList, NotificationsInfoGoogleCreate, NotificationsInfoGoogleEdit } from './views/notifications_info_google';
import { MobileClubList } from './views/mobile_clubs_list';
import { CPAAdvertiserList, CPAAdvertiserCreate, CPAAdvertiserEdit } from './views/cpa_advertisers';
import { RefundEmailList, RefundEmailCreate, RefundEmailEdit } from './views/refund_emails';
import { IPAddressesList } from './views/ip_addresses';

function App() {
/*  useEffect(() => {
    const dataProvider = jsonServerProvider('http://34.254.161.79:8080/api');

    dataProvider.getList('sources', {
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'name', order: 'ASC' },
      filter: { name: '12' },
    });

    dataProvider.getOne('sources', { id: 1 });

    dataProvider.getMany('sources', { ids: [1, 2, 3] });

    dataProvider.getManyReference('sources', {
      target: 'name',
      id: '12',
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'name', order: 'DESC' }
    });
  });*/

  return (
    <Admin dataProvider={myDataProvider} authProvider={myAuthProvider} loginPage={MyLoginPage} dashboard={Dashboard} customRoutes={myRoutes} layout={MyLayout}>
        { (permissions) => {
            const hasAccess = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
            const hasAccessCustService = permissions && permissions.priv && (permissions.priv >= 6) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
            const hasAccessNetworks = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessRoute53Domains = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessAdvDomains = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessSSUsers = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
            const hasAccessSSUsersEdit = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessUsers = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
            const hasAccessUsersEdit = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessPayouts = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessPayoutsEdit = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);
            const hasAccessAdhocQueries = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessAccountsInfoGoogle = permissions && permissions.priv && (permissions.priv >= 14);
            const hasAccessAccountsInfoGoogleEdit = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessNotificationsInfoGoogle = permissions && permissions.priv && (permissions.priv >= 14);
            const hasAccessNotificationsInfoGoogleEdit = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessMobileClubsList = permissions && permissions.priv && (permissions.priv >= 5);
            const hasAccessCPAAdvertiser = permissions && permissions.priv && (permissions.priv >= 14);
            const hasAccessCPAAdvertiserEdit = permissions && permissions.priv && (permissions.priv >= 10);
            const hasAccessCampaignParamsMenu = permissions && permissions.priv && (permissions.priv >= 5);
            const hasAccessRefundEmail = permissions && permissions.priv && (permissions.priv >= 6) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
            
            return ([
                <Resource name="campaigns" list={hasAccess? CampaignList : null} create={hasAccess? CampaignCreate : null} edit={hasAccess? CampaignEdit : null} />,
                <Resource name="sources" list={hasAccess? SourceList : null} create={hasAccess? SourceCreate : null} edit={hasAccess? SourceEdit : null} />,
                <Resource name="users" list={hasAccessUsers? UserList : null} create={hasAccessUsersEdit? UserCreate : null} edit={hasAccessUsersEdit? UserEdit : null} />,
                <Resource name="regions" />,
                <Resource name="services" />,
                <Resource name="advertised_domains" />,
                <Resource name="schedules" />,
                <Resource name="schedule_landing_pages" />,
                <Resource name="mobile_networks" />,
                <Resource name="domain_landing_pages" />,
                <Resource name="interstitial_landing_pages" />,
                <Resource name="campaign_params" create={hasAccess? CampaignParamCreate : null} edit={hasAccess? CampaignParamEdit : null} />,
                <Resource name="campaigns_for_params" />,
                <Resource name="mobile_clubs_for_params" />,
                <Resource name="campaign_screenshots" list={hasAccess? CampaignScreenshotList : null} create={hasAccess? CampaignScreenshotCreate : null} edit={hasAccess? CampaignScreenshotEdit : null} />,
                <Resource name="mobile_networks_for_screenshots" />,
                <Resource name="campaigns_for_screenshots" />,
                <Resource name="customer_contact_log" list={hasAccessCustService? ContactLogList : null} />,
                <Resource name="customer_service" list={hasAccessCustService? CustomerService : null} />,
                <Resource name="regions_for_networks" />,
                <Resource name="mobile_networks_menu" list={hasAccessNetworks? MobileNetworkList : null} create={hasAccessNetworks? MobileNetworkCreate : null} edit={hasAccessNetworks? MobileNetworkEdit : null} />,
                <Resource name="aws_route53_domains" list={hasAccessRoute53Domains? AWSRoute53Domains : null} />,
                <Resource name="advertised_domains_menu" list={hasAccessAdvDomains? AdvDomainList : null} create={hasAccessAdvDomains? AdvDomainCreate : null} edit={hasAccessAdvDomains? AdvDomainEdit : null} />,
                <Resource name="ss_users" list={hasAccessSSUsers? SSUserList : null} edit={hasAccessSSUsersEdit? SSUserEdit : null} />,
                <Resource name="sources_names" />,
                <Resource name="services_for_cap_rotator" />,
                <Resource name="campaigns_for_cap_rotator" />,
                <Resource name="landing_pages_for_cap_rotator" />,
                <Resource name="payouts" list={hasAccessPayouts? PayoutList : null} create={hasAccessPayoutsEdit? PayoutCreate : null} edit={hasAccessPayoutsEdit? PayoutEdit : null} />,
                <Resource name="aggregators" />,
                <Resource name="mobile_club_currencies" />,
                <Resource name="adhoc_queries" list={hasAccessAdhocQueries? AdhocQueryList : null} create={hasAccessAdhocQueries? AdhocQueryCreate : null} edit={hasAccessAdhocQueries? AdhocQueryEdit : null} />,
                <Resource name="db_connections" />,
                <Resource 
                    name="accounts_info_google" 
                    list={hasAccessAccountsInfoGoogle? AccountsInfoGoogleList : null} 
                    create={hasAccessAccountsInfoGoogleEdit? AccountsInfoGoogleCreate : null} 
                    edit={hasAccessAccountsInfoGoogleEdit? AccountsInfoGoogleEdit : null} />,
                <Resource 
                    name="notifications_info_google" 
                    list={hasAccessNotificationsInfoGoogle? NotificationsInfoGoogleList : null} 
                    create={hasAccessNotificationsInfoGoogleEdit? NotificationsInfoGoogleCreate : null} 
                    edit={hasAccessNotificationsInfoGoogleEdit? NotificationsInfoGoogleEdit : null} />,
                <Resource name="mobile_clubs_list" list={hasAccessMobileClubsList? MobileClubList : null} />,    
                <Resource 
                    name="cpa_advertisers" 
                    list={hasAccessCPAAdvertiser? CPAAdvertiserList : null} 
                    create={hasAccessCPAAdvertiserEdit? CPAAdvertiserCreate : null} 
                    edit={hasAccessCPAAdvertiserEdit? CPAAdvertiserEdit : null} />,
                <Resource 
                    name="campaign_params_menu" 
                    list={hasAccessCampaignParamsMenu? CampaignParamList : null} 
                    create={hasAccess? CampaignParamCreate : null} 
                    edit={hasAccess? CampaignParamEdit : null}
                />,
                <Resource name="campaign_suggestions" />,
                <Resource 
                    name="refund_emails" 
                    list={hasAccessRefundEmail? RefundEmailList : null} 
                    create={hasAccessRefundEmail? RefundEmailCreate : null} 
                    edit={hasAccessRefundEmail? RefundEmailEdit : null}
                />,
                <Resource name="ip_addresses" list={IPAddressesList} />,
                <Resource name="regions_for_country_lookup" />,
            ]);
        }}
    </Admin>
  );
}

export default App;
