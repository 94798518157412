import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, SelectInput, ReferenceInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import { regions } from '../common/constants';

const MobileNetworkFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="aNetworkCode" label="Network" />
        <ReferenceInput source="aRegionCode" label="Region" reference="regions_for_networks" sort={{ field: 'aRegionCode', order: 'ASC' }} perPage={100000}>
            <SelectInput optionText="id" optionValue="id" />
        </ReferenceInput>
        <TextInput source="aAggregatorNetworkCode" label="Aggregator Network" />
        <TextInput source="aIspPatternToMatch" label="ISP Pattern" />
    </Filter>
);

export const MobileNetworkList = (props) => (
    <List className="maxWidth960" {...props} title="Mobile Networks" filters={<MobileNetworkFilter />} sort={{ field: 'aRegionCode', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="aNetworkCode" label="Network" />
            <TextField source="aRegionCode" label="Region"  />
            <TextField source="aAggregatorNetworkCode" label="Aggregator Network" />
            <TextField source="aIspPatternToMatch" label="ISP Pattern" />
            <EditButton basePath="/mobile_networks_menu" />
        </Datagrid>
    </List>
);

const MobileNetworkForm = ({ withId, ...props }) => (
    <SimpleForm {...props} >
        { withId && <TextInput disabled source="id" label="ID" /> }
        <TextInput source="aNetworkCode" label="Network" validate={[required()]} />
        <SelectInput source="aRegionCode" label="Region" choices={regions} optionText="id" optionValue="id" validate={[required()]} /> 
        <TextInput source="aAggregatorNetworkCode" label="Aggregator Network" />
        <TextInput source="aIspPatternToMatch" label="ISP Pattern" />
    </SimpleForm>
);

const MobileNetworkTitle = ({ record }) => {
    return <span>Network {record ? `"${record.aNetworkCode}"` : ''}</span>;
};

export const MobileNetworkCreate = (props) => (
    <Create title="Create New Network" {...props} className="maxWidth960">
        <MobileNetworkForm withId={false} redirect="list" />
    </Create>
);

export const MobileNetworkEdit = (props) => (
    <Edit undoable={false} title={<MobileNetworkTitle />} {...props}>
        <MobileNetworkForm withId={true} />
    </Edit>
);

