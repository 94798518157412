import React from 'react';
import { TextInput, NumberInput, SelectInput, BooleanInput, AutocompleteInput, AutocompleteArrayInput } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

const MyTooltip = ({title, ...rest}) => (
    <Tooltip {...rest} title={<span style={{ fontSize: '2em' }}>{title}</span>} />
);

export const TextInputWithTip = ({title, tipHidden, ...rest}) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextInput {...rest} />
        { !tipHidden && 
            <MyTooltip title={title}>
                <HelpIcon />
            </MyTooltip>
        }
    </div>
);

export const NumberInputWithTip = ({title, tipHidden, ...rest}) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <NumberInput {...rest} />
        { !tipHidden &&
            <MyTooltip title={title}>
                <HelpIcon />
            </MyTooltip>
        }
    </div>
);

export const SelectInputWithTip = ({title, tipHidden, ...rest}) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectInput {...rest} />
        { !tipHidden && 
            <MyTooltip title={title}>
                <HelpIcon />
            </MyTooltip>
        }
    </div>
);

export const BooleanInputWithTip = ({title, tipHidden, ...rest}) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <BooleanInput {...rest} /> 
        { !tipHidden && 
            <MyTooltip title={title}>
                <HelpIcon />
            </MyTooltip>
        }
    </div>
);

export const AutocompleteInputWithTip = ({title, tipHidden, ...rest}) => (
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '500px' }}>
        <AutocompleteInput {...rest} />
        { !tipHidden && 
            <MyTooltip title={title}>
                <HelpIcon />
            </MyTooltip>
        }
    </div>
);

export const AutocompleteArrayInputWithTip = ({title, tipHidden, ...rest}) => (
    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '500px' }}>
        <AutocompleteArrayInput fullWidth {...rest} />
        { !tipHidden && 
            <MyTooltip title={title}>
                <HelpIcon />
            </MyTooltip>
        }
    </div>
);

