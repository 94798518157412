import React, { useEffect, useContext } from 'react';
import { List, Datagrid, TextField, Filter, SelectInput, ReferenceInput, ReferenceArrayInput /*EditButton, SimpleForm, Create, Edit, required*/ } from 'react-admin';
import { AutocompleteArrayInputWithTip } from '../components/TooltipComponents';
import { MyContext } from '../common/MyContext';

const IPAddressesFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput label="Search" source="q" alwaysOn />*/}
        <ReferenceInput source="region" label="Region" reference="regions_for_country_lookup" sort={{ field: 'country_iso_code', order: 'ASC' }} perPage={100000} alwaysOn>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceArrayInput source="networks" label="Networks" reference="mobile_networks" perPage={100000} sort={{ field: 'aNetworkCode', order: 'ASC' }} alwaysOn>
            <AutocompleteArrayInputWithTip optionText="name" optionValue="id" title="Select one or more networks/operators" />
        </ReferenceArrayInput>
    </Filter>
);

export const IPAddressesList = (props) => {
    const myContext = useContext(MyContext);
    useEffect(() => {
        if (myContext) {
            myContext.setShowIndicator(true);
        }
        
        return function cleanup() {
            if (myContext) {
                myContext.setShowIndicator(false);
            }
        };
    }, [myContext]);
    
    return (
    <List {...props} title="IP Addresses" filters={<IPAddressesFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="isp" label="ISP" sortable={false} />
            <TextField source="From" label="From" sortable={false} />
            <TextField source="To" label="To" sortable={false} />
            <TextField source="country_iso_code" label="Country Code" sortable={false} />
            <TextField source="country_name" label="Country Name" sortable={false} />
            <TextField source="continent_code" label="Continent Code" sortable={false} />
            <TextField source="continent_name" label="Continent Name" sortable={false} />
            {/*<EditButton basePath="/ip_addresses" />*/}
        </Datagrid>
    </List>
    );
};

