import { BACKEND_URL } from './constants';

const myAuthProvider = {
    login: ({ username, password }) => {
        return fetch(BACKEND_URL + '/loginUser', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
        .then(response => response.json())
        .then(json => {
            if (json.success) {
                localStorage.setItem('uid', json.id);
                localStorage.setItem('priv', json.priv);
                localStorage.setItem('areas', json.areas);
                localStorage.setItem('api_token', json.api_token);
            } else {
                if (json.active) {
                    throw new Error('Wrong combination of username/password !!!');
                } else {
                    throw new Error('This user had been deactivated. Please contact the administration team to activate it again!');
                }
            }
        });
    },
    logout: () => {
        localStorage.removeItem('uid');
        localStorage.removeItem('priv');
        localStorage.removeItem('areas');
        localStorage.removeItem('api_token');
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('api_token')? 
        Promise.resolve() : 
        Promise.reject(),
    checkError: (error) => {
        /*const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('uid');
            localStorage.removeItem('priv');
            localStorage.removeItem('areas');
            localStorage.removeItem('api_token');
            return Promise.reject();
        }*/
        return Promise.resolve();
    },
    getPermissions: () => {
        const api_token = localStorage.getItem('api_token');
        const priv = localStorage.getItem('priv');
        const areas = localStorage.getItem('areas');
        return (api_token && priv && areas)? Promise.resolve({ priv, areas }) : Promise.reject();
    },
};

export default myAuthProvider;
