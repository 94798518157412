import React, { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { parse } from "query-string";
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import jsonExport from 'jsonexport/dist';
import { useNotify, fetchStart, fetchEnd, Title, SimpleForm, FormDataConsumer, TextInput, NumberInput, SelectInput, required, Toolbar, SaveButton, downloadCSV } from 'react-admin';
import { BooleanInputWithTip } from './TooltipComponents';
import { BACKEND_URL, contactMethods, contactReasons, scrollToRef, MCP_USERNAME, MCP_SECRET } from '../common/constants';
import { MyContext } from '../common/MyContext';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from '@material-ui/icons/GetApp';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import Paper from '@material-ui/core/Paper';
import BlockIcon from '@material-ui/icons/Block';
import MuiLink from '@material-ui/core/Link';

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: theme.palette.error.dark,
    backgroundColor: theme.palette.primary.main,    
    color: theme.palette.error.contrastText,
    paddingTop: '16px !important',
    paddingBottom: '16px !important',
    fontWeight: 'bold',
    //fontSize: 18,
  },
  body: {
    //fontSize: 14,
    //background: '#eee'
    border: 'none'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    transition: '0.1s all',
    backgroundColor: theme.palette.grey[100],
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
    }
  },
}))(TableRow);


const TableComponent = ({ data, ...props }) => {
    const parseValue = (str) => {
        if (str && str.length && (str.indexOf('task=') === 0)) {
            const { handleOptOut, handleStopSubscription, handleResendStop, handleDeleteRefund } = props;

            let obj = {};
            try {
                obj = parse(str);
            } catch(e) {
                return str;
            }
            if (obj && obj.task) {
                switch (obj.task) {
                    case 'optout':
                        return (
                            <Button variant="contained" size="small" color="primary" onClick={() => handleOptOut(obj.subscription_id, obj.uid, obj.club)}>Opt-Out</Button>
                        );
                    case 'stop': 
                        return (
                            <Button variant="contained" size="small"  color="primary" onClick={() => handleStopSubscription(obj.subscription_id, obj.club)}>Stop</Button>
                        );
                    case 'resend': 
                        return (
                            <Button variant="contained" size="small"  color="primary" onClick={() => handleResendStop(obj.subscription_id, obj.club)}>Resend</Button>
                        );
                    case 'delete_rec':
                        return (
                            <Button variant="contained" size="small"  color="primary" onClick={() => handleDeleteRefund(obj.rec_id)}>Delete</Button>
                        );
                    case 'url':
                        return (
                            <MuiLink href={obj.url} target="_blank">{obj.title? obj.title : obj.url}</MuiLink>
                        );
                    default:
                        break;
                }
            }
        }
        return str;
    };

    if (data && !!data.length) {
        return (
            <div style={{ overflowX: 'auto' }}>
                <Table aria-labelledby="tableTitle" size="small" stickyHeader={true} padding="none">
                    <TableHead>
                        <StyledTableRow>
                            {Object.keys(data[0]).map(k => 
                                <StyledTableCell key={k}>{k}</StyledTableCell>
                            )}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        { data.map((item, index) => (
                        <StyledTableRow key={index}>
                            {Object.keys(item).map(k =>
                                <StyledTableCell key={k}>{parseValue(item[k])}</StyledTableCell>
                            )}
                        </StyledTableRow>
                        )) }
                    </TableBody>
                </Table>
            </div>
        );
    } else {
        return null;
    }
};

const LogSearchDialog = forwardRef(({parentSetForceSearch, parentSetSkipLogSearch, ...props}, ref) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [pinRequired, setPinRequired] = useState(false);
    const [pinConfirmed, setPinConfirmed] = useState(false);
    const [parsedMobile, setParsedMobile] = useState(null);
    
    const handleClose = () => {
        setOpen(false);
    }

    const handleLogSearch = () => {
        formLogSearch.submit();
    }

    const handleCreateLog = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        if (values && values.aContactMethod && !!values.aContactMethod.length &&
            values.aContactReason && !!values.aContactReason.length) {

            let reasons = contactReasons.filter(r => r.id === values.aContactReason);
            if (reasons && !!reasons.length) {
                let refundReason = reasons[0].name;
                localStorage.setItem('aRefundReason', refundReason);
            }

            const obj = { 
                aParsedMobile: parsedMobile, 
                aContactMethod: values.aContactMethod, 
                aContactReason: values.aContactReason 
            };
            
            dispatch(fetchStart());
            fetch(BACKEND_URL + '/cs_create_log', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json.success) {
                                // refresh the data
                                if (parentSetForceSearch && parentSetSkipLogSearch) {
                                    parentSetSkipLogSearch(true);
                                    parentSetForceSearch(true);
                                }
                                notify('A log for this search has been saved.');
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        }

        setOpen(false);
    };

    const [formLogSearch, setFormLogSearch] = useState(null);

    const LogSearchToolbar = ({handleSubmitWithRedirect, ...lstbprops}) => {
        const form = useForm();

        useEffect(() => {
            setFormLogSearch(form);
        }, [form]);

        return null;
    };

    useImperativeHandle(ref, () => ({
       setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },

        setPinRequiredPublic: (pPinRequired) => {
            setPinRequired(pPinRequired);
        },

        setPinConfirmedPublic: (pPinConfirmed) => {
            setPinConfirmed(pPinConfirmed);
        },

        setParsedMobilePublic: (pParsedMobile) => {
            setParsedMobile(pParsedMobile);
        }
    }));

    const PINToolbar = ({handleSubmitWithRedirect, ...ptbprops}) => {
        const form = useForm();

        const handleClickConfirm = () => {
            setPinConfirmed(true);
            form.change('pin', '');
        };

        const handleClickRequest = () => {
            if (!(parsedMobile && !!parsedMobile.length)) {
                return;
            }

            dispatch(fetchStart());
            fetch(BACKEND_URL + '/cs_send_new_pin/' + parsedMobile, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json.success) {
                                form.change('pin', json.pin);
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        };

        let formData = form.getState().values;
        let pin = formData.pin;

        return (
            <Toolbar {...ptbprops}>
                { pin && !!pin.length && <SaveButton handleSubmitWithRedirect={handleClickConfirm} variant="contained" color="primary" label="Confirm PIN matches what the user received" /> }
                { /* !(pin && !allowRerequestPin)*/ !(pin && !!pin.length) && <SaveButton handleSubmitWithRedirect={handleClickRequest} variant="contained" color="primary" 
                    label={(pin && !!pin.length)? 'Request new PIN' : 'Send PIN'} /> }
            </Toolbar>
        );
    };

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="log-search-dialog-title"
            >
                <DialogTitle id="log-search-dialog-title">{"Data protection: Log this search"}</DialogTitle>
                <DialogContent>
                    { pinRequired &&
                        <div>
                            <label>Verify this User</label>
                            { !pinConfirmed? 
                                <SimpleForm
                                    resource="regions"
                                    toolbar={<PINToolbar />}
                                >
                                    <TextInput source="pin" disabled label="PIN" />
                                </SimpleForm> : 
                                <div>PIN Confirmed</div>
                            } 
                        </div>
                    }
                    <div>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleCreateLog}
                        toolbar={<LogSearchToolbar />}
                    >
                        <SelectInput 
                            source="aContactMethod" 
                            label="Contact Method" 
                            choices={contactMethods} 
                            optionText="name" 
                            optionValue="id"  
                            validate={required()} 
                        />

                        <SelectInput 
                            source="aContactReason" 
                            label="Contact Reason" 
                            choices={contactReasons} 
                            optionText="name" 
                            optionValue="id"  
                            validate={required()} 
                        />

                    </SimpleForm>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleLogSearch} variant="contained" color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

const RefundLogDialog = forwardRef(({parentSetForceSearch, parentSetSkipLogSearch, ...props}, ref) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [parsedMobile, setParsedMobile] = useState(null);
    const [msisdnAlias, setMsisdnAlias] = useState(null);
    
    const handleClose = () => {
        setOpen(false);
    }

    const handleRefundLog = () => {
        formRefundLog.submit();
    }

    const handleCreateRefundLog = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        if (values && !isNaN(values.aRefundAmount) &&
            values.aRefundReason && !!values.aRefundReason.length) {

            const obj = { 
                aParsedMobile: parsedMobile, 
                aRefundAmount: values.aRefundAmount, 
                aRefundReason: values.aRefundReason,
                aMsisdnAlias: msisdnAlias,
                aReference: null 
            };
            
            dispatch(fetchStart());
            fetch(BACKEND_URL + '/cs_create_refund', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json.success) {
                                // refresh the data
                                if (parentSetForceSearch && parentSetSkipLogSearch) {
                                    parentSetSkipLogSearch(true);
                                    parentSetForceSearch(true);
                                }
                                notify('A refund log has been saved.');
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        }

        setOpen(false);
    };

    const [formRefundLog, setFormRefundLog] = useState(null);

    const RefundLogToolbar = ({handleSubmitWithRedirect, ...rltbprops}) => {
        const form = useForm();

        useEffect(() => {
            setFormRefundLog(form);

            let refundReason = localStorage.getItem('aRefundReason');
            if (form && refundReason && !!refundReason.length) {
                form.change('aRefundReason', refundReason);
                localStorage.removeItem('aRefundReason');
            }
        }, [form]);

        return null;
    };

    useImperativeHandle(ref, () => ({
        setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },

        setParsedMobilePublic: (pParsedMobile) => {
            setParsedMobile(pParsedMobile);
        },

        setMsisdnAliasPublic: (pMsisdnAlias) => {
            setMsisdnAlias(pMsisdnAlias);
        }
    }));

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="refund-log-dialog-title"
            >
                <DialogTitle id="refund-log-dialog-title">{"Log refund"}</DialogTitle>
                <DialogContent>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleCreateRefundLog}
                        toolbar={<RefundLogToolbar />}
                    >
                        <NumberInput 
                            source="aRefundAmount" 
                            label="Amount" 
                            validate={required()} 
                        />

                        <TextInput 
                            source="aRefundReason" 
                            label="Reason" 
                            validate={required()} 
                        />

                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleRefundLog} variant="contained" color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

const McpPinDialog = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [msisdn, setMsisdn] = useState(null);
    const [result, setResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const handleClose = () => {
        setOpen(false);
    }

    const handleMcpPin = () => {
        formMcpPin.submit();
    }

    const handleMcpPinSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        if (values && (values.useSystemAccount || 
            (values.username && !!values.username.length &&
            values.user_secret && !!values.user_secret.length) ) ) {

            const obj = { 
                username: values.useSystemAccount? MCP_USERNAME : values.username, 
                user_secret: values.useSystemAccount? MCP_SECRET : values.user_secret, 
                msisdn: msisdn
            };

            dispatch(fetchStart());
            fetch(BACKEND_URL + '/mcp_get_by_msisdn', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json.success) {
                                setResult(json.result);
                                setErrorMessage(null);
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            setResult(null);
                            setErrorMessage(e.message);
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    setResult(null);
                    setErrorMessage(e.message); 
                });
            
        }
    }

    const [formMcpPin, setFormMcpPin] = useState(null);

    const McpPinToolbar = ({handleSubmitWithRedirect, ...mptbprops}) => {
        const form = useForm();

        useEffect(() => {
            setFormMcpPin(form);
        }, [form]);

        return null;
    };
    
    useImperativeHandle(ref, () => ({
        setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },

        setMsisdnPublic: (pMsisdn) => {
            setMsisdn(pMsisdn);
            setResult(null);
            setErrorMessage(null);
        }
    }));

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="mcp-pin-dialog-title"
            >
                <DialogTitle id="mcp-pin-dialog-title">{'MCP PIN Verify'}</DialogTitle>
                <DialogContent>
                    <label>MSISDN: <strong>{msisdn}</strong></label>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleMcpPinSubmit}
                        toolbar={<McpPinToolbar />}
                    >
                        <BooleanInputWithTip source="useSystemAccount" label="Use System Account" defaultValue={true} 
                            title="Uncheck this option if you need to enter Username and User Secret by hand. Please DON'T CLICK THIS CHECKBOX if you don't know what you're doing!" />
                        <FormDataConsumer>
                        { ({formData, ...rest }) => !formData.useSystemAccount && 
                            <TextInput 
                                source="username" 
                                label="Username" 
                                validate={required()} 
                            />
                        }
                        </FormDataConsumer>
                        
                        <FormDataConsumer>
                        { ({formData, ...rest }) => !formData.useSystemAccount && 
                            <TextInput 
                                source="user_secret" 
                                label="User Secret" 
                                validate={required()} 
                            />
                        }
                        </FormDataConsumer>
                    </SimpleForm>
                    
                    { result && 
                        <div style={{ overflowX: 'auto' }}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>Result:</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(result).map(k => (
                                        <TableRow key={k}>
                                            <TableCell>{k}</TableCell>
                                            <TableCell>{result[k]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div> }
                    { errorMessage && !!errorMessage.length &&
                        <div style={{ background: '#faa', textAlign: 'center', padding: '10px' }}>
                            {errorMessage}
                        </div> }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleMcpPin} variant="contained" color="primary" autoFocus>
                        Get PIN Data
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default ({ permissions, location, ...props }) => {
    const myContext = useContext(MyContext);
    useEffect(() => {
        if (myContext) {
            myContext.setShowIndicator(true);
        }
        
        return function cleanup() {
            if (myContext) {
                myContext.setShowIndicator(false);
            }
        };
    }, [myContext]);

    const notify = useNotify();
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    
    const [username, setUsername] = useState(null);
    //const [searchType, setSearchType] = useState(null);
    const [identifier, setIdentifier] = useState(null);
    const [encodedIdentifier, setEncodedIdentifier] = useState(null);
    const [identifierIsValid, setIdentifierIsValid] = useState(false);
    const [pinVerification, setPinVerification] = useState(false);
    const [imiAlias, setImiAlias] = useState(null);
    //const [imiAliasEncoded, setImiAliasEncoded] = useState(null);

    //const [mixemSubs, setMixemSubs] = useState([]);
    //const [mixemBilling, setMixemBilling] = useState([]);
    //const [mixemOptins, setMixemOptins] = useState([]);

    const [subscriptions, setSubscriptions] = useState([]);
    const [cpaRecords, setCpaRecords] = useState([]);
    //const [userRecords, setUserRecords] = useState([]);
    const [billingSuccesses, setBillingSuccesses] = useState([]);
    const [optinsNew, setOptinsNew] = useState([]);
    const [optins, setOptins] = useState([]);
    const [optinsArchive, setOptinsArchive] = useState([]);
    const [optinsArchiveLocal, setOptinsArchiveLocal] = useState([]);
    //const [regulatoryLog, setRegulatoryLog] = useState([]);
    //const [regulatoryLogCst, setRegulatoryLogCst] = useState([]);
    const [regulatoryLogCstOptins, setRegulatoryLogCstOptins] = useState([]);
    const [smsMessages, setSmsMessages] = useState([]);
    const [cpaLogging, setCpaLogging] = useState([]);
    //const [msisdnCreditRecord, setMsisdnCreditRecord] = useState([]);
    const [marketing, setMarketing] = useState([]);
          
    const [imiTransactions1, setImiTransactions1] = useState([]);
    const [imiTransactions2, setImiTransactions2] = useState([]);
    const [imiTransactions3, setImiTransactions3] = useState([]);
    //const [imiTransactions4, setImiTransactions4] = useState([]);
    const [imiMessages1, setImiMessages1] = useState([]);
    const [imiMessages2, setImiMessages2] = useState([]);
    const [imiMessages3, setImiMessages3] = useState([]);
    //const [imiMessages4, setImiMessages4] = useState([]);

    const [blockedStatus, setBlockedStatus] = useState(false);
    const [userNotices, setUserNotices] = useState([]);
    const [userSearchLog, setUserSearchLog] = useState([]);
    const [userRefundLog, setUserRefundLog] = useState([]);
    const [msg, setMsg] = useState(null);

    const [notes, setNotes] = useState(null);

    const [aux, setAux] = useState(null);
    const [add, setAdd] = useState(null);
    const [showQueries, setShowQueries] = useState(false);
    const [forceSearch, setForceSearch] = useState(false);
    
    const [queries, setQueries] = useState([]);
    const [queryExecutionTime, setQueryExecutionTime] = useState([]);
    const [tableDateRange, setTableDateRange] = useState([]);

    const useStyles = makeStyles({
        standardInput: {
            minWidth: '160px',
            width: '256px'
        },
        inlineBlock: {
            display: 'inline-flex',
            marginRight: '1rem'
        },
        flexBetween: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        hidden: {
            display: 'none',
        },
        verticalCenter: {
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            marginRight: '1rem'
        },
        horizToolbar: {
            marginTop: 0
        },
        blockedStatus: {
            background: '#faa', 
            textAlign: 'center', 
            fontSize: '24px', 
            padding: '10px' 
        },
        userNotices: {
            background: '#faa', 
            padding: '10px' 
        },
        userRefundLog: {
            //background: '#dfd', 
            padding: '10px'
        },
        tableDateRange: {
            color: '#ccf'
        },
        sql: {
            margin: 0
        },
        error: {
            background: 'red'
        },
        noteSnackbar: {
            top: '75px'
        },
        searchMsisdnForm: {
            margin: 0,
            background: '#3f51b5',
            width: '100%',
            padding: '8px 16px',
            boxSizing: 'border-box',
            overflow: 'hidden',
            color: 'white',
        },
        tabsHolder: {
            marginTop: '32px', 
            border: '1px solid #777', 
            borderTop: 'none',
            '& h3': {
                marginLeft: '1em',
                fontSize: '1.5em'
                
            }
        }
            
    });

    const classes = useStyles();

    const handleSearch = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.msisdn && !!values.msisdn.length) {
            history.push('#/customer_service?msisdn=' + values.msisdn);
        
            let url = BACKEND_URL + '/customer_service?msisdn=' + values.msisdn;
            if (aux && !!aux.length) {
                url += '&aux=' + aux;
            }
            if (add && !!add.length) {
                url += '&add=' + add;
            }
            if (showQueries) {
                url += '&show_queries=1';
            }
            
            dispatch(fetchStart());
            fetch(url, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            //console.log(json);
                            //setOldTab(selectedTab);
                            setSelectedTab(false);
                            if (json) {
                                setUsername(json.username);
                                //setSearchType(json.search_type);
                                setIdentifier(json.identifier);
                                setEncodedIdentifier(json.encoded_identifier);
                                setIdentifierIsValid(json.identifier_is_valid);
                                setPinVerification(json.pin_verification);
                                setImiAlias(json.imi_alias);
                                //setImiAliasEncoded(json.imi_alias_encoded);
                         
                                //setMixemSubs(json.mixem_subscriptions);
                                //setMixemBilling(json.mixem_billing_successes);
                                //setMixemOptins(json.mixem_optins);
                                setSubscriptions(json.subscriptions);
                                setCpaRecords(json.cpa_records);
                                //setUserRecords(json.user_records);
                                setBillingSuccesses(json.billing_successes);
                                setOptinsNew(json.optins_new);
                                setOptins(json.optins);
                                setOptinsArchive(json.optins_archive);
                                setOptinsArchiveLocal(json.optins_archive_local);
                                //setRegulatoryLog(json.regulatory_log);
                                //setRegulatoryLogCst(json.regulatory_log_cst);
                                setRegulatoryLogCstOptins(json.regulatory_log_cst_optins);
                                setSmsMessages(json.sms_messages);
                                setCpaLogging(json.cpalogging);
                                //setMsisdnCreditRecord(json.msisdn_credit_record);
                                setMarketing(json.marketing);

                                setImiTransactions1(json.imi_transactions_1);
                                setImiTransactions2(json.imi_transactions_2);
                                setImiTransactions3(json.imi_transactions_3);
                                //setImiTransactions4(json.imi_transactions_4);
                                setImiMessages1(json.imi_messages_1);
                                setImiMessages2(json.imi_messages_2);
                                setImiMessages3(json.imi_messages_3);
                                //setImiMessages4(json.imi_messages_4);

                                setBlockedStatus(json.blocked_status);
                                setUserNotices(json.user_notices);
                                setUserSearchLog(json.user_search_log);
                                setUserRefundLog(json.user_refund_log);
                                setMsg(json.msg);

                                setNotes(json.notes);

                                setQueries(json.queries);
                                setQueryExecutionTime(json.query_execution_time);
                                setTableDateRange(json.table_daterange);

                                if (json.errorMessages && !!json.errorMessages.length) {
                                     json.errorMessages.forEach(e => notify(e));
                                }

                                if (!aux && !add && !showQueries && !skipLogSearch) {
                                    setForceLog(true);
                                }
                                if (skipLogSearch) {
                                    setSkipLogSearch(false);
                                }
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        }
    }

    const [formSearch, setFormSearch] = useState(null);

    useEffect(() => {
        if (formSearch && forceSearch) {
            formSearch.submit();
            setForceSearch(false);
        }
    }, [forceSearch, formSearch]);

    const SearchToolbar = ({handleSubmitWithRedirect, ...stbprops}) => {
        const form = useForm();

        const handleClickSearch = () => {
            setAux(null);
            setAdd(null);
            setShowQueries(false);
            setForceSearch(true);
        };

        useEffect(() => {
            setFormSearch(form);
        }, [form]);

        return (
            <Toolbar {...stbprops} className={classes.horizToolbar} style={{background: 'none'}}>
                <SaveButton handleSubmitWithRedirect={handleClickSearch} size="large" variant="contained" color="primary" label="Search" icon={<SearchIcon />} />
            </Toolbar>
        );
    };

    const [forceLog, setForceLog] = useState(false);
    const logSearchDialog = useRef(null);
    const [skipLogSearch, setSkipLogSearch] = useState(false);
    
    useEffect(() => {
        if (identifier && !!identifier.length && forceLog) {
            logSearchDialog.current.setPinRequiredPublic(pinVerification);
            logSearchDialog.current.setPinConfirmedPublic(false);
            logSearchDialog.current.setParsedMobilePublic(identifier);
            logSearchDialog.current.setOpenPublic(true);
            setForceLog(false);
        }
    }, [forceLog, pinVerification, identifier]);

    const refundLogDialog = useRef(null);
    const handleShowRefund = () => {
        refundLogDialog.current.setMsisdnAliasPublic(imiAlias);
        refundLogDialog.current.setParsedMobilePublic(identifier);
        refundLogDialog.current.setOpenPublic(true);
    };

    const handleImiAlias = () => {
        if (formSearch && imiAlias && !!imiAlias.length) {
            formSearch.change('msisdn', imiAlias);
            setAux(null);
            setAdd(null);
            setShowQueries(false);
            setForceSearch(true);            
        }
    };

    const handleOptOut = (subscription, user_unique, club_unique) => {
        let subscription_id = '';
        if (subscription && subscription.length) {
            subscription_id = subscription.split(' ').join('+');
        }

        const obj = { subscription_id, user_unique, club_unique };

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/optout', { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.success) {
                            if (user_unique === 'passive' && club_unique === 'passive') {
                                setMsg('optedout');
                            } else {
                                // refresh the data
                                setSkipLogSearch(true);
                                setForceSearch(true);
                            }
                            notify('Opt-out was recorded to regulatoryLog.');
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });
    };

    const handleStopSubscription = (subscription, club_unique = '', mode = 'single') => {
        let subscription_id = '';
        if (subscription && subscription.length) {
            subscription_id = subscription.split(' ').join('+');
        }

        const obj = { subscription_id, club_unique, mode };

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/stop_subscription', { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.errorMessages && !!json.errorMessages.length) {
                            json.errorMessages.forEach(e => notify(e));
                        }

                        if (json.success && json['return']) {
                            // refresh the data
                            setSkipLogSearch(true);
                            setForceSearch(true);
                            notify('Result from stopping subscription: <br/>' + json['return']);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                dispatch(fetchEnd());
                notify(e.message, 'warning'); 
            });
    };

    const handleResendStop = (subscription, club_unique = '', mode = 'single') => {
        let subscription_id = '';
        if (subscription && subscription.length) {
            subscription_id = subscription.split(' ').join('+');
        }

        const obj = { subscription_id, club_unique, mode };

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/resend_stop', { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.errorMessages && !!json.errorMessages.length) {
                            json.errorMessages.forEach(e => notify(e));
                        }

                        if (json.success && json['return']) {
                            // refresh the data
                            setSkipLogSearch(true);
                            setForceSearch(true);
                            notify('Result from resending stop subscription: <br/>' + json['return']);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });
    };

    const handleDeleteRefund = (refund_id) => {
        const obj = {};

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/delete_refund/' + refund_id, { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.success) {
                            // refresh the data
                            setSkipLogSearch(true);
                            setForceSearch(true);
                            notify(`Refund record ${refund_id} deleted.`);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });
    };

    const handleSaveNotes = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.text && !!values.text && identifier && !!identifier.length) {
            const obj = { msisdn: identifier, text: values.text };

            dispatch(fetchStart());
            fetch(BACKEND_URL + '/cs_save_notes', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json.success) {
                                setNotes(Object.assign({}, notes, { text: values.text }));
                                notify(`Note saved for ${identifier}.`);
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        }
    };

    const [formNotes, setFormNotes] = useState(null);
    const NotesToolbar = (ntbprops) => {
        const form = useForm();

        useEffect(() => {
            setFormNotes(form);
        }, [form]);

        return (
            <Toolbar {...ntbprops}>
                <SaveButton variant="contained" color="primary" />
            </Toolbar>
        );
    };
    useEffect(() => {
        if (formNotes && notes) {
            formNotes.change('text', notes.text);
            formNotes.change('datestamp', notes.datestamp);
        }
    }, [formNotes, notes]);

    const [selectedTab, setSelectedTab] = useState(false);
    //const [oldTab, setOldTab] = useState(false);
    
    const handleTabChange = (event, value) => {
        setSelectedTab(value);
    };

    const handleStandardSearch = () => {
        if (formSearch) {
            let formState = formSearch.getState();
            if (formState && formState.valid) {
                setAux(null);
                setAdd(null);
                setShowQueries(false);
            }
        }
        setForceSearch(true);
    };
    
    /*const handleMixemRecords = () => {
        setAux('mixem_records');
        setAdd(null);
        setShowQueries(false);
        setForceSearch(true);
    };*/

    const handleCpaRecords = () => {
        setAux('cpa_records');
        setAdd(null);
        setShowQueries(false);
        setForceSearch(true);
    };

    const handleAllCampaigns = () => {
        setAux(null);
        setAdd('all_campaign');
        setShowQueries(false);
        setForceSearch(true);
    };

    const handleShowQueries = () => {
        setAux(null);
        setAdd(null);
        setShowQueries(true);
        setForceSearch(true);
    };

    const [visibleTabs, setVisibleTabs] = useState([]);

    useEffect(() => {
        let result = [];

        let notHaveMixemOrCPA = !(cpaRecords && !!cpaRecords.length) /*&& !(mixemSubs && !!mixemSubs.length) && !(mixemBilling && !!mixemBilling.length) && !(mixemOptins && !!mixemOptins.length)*/;

        /*if ((mixemSubs && !!mixemSubs.length) || (mixemBilling && !!mixemBilling.length) || (mixemOptins && !!mixemOptins.length)) {
            result.push(0);
        }*/

        if (cpaRecords && !!cpaRecords.length) {
            result.push(1);
        }

        if (/*((userSearchLog && !!userSearchLog.length) || (userRefundLog && !!userRefundLog.length)) &&*/ notHaveMixemOrCPA) {
            result.push(2);
        }

        /*if (((userRecords && !!userRecords.length) || (msisdnCreditRecord && !!msisdnCreditRecord.length)) && notHaveMixemOrCPA) {
            result.push(3);
        }*/

        if (((optinsNew && !!optinsNew.length) || (optins && !!optins.length) || (regulatoryLogCstOptins && !!regulatoryLogCstOptins.length) || 
                            (optinsArchive && !!optinsArchive.length) || (optinsArchiveLocal && !!optinsArchiveLocal.length)) && notHaveMixemOrCPA) {
            result.push(3);
        }

        if ((marketing && !!marketing.length) && notHaveMixemOrCPA) {
            result.push(4);
        }
        
        if (((billingSuccesses && !!billingSuccesses.length) || (imiTransactions1 && !!imiTransactions1.length) || (imiTransactions2 && !!imiTransactions2.length) || 
                            (imiTransactions3 && !!imiTransactions3.length) /*|| (imiTransactions4 && !!imiTransactions4.length)*/) && notHaveMixemOrCPA) {
            result.push(5);
        }

        if (((smsMessages && !!smsMessages.length) || (imiMessages1 && !!imiMessages1.length) || (imiMessages2 && !!imiMessages2.length) || 
                            (imiMessages3 && !!imiMessages3.length) /*|| (imiMessages4 && !!imiMessages4.length)*/) && notHaveMixemOrCPA) {
            result.push(6);
        }

        /*if (((regulatoryLog && !!regulatoryLog.length) || (regulatoryLogCst && !!regulatoryLogCst.length)) && notHaveMixemOrCPA) {
            result.push(7);
        }*/

        if (cpaLogging && !!cpaLogging.length && notHaveMixemOrCPA) {
            result.push(8);
        }

        setVisibleTabs(result);

        if (result && !!result.length) {
            /*if (oldTab && result.includes(oldTab)) {
                setSelectedTab(oldTab);
            } else { */
                const firstTab = result[0];
                setSelectedTab(firstTab);
            /*} */
        } else {
            setSelectedTab(false);
        }
    }, [cpaRecords, /*mixemSubs, mixemBilling, mixemOptins,*/ /*userRecords, msisdnCreditRecord,*/ userSearchLog, userRefundLog, billingSuccesses, imiTransactions1, imiTransactions2, 
        imiTransactions3, /*imiTransactions4,*/ optinsNew, optins, regulatoryLogCstOptins, optinsArchive, optinsArchiveLocal, marketing, smsMessages, imiMessages1, imiMessages2,
        imiMessages3, /*imiMessages4,*/ /*regulatoryLog, regulatoryLogCst,*/ cpaLogging/*, oldTab*/]);

    let msisdnParam = null;
    try {
        const parObj = parse(location.search);
        if (parObj && parObj.msisdn && !!parObj.msisdn.length) {
            msisdnParam = parObj.msisdn;
        }
    } catch (e) {
        console.log(e.message);
    }
    useEffect(() => {
        if (formSearch && msisdnParam && !!msisdnParam.length) {
            formSearch.change('msisdn', msisdnParam);

            setAux(null);
            setAdd(null);
            setShowQueries(false);
            setForceSearch(true);
        }
    }, [msisdnParam, formSearch]);

    const refundLogRef = useRef(null);
    const notesRef = useRef(null);
    
    const RefundSnackbar = ({sbopen, ...sbprops}) => {
        const [open, setOpen] = useState(sbopen);
        
        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpen(false);
        };
        
        const handleClick = () => {
            scrollToRef(refundLogRef);
        };
        
        return (
            <Snackbar {...sbprops}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
                open={open} 
                message="You have REFUNDS issued"
                onClose={handleClose}
                action={
                    <>
                        <Button color="secondary" size="small" onClick={handleClick}>
                            Scroll to REFUNDS
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>}
            />
        );
    };

    const NoteSnackbar = ({sbopen, ...sbprops}) => {
        const [open, setOpen] = useState(sbopen);
        
        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpen(false);
        };
        
        const handleClick = () => {
            scrollToRef(notesRef);
        };
        
        return (
            <Snackbar {...sbprops}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                className={classes.noteSnackbar} 
                open={open} 
                message="You have NOTES"
                onClose={handleClose}
                action={
                    <>
                        <Button color="secondary" size="small" onClick={handleClick}>
                            Scroll to NOTES
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>}
            />
        );
    };


    // FILTER BY CLUB IN BILLING SUCCESSES
    const [filteredBills, setFilteredBills] = useState([]);

    const handleBillFilter = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
    
        if (values) {
            if (!!values.selectedBillClub && !!values.selectedBillClub.length) {
                const filtered = billingSuccesses.filter(bs => bs.aClubName === values.selectedBillClub);
                setFilteredBills(filtered);
            } else {
                setFilteredBills(billingSuccesses);
            }
        }
    }
    
    const handleChangeSelectedBillClub = (event) => {
        if (formBillFilter) {
            formBillFilter.submit();
        }
    };
    
    const [formBillFilter, setFormBillFilter] = useState(null);
    const BillFilterToolbar = (bftbprops) => {
        const form = useForm();

        useEffect(() => {
            setFormBillFilter(form);
        }, [form]);

        return null;
    };

    const [billClubs, setBillClubs] = useState([]);    
    useEffect(() => {
        if (billingSuccesses) {
            let clubs = [{ id: '', name: 'ALL' }];
            const clubNames = billingSuccesses.map(bs => bs.aClubName).filter(Boolean);
            const uniqueClubNames = clubNames.filter((club, index) => clubNames.indexOf(club) === index).sort();
            uniqueClubNames.forEach(club => { clubs.push({ id: club, name: club }); })
            setBillClubs(clubs);
        
            if (formBillFilter) {
                formBillFilter.change('selectedBillClub', '');
                setFilteredBills(billingSuccesses);
            }
        }
    }, [formBillFilter, billingSuccesses]);


    // FILTER BY CLUB IN SMS MESSAGES
    const [filteredSms, setFilteredSms] = useState([]);

    const handleSmsFilter = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
    
        if (values) {
            if (!!values.selectedSmsClub && !!values.selectedSmsClub.length) {
                const filtered = smsMessages.filter(sm => {
                    const clubName = sm['Club Name'];
                    const mt_mo = sm['MT/MO'];
                    
                    if (clubName && !!clubName.length) {
                        return clubName === values.selectedSmsClub;
                    } else {
                        return mt_mo === 'MO';
                    }
                });
                setFilteredSms(filtered);
            } else {
                setFilteredSms(smsMessages);
            }
        }
    }
    
    const handleChangeSelectedSmsClub = (event) => {
        if (formSmsFilter) {
            formSmsFilter.submit();
        }
    };
    
    const [formSmsFilter, setFormSmsFilter] = useState(null);
    const SmsFilterToolbar = (sftbprops) => {
        const form = useForm();

        useEffect(() => {
            setFormSmsFilter(form);
        }, [form]);

        return null;
    };

    const [smsClubs, setSmsClubs] = useState([]);    
    useEffect(() => {
        if (smsMessages) {
            let clubs = [{ id: '', name: 'ALL' }];
            const clubNames = smsMessages.map(sm => sm['Club Name']).filter(Boolean);
            const uniqueClubNames = clubNames.filter((club, index) => clubNames.indexOf(club) === index).sort();
            uniqueClubNames.forEach(club => { clubs.push({ id: club, name: club }); })
            setSmsClubs(clubs);
        
            if (formSmsFilter) {
                formSmsFilter.change('selectedSmsClub', '');
                setFilteredSms(smsMessages);
            }
        }
    }, [formSmsFilter, smsMessages]);

    const mcpPinDialog = useRef(null);
    const handleShowMcpPin = () => {
        mcpPinDialog.current.setMsisdnPublic(identifier);
        mcpPinDialog.current.setOpenPublic(true);
    };
    
    const handleExportSms = () => {
        jsonExport(filteredSms, {
            headers: ['MT/MO', 'Club Name', 'MSISDN', 'Shortcode', 'Date', 'Delivery', 'Price', 'Charge', 'Message Content'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'sms_messages'); // download as 'sms_messages.csv` file
        });            
    };

    const handleExportUserSearchLog = () => {
        if (!(userSearchLog && !!userSearchLog.length)) {
            return;
        }
        let columns = Object.keys(userSearchLog[0]);
        jsonExport(userSearchLog, {
            headers: columns // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'customer_contact_log'); // download as 'customer_contact_log.csv` file
        });            
    };
        
const AntTabs = withStyles({
  root: {
    //borderBottom: '11px solid #e8e8e8',
    backgroundColor: '#eee',
  },
  indicator: {
    top: 0,
    height: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #777',
  },
})(MuiTabs);

const AntTab = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #777',
    '&:hover': {
        opacity: 1,
    },
    '&$selected': {
        backgroundColor: 'white',
        borderBottom: 'none',
        fontSize: '1em',
    },
    '&:focus': {
        //color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <MuiTab {...props} />);

    return (
        <Card>
            <Title title="Customer Service" />
            <CardContent>
                
                <div className={classes.flexBetween}>
                    <Paper variant="outlined" square={true}>
                        <p className={classes.searchMsisdnForm}>Search subscriptions and activities of a MSISDN or e-mail:</p>
                        <SimpleForm
                            resource="regions"
                            onSubmit={handleSearch}
                            toolbar={<SearchToolbar />}
                            className={classes.inlineBlock}
                        >
                            <TextInput
                                source="msisdn"
                                label="MSISDN or Email"
                                helperText="Enter a MSISDN or e-mail address"
                                validate={required()}
                                onKeyPress={(e) => { if (e.key === 'Enter') handleStandardSearch(); }}
                            />
                        </SimpleForm>
                    </Paper>
                    <div className={classes.flexBetween}>
                        { identifier && identifier.length && identifierIsValid && (selectedTab === 6) &&
                        <div className={classes.verticalCenter}>
                            <Button variant="contained" color="primary" onClick={handleExportSms}><ExportIcon /> Export SMS Messages</Button>
                        </div>
                        }

                        { identifier && identifier.length && identifierIsValid &&
                        <div className={classes.verticalCenter}>
                            <Button variant="contained" color="primary" onClick={handleShowRefund}><MoneyOffIcon /> Log refund</Button>
                        </div>
                        }
                    </div>
                    
                    <RefundSnackbar sbopen={(selectedTab === 2) && userRefundLog && !!userRefundLog.length} />
                    <NoteSnackbar sbopen={(selectedTab === 2) && notes && !!notes.text && !!notes.text.length} />                           
                </div>


                { identifier && identifier.length && identifierIsValid && 
                <div className={classes.tabsHolder} role="tabpanel">
                    
                    <AntTabs value={selectedTab} onChange={handleTabChange}  variant="fullWidth" style={{margin: '0px -0px 0 -1px'}}>
                        {/* visibleTabs.includes(0) && <MuiTab key={0} value={0} label="Mixem Archive" /> */}
                        { visibleTabs.includes(1) && <AntTab key={1} value={1} label="CPA" /> }
                        { visibleTabs.includes(2) && <AntTab key={2} value={2} label="Subs" /> }
                        {/* visibleTabs.includes(3) && <MuiTab key={3} value={3} label="User" /> */}
                        { visibleTabs.includes(3) && <AntTab key={3} value={3} label="Opt-ins" /> }
                        { visibleTabs.includes(4) && <AntTab key={4} value={4} label="Marketing" /> }
                        { visibleTabs.includes(5) && <AntTab key={5} value={5} label="Billing" /> }
                        { visibleTabs.includes(6) && <AntTab key={6} value={6} label="SMS" /> }
                        {/* visibleTabs.includes(7) && <MuiTab key={7} value={7} label="Regulatory" /> */}
                        { visibleTabs.includes(8) && <AntTab key={8} value={8} label="CPA Logging" /> }
                    </AntTabs>
                    
                    {/* (selectedTab === 0) && visibleTabs.includes(0) && 
                        <div>
                            <h3>Mixem Subscriptions</h3>
                            <TableComponent data={mixemSubs} /> 

                            <h3>Mixem Billing</h3>
                            <TableComponent data={mixemBilling} /> 

                            <h3>Mixem Opt-Ins</h3>
                            <TableComponent data={mixemOptins} /> 
                        </div>
                    */}
                    { (selectedTab === 1) && visibleTabs.includes(1) && 
                        <div>
                            <h3>CPA Records</h3>
                            <TableComponent data={cpaRecords} /> 
                        </div>
                    }
                    { (selectedTab === 2) && visibleTabs.includes(2) && 
                        <div>
                            { blockedStatus && 
                                <div className={classes.blockedStatus}>
                                    According to the Pareva user record, this user has been blocked (accountType=99).
                                </div> }

                            { userNotices && !!userNotices.length && 
                                <div className={classes.userNotices}>
                                    <h3>Customer Notices</h3>
                                    <TableComponent data={userNotices} />
                                </div> }

                            { (subscriptions && !!subscriptions.length)?
                                <div>
                                    <h3>Subscriptions</h3>
                                    <TableComponent 
                                        data={subscriptions} 
                                        handleOptOut={handleOptOut} 
                                        handleStopSubscription={handleStopSubscription}
                                        handleResendStop={handleResendStop} 
                                        handleDeleteRefund={handleDeleteRefund} 
                                    /> 
                                </div> :
                                <div>
                                    <h3>No Sub Records Found</h3>
                                    { (msg === 'optedout')?
                                        <p>This user has been opted out of broadcasts.</p> : 
                                        <div style={{margin: '0 1.5em'}}>
                                            { identifier && (identifier.length < 14) && !blockedStatus && 
                                                <div>
                                                    <p>You can still opt-out <strong>{identifier}</strong> from broadcasts or block it from subscribing in the future:</p>
                                                    <p><Button style={{marginRight: '1.5em'}} variant="contained" color="primary" onClick={() => handleOptOut(identifier, 'passive', 'passive')}>Opt-Out</Button>
                                                    <Button variant="contained" color="primary" component={Link} to={{ pathname: `/user_total_block/${encodedIdentifier}` }}>Block</Button></p>
                                                </div> }
                                        </div> }
                                </div> }

                            { userSearchLog && !!userSearchLog.length &&
                                <div>
                                    <div className={classes.flexBetween}>
                                        <h3>Customer Contact Log</h3>
                                        <div className={classes.verticalCenter}>
                                            <Button variant="contained" color="primary" onClick={handleExportUserSearchLog}><ExportIcon /> Export Customer Contact Log</Button>
                                        </div>
                                    </div>
                                    <TableComponent data={userSearchLog} />
                                </div> }

                            { userRefundLog && !!userRefundLog.length &&
                                <div className={classes.userRefundLog}>
                                    <h3 ref={refundLogRef}>Refund Log</h3>
                                    <TableComponent 
                                        data={userRefundLog}
                                        handleOptOut={handleOptOut} 
                                        handleStopSubscription={handleStopSubscription}
                                        handleResendStop={handleResendStop} 
                                        handleDeleteRefund={handleDeleteRefund} 
                                    />
                                </div> }

                            <br />

                            <Divider />

                            <h3 ref={notesRef}>Notes</h3>
                            <SimpleForm style={{marginTop: 0}} resource="regions" onSubmit={handleSaveNotes} toolbar={<NotesToolbar />}>
                                <TextInput source="text" label="Notes" helperText="Text" multiline validate={required()} />
                                <TextInput source="datestamp" disabled label="Date" />
                            </SimpleForm>

                            <br />
                            
                            <Divider />

                            { imiAlias && !!imiAlias.length &&
                                <div>
                                    <h3>This is a UK MSISDN</h3>
                                    <p style={{margin: '0 1.5em'}}>That means that you might find more data for its alias, <Button variant="contained" color="primary" onClick={handleImiAlias}>{imiAlias}</Button>.
                                    You should also check the <a target="_blank" rel="noopener noreferrer" href="http://mobilepayments.imimobile.net/">IMI Platform</a>.</p>
                                </div> }


                            { ['jenny', 'jv', 'georgiana', 'richard', 'rk', 'ellen', 'angel'].includes(username) && 
                                <div style={{background: '#ffd2d2', marginTop: '2em', border: 'red 1px solid'}}>
                                    <h3 style={{ width: '200px', display: 'inline-block', verticalAlign: 'middle'}}>User Total Block</h3>
                                    <Button style={{margin: '1.5em', backgroundColor: '#e80000', color: 'white' }} variant="contained" component={Link} to={{ pathname: `/user_total_block/${encodedIdentifier}` }}><BlockIcon />Block</Button>
                                </div> }
                            
                            
                        </div>
                    }
                    {/* (selectedTab === 3) && visibleTabs.includes(3) && 
                        <div>
                            <h3>User records</h3>
                            <TableComponent data={userRecords} /> 

                            <h3>MSISDN Credit Scores</h3>
                            <TableComponent data={msisdnCreditRecord} />
                        </div>
                    */}
                    { (selectedTab === 3) && visibleTabs.includes(3) &&
                        <div>
                            <h3>Opt-ins</h3>
                            <TableComponent data={optinsNew} />
                            <TableComponent data={optins} /> 
                            <TableComponent data={regulatoryLogCstOptins} />

                            {optinsArchiveLocal && optinsArchiveLocal.map((oal, index) => <TableComponent key={index} data={oal} />)} 
                            {optinsArchive && optinsArchive.map((oa, index) => <TableComponent key={index} data={oa} />)} 
                        </div>
                    }
                    { (selectedTab === 4) && visibleTabs.includes(4) &&
                        <div>
                            <h3>Marketing</h3>
                            <TableComponent data={marketing} />
                        </div>
                    }
                    { (selectedTab === 5) && visibleTabs.includes(5) && 
                        <div>
                            <h3>Billing</h3>
                            <SimpleForm
                                resource="regions"
                                onSubmit={handleBillFilter}
                                toolbar={<BillFilterToolbar />}
                                className={classes.inlineBlock}
                            >
                                <SelectInput
                                    source="selectedBillClub"
                                    label="Select Club"
                                    choices={billClubs}
                                    optionValue="id"
                                    optionText="name"
                                    onChange={handleChangeSelectedBillClub}
                                />
                            </SimpleForm>
                            <TableComponent data={filteredBills} />

                            {/*<h4>IMI Transactions (UME account)</h4> 
                            <TableComponent data={imiTransactions1} />

                            <h4>IMI Transactions (Moonlight account)</h4>
                            <TableComponent data={imiTransactions2} />

                            <h4>IMI Transactions (GetMeFit account)</h4>
                            <TableComponent data={imiTransactions3} />*/}

                            {/*<h4>IMI Transactions (account)</h4>
                            <TableComponent data={imiTransactions4} />*/}
                        </div>
                    }
                    { (selectedTab === 6) && visibleTabs.includes(6) &&
                        <div>
                            <h3>SMS Message Log</h3>
                            <SimpleForm
                                resource="regions"
                                onSubmit={handleSmsFilter}
                                toolbar={<SmsFilterToolbar />}
                                className={classes.inlineBlock}
                            >
                                <SelectInput
                                    source="selectedSmsClub"
                                    label="Select Club"
                                    choices={smsClubs}
                                    optionValue="id"
                                    optionText="name"
                                    onChange={handleChangeSelectedSmsClub}
                                />
                            </SimpleForm>
                            <TableComponent data={filteredSms} size="small" />

                            {/*<h4>IMI Messages (UME account)</h4>
                            <TableComponent data={imiMessages1} />

                            <h4>IMI Messages (Moonlight account)</h4> 
                            <TableComponent data={imiMessages2} />

                            <h4>IMI Messages (GetMeFit account)</h4>
                            <TableComponent data={imiMessages3} />*/}

                            {/*<h4>IMI Messages (account)</h4>
                            <TableComponent data={imiMessages4} />*/}
                        </div>
                    }
                    {/* (selectedTab === 7) && visibleTabs.includes(7) &&
                        <div>
                            <h3>Regulatory Log</h3>
                            <TableComponent data={regulatoryLog} /> 

                            <h3>New Regulatory Log for Customer Services</h3>
                            <TableComponent data={regulatoryLogCst} /> 
                        </div>
                    */}
                    { (selectedTab === 8) && visibleTabs.includes(8) && 
                        <div>
                            <h3>CPA Sent This Week</h3>
                            <TableComponent data={cpaLogging} /> 
                        </div>
                    }

                    

                    <div className="custServiceBottomButtons">
                        {/* (aux !== 'mixem_records') && 
                            <div>
                                <Button variant="contained" color="primary" onClick={handleMixemRecords}>Show archived Mixem records</Button> 
                            </div>
                        */}

                        { (aux !== 'cpa_records') && 
                            <div>
                                <Button variant="contained" color="primary" onClick={handleCpaRecords} title="includes publisher ID" alt="includes publisher ID">Show CPA records</Button>
                            </div>
                        }

                        { !(!(aux && aux.length) && add && (add === 'all_campaign')) && 
                            <div>
                                <Button variant="contained" color="primary" onClick={handleAllCampaigns} title="(on Opt-Ins tab)" alt="(on Opt-Ins tab)">Show all campaign records</Button> 
                            </div>
                        }

                        { !showQueries && 
                            <div>
                                <Button variant="contained" color="primary" onClick={handleShowQueries}>Show queries</Button>
                            </div>
                        }

                        <div>
                            <Button variant="contained" color="primary" onClick={handleShowMcpPin}>Detailed MCP Logs</Button>
                        </div>
                        
                    </div>

                    { showQueries && queries && queryExecutionTime && 
                        <div>
                            <h3>Queries used to build this report</h3>

                            <ul>
                                {Object.keys(queries).map(q =>
                                    <li key={q}>
                                        <strong>{q}</strong> &nbsp;
                                        <em>{queryExecutionTime[q]} ms</em> &nbsp;
                                        { tableDateRange && <span className={classes.tableDateRange}>{tableDateRange[q]}</span> }
                                        <blockquote className={classes.sql}>{queries[q].query}</blockquote>
                                        <span className={classes.error}>{queries[q].error}</span>
                                    </li>
                                )};
                            </ul>
                        </div>
                    }

                    <LogSearchDialog ref={logSearchDialog} parentSetForceSearch={setForceSearch} parentSetSkipLogSearch={setSkipLogSearch} />
                    <RefundLogDialog ref={refundLogDialog} parentSetForceSearch={setForceSearch} parentSetSkipLogSearch={setSkipLogSearch} />
                    <McpPinDialog ref={mcpPinDialog} />
                </div> 
                }
            </CardContent>
        </Card>
    );
};
