import React, { useState, useEffect } from 'react';
import { useNotify, fetchStart, fetchEnd, List, Datagrid, TextField, Filter, TextInput, EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import { useDispatch } from 'react-redux';
import { BACKEND_URL } from '../common/constants';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


const SourceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="name" label="Name" />
    </Filter>
);

export const SourceList = (props) => (
    <List {...props} filters={<SourceFilter />} style={{ maxWidth: '550px' }}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <EditButton basePath="/sources" className="tableEdit" />
        </Datagrid>
    </List>
);

const SourceForm = ({ withId, ...props }) => (

    <SimpleForm {...props}>
        {withId && <TextInput disabled source="id" fullWidth />}
        <TextInput source="name" validate={[required()]} fullWidth />
    </SimpleForm>
);

const SourceTitle = ({ record }) => {
    return <span>Source {record ? `#${record.id}` : ''}</span>;
};

export const SourceCreate = (props) => (
    <Create title="Create New Source" {...props} style={{ maxWidth: '550px' }}>
        <SourceForm withId={false} redirect="list" />
    </Create>
);

export const SourceEdit = (props) => (
    <Edit undoable={false} title={<SourceTitle />} aside={<SourceTabs />} {...props} className="thinLeftPanel sidePanelFix">
        <SourceForm withId={true} />
    </Edit>
);


const SourceTabs = ({ record, ...props }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Card>
            <CardContent>
                <MuiTabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
                    <MuiTab key={0} value={0} label="CPA Advertiser" />
                </MuiTabs>
                <Divider />
                {(selectedTab === 0) && record && record.id && <CPAAdvertiser record={record} />}
            </CardContent>
        </Card>
    );
};

const CPAAdvertiser = ({ record, ...props }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const notify = useNotify();

    const [advertiser, setAdvertiser] = useState(null);

    useEffect(() => {
        if (!record) {
            return;
        }
        if (!(record.name && !!record.name.length)) {
            return;
        }

        let url = BACKEND_URL + '/find_cpa_advertiser_by_source/' + record.name;

        dispatch(fetchStart());
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('api_token')
            }
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json) {
                            setAdvertiser(json.advertiser);

                            if (json.errorMessages && !!json.errorMessages.length) {
                                json.errorMessages.forEach(e => notify(e));
                            }
                        } else {
                            setAdvertiser(null);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd());
                notify(e.message, 'warning');
            });
    }, [record, dispatch, notify]);

    if (advertiser) {
        return (
            <div className={classes.root}>
                <h2>CPA Advertiser matching this source</h2>
                <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content">
                        <Typography className={classes.heading}>CPA Advertiser</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ overflow: 'hidden' }}>
                        <Table aria-labelledby="tableTitle">
                            <TableBody>
                                {advertiser && Object.keys(advertiser).map(k =>
                                    <TableRow key={k}>
                                        <TableCell>{k}</TableCell>
                                        <TableCell>{advertiser[k]}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <h2>No CPA Advertiser found!</h2>
            </div>
        );
    }
};

