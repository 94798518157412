import React from 'react';
import { Route } from 'react-router-dom';
import UserTotalBlock from '../components/UserTotalBlock';
//import CustomerService from '../components/CustomerService';
import BulkStop from '../components/BulkStop';
import ClearTestMsisdn from '../components/ClearTestMsisdn';
import RunAdhocQuery from '../components/RunAdhocQuery';
import EBEnvironmentIP from '../components/EBEnvironmentIP';
import CampaignPromo from '../components/CampaignPromo';
import CampaignInParevaEdit from '../components/CampaignInParevaEdit';

export default [
    <Route exact path="/user_total_block/:msisdn" component={UserTotalBlock} />,
    //<Route exact path="/customer_service/:msisdnParam" component={CustomerService} />,
    <Route exact path="/bulk_stop" component={BulkStop} />,
    <Route exact path="/clear_test_msisdn/:msisdnParam?" component={ClearTestMsisdn} />,    
    <Route exact path="/run_adhoc_query/:id" component={RunAdhocQuery} />,
    <Route exact path="/eb_environment_ip_list" component={EBEnvironmentIP} />,
    <Route exact path="/campaign_promo/:campaign_id" component={CampaignPromo} />,
    <Route exact path="/campaigns_in_pareva/:platform_aunique" component={CampaignInParevaEdit} />,
];
