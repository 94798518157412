import React from 'react';
import { Notification, getNotification } from 'react-admin';
import { useSelector } from 'react-redux';

export default (props) => {
    const notification = useSelector(getNotification);
    const msg = notification? notification.message : '';

    if (msg.startsWith('ra.')) {
        return (
            <Notification {...props} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        );
    } else {
        return ( 
            <Notification {...props} autoHideDuration={10000} message={<div dangerouslySetInnerHTML={{ __html: msg }} />} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        );
    }
};

