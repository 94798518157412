import React from 'react';
import { List, Datagrid, TextField, BooleanField, DateField, Filter, 
    TextInput, BooleanInput, NumberInput, SelectInput, ReferenceInput, DateInput /*EditButton, SimpleForm, Create, Edit, required*/ } from 'react-admin';
import { clubTypes, classifications, clubServiceTypes, paymentTypes, servedBys } from '../common/constants';

const MobileClubFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="aUnique" label="aUnique" />
        <TextInput source="aClubName" label="aClubName" />
        <TextInput source="aName" label="aName" />
        <ReferenceInput source="aRegion" label="aRegion" reference="regions" sort={{ field: 'id', order: 'ASC' }} perPage={100000}>
            <SelectInput optionText="aRegion" />
        </ReferenceInput>
        <TextInput source="aWapDomain" label="aWapDomain" />
        <TextInput source="aWebDomain" label="aWebDomain" />
        <ReferenceInput source="aCurrency" label="aCurrency" reference="mobile_club_currencies" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="aAggregator" label="aAggregator" reference="aggregators" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="aSmsNumber" label="aSmsNumber" />
        <TextInput source="msisdnformat" label="msisdnformat" />
        <BooleanInput source="aActive" label="aActive" />
        <SelectInput source="aType" label="aType" choices={clubTypes} optionText="name" optionValue="id" />
        <NumberInput source="aPeriod" label="aPeriod" />
        <NumberInput source="aPrice" label="aPrice" />
        <DateInput source="aCreated" label="aCreated" />
        <SelectInput source="aClassification" label="aClassification" choices={classifications} optionText="name" optionValue="id" />
        <SelectInput source="serviceType" label="serviceType" choices={clubServiceTypes} optionText="name" optionValue="id" />
        <SelectInput source="paymentType" label="paymentType" choices={paymentTypes} optionText="name" optionValue="id" />
        <SelectInput source="servedBy" label="servedBy" choices={servedBys} optionText="name" optionValue="id" />
        <TextInput source="companyCode" label="companyCode" />
        <TextInput source="aPartnerClub" label="aPartnerClub" />
        <TextInput source="refundEmail" label="refundEmail" />
    </Filter>
);

export const MobileClubList = (props) => (
    <List {...props} title="Clubs List" size="small" filters={<MobileClubFilter />} bulkActionButtons={false} sort={{ field: 'aRegion', order: 'ASC' }}>
        <Datagrid size={"small"}>
            <TextField source="aUnique" label="aUnique" className={"trimmedText width50"}  /> 
            <TextField source="aClubName" label="aClubName" />
            <TextField source="aName" label="aName" />
            <TextField source="aRegion" label="aRegion" />
            {/*<TextField source="aWapDomain" label="aWapDomain" className={"condensed"}/> */}
            <TextField source="aWebDomain" label="aWebDomain" className={"trimmedText width80"}/>
            <TextField source="aCurrency" label="aCurrency" />
            <TextField source="aAggregator" label="aAggregator" />
            <TextField source="aSmsNumber" label="aSmsNumber" />
            <TextField source="msisdnformat" label="msisdnformat" />
            <BooleanField source="aActive" label="aActive" />
            <TextField source="aType" label="aType" className={"trimmedText width50"}/>
            <TextField source="aPeriod" label="aPeriod" />
            <TextField source="aPrice" label="aPrice" />
            <DateField source="aCreated" showTime label="aCreated" className={"condensed"}/>
            <TextField source="aClassification" label="aClassification" />
            <TextField source="serviceType" label="serviceType" />
            <TextField source="paymentType" label="paymentType" />
            <TextField source="servedBy" label="servedBy" />
            <TextField source="companyCode" label="companyCode" />
            {/*<TextField source="aPartnerClub" label="aPartnerClub" />*/}
            <TextField source="refundEmail" label="refundEmail" className={"trimmedText width80"}/>
            {/*<EditButton basePath="/mobile_clubs_list" />*/}
        </Datagrid>
    </List>
);

/*
const MobileClubForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" /> }
        <TextInput source="name" validate={[required()]}/>
    </SimpleForm>
);

const MobileClubTitle = ({ record }) => {
    return <span>Mobile Club {record ? `#${record.id}` : ''}</span>;
};

export const MobileClubCreate = (props) => (
    <Create title="Create New Mobile Club" {...props}>
        <MobileClubForm withId={false} redirect="list" />
    </Create>
);

export const MobileClubEdit = (props) => (
    <Edit undoable={false} title={<MobileClubTitle />} {...props}>
        <MobileClubForm withId={true} />
    </Edit>
);
*/
