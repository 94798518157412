import React, { useState, useEffect } from 'react';
import { useNotify, useRedirect, SimpleForm, TextInput, BooleanInput, FormDataConsumer, Toolbar, SaveButton, required } from 'react-admin';
import { useForm } from 'react-final-form';
import { Link } from 'react-router-dom';
import { BACKEND_URL, rand } from '../common/constants';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import CPATests from './CPATests';

const DemoComp = (props) => {
    const { parentDemoUrl, demoHasRotator } = props;

    const [demoRotator, setDemoRotator] = useState(false);
    const [demoTrickyTime, setDemoTrickyTime] = useState(false);
    const [demoBlockId, setDemoBlockId] = useState(false);
    const [demo3gFlow, setDemo3gFlow] = useState(false);
    const [demoOperator, setDemoOperator] = useState(null);
    const [demoUrl, setDemoUrl] = useState(null);

    useEffect(() => {
        let txtDemoUrl = parentDemoUrl;
        let hasDemoParams = parentDemoUrl && !!parentDemoUrl.length;

        if (demoRotator) {
            if (hasDemoParams) {
                txtDemoUrl += '&';
            }
            txtDemoUrl += 'rotator=true';
            hasDemoParams = true;
        }
        if (demoTrickyTime) {
            if (hasDemoParams) {
                txtDemoUrl += '&';
            }
            txtDemoUrl += 'ttime=true';
            hasDemoParams = true;
        }
        if (demoBlockId) {
            if (hasDemoParams) {
                txtDemoUrl += '&';
            }
            txtDemoUrl += 'blockid=true';
            hasDemoParams = true;
        }
        if (demo3gFlow) {
            if (hasDemoParams) {
                txtDemoUrl += '&';
            }
            txtDemoUrl += 'flow=3g';
            hasDemoParams = true;
        }
        if (demoOperator && !!demoOperator.length) {
            if (hasDemoParams) {
                txtDemoUrl += '&';
            }
            txtDemoUrl += 'operator=' + demoOperator;
            hasDemoParams = true;
        }
        setDemoUrl(txtDemoUrl);

    }, [demoOperator, demo3gFlow, demoBlockId, demoTrickyTime, demoRotator, parentDemoUrl]);

    const Flow3gInput = (flow3gProps) => {
        const form = useForm();
        return (
            <BooleanInput {...flow3gProps} source="flow3g" label="3G Flow" onChange={(value) => { 
                setDemo3gFlow(value); 
                if (form) {
                    form.change('operator', null);
                }
                setDemoOperator(null);  
            }} />
        );
    };

    const handleSubmitDemoForm = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        return false;
    };
    
    return (
        <>
        <h3>Demo URL</h3>
        <a target="_blank" rel="noopener noreferrer" className="demo_url" href={demoUrl}>{demoUrl}</a>
        <SimpleForm
            resource="regions"
            toolbar={null}
            onSubmit={handleSubmitDemoForm}
            initialValues={{ rotator: demoRotator, ttime: demoTrickyTime, blockid: demoBlockId, flow3g: demo3gFlow, operator: demoOperator }}>
            <BooleanInput source="rotator" label="Rotator ON/OFF" options={{ disabled: !demoHasRotator }} onChange={(value) => { setDemoRotator(value); }} />
            <BooleanInput source="ttime" label="Tricky Time ON/OFF" onChange={(value) => { setDemoTrickyTime(value); }} />
            <BooleanInput source="blockid" label="BlockID ON/OFF" onChange={(value) => { setDemoBlockId(value); }} />
            <Flow3gInput />
            <FormDataConsumer>
                { ({formData, ...rest }) =>
                    formData.flow3g && <TextInput source="operator" label="Operator" onChange={(event) => { 
                        if (event && event.target) { 
                            setDemoOperator(event.target.value); 
                        } 
                    }} />
                }
            </FormDataConsumer>
        </SimpleForm>
        </>
    );
};

export default ({ record, ...props }) => {
    const [mobileClub, setMobileClub] = useState(null);
    const [advertisedUrl, setAdvertisedUrl] = useState(null);
    const [notes, setNotes] = useState(null);
    const [cid, setCid] = useState(null);
    const [demoUrl, setDemoUrl] = useState(null);
    const [demoHasRotator, setDemoHasRotator] = useState(false);

    const notify = useNotify();

    const redirect = useRedirect();

    useEffect(() => {
        if (!record) {
            return;
        }

        fetch(BACKEND_URL + '/mobile_clubs?aWapDomain=' + record.platform_domain + '&platform_code=' + record.platform, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                if (json && json.length) {
                    let club = json[0];
                    setMobileClub(club);
                    let protocol = null;
                    let txtNotes = '';
                    if (record.region === 'UK') {
                        if (record.advertised_domain && record.advertised_domain.startsWith('i.')) {
                            protocol = 'http';
                            txtNotes += 'HTTPS not available on this PFI domain as IMI does not support it. ';
                        } else {
                            protocol = 'https';
                            txtNotes += 'HTTPS available on this domain as it\'s PSMS. ';
                        }
                    } else {
                        protocol = 'https';
                    }

                    if (club) {
                        switch (club.aAggregator) {
                            case 'payvol':
                                protocol = 'http';
                                txtNotes += 'HTTPS <em>not</em> available on this Payvol domain. ';
                                break;
                            case 'pfi':
                                protocol = 'http';
                                txtNotes += 'HTTPS <em>not</em> available on this PFI domain. ';  
                                break;
                            default:
                                break;
                        }
                    }

                    let domain = record.advertised_domain;
                    let sourceId = record.source;
                    setCid(record.platform_aunique);

                    if (sourceId === '12') {
                        setAdvertisedUrl(`${protocol}://${domain}/?cid=${cid}&##MSISDN##`);
                        txtNotes += 'This URL is a special, condensed advertising URL used for UK broadcasts. ';
                    } else {
                        setAdvertisedUrl(`${protocol}://${domain}/?cid=${cid}`);
                        txtNotes += 'This URL is a "traditional"-style advertising URL used for most regions. ';
                    }
                    setNotes(txtNotes);
                    
                    let txtDemoUrl = 'https://demo.red27mobile.com/?';
                    let hasDemoParams = false;
                    if (club && club.aUnique && !!club.aUnique.length) {
                        txtDemoUrl += 'clubUnique=' + club.aUnique;
                        hasDemoParams = true;
                    }
                    if (record && record.platform_aunique && !!record.platform_aunique.length) {
                        if (hasDemoParams) {
                            txtDemoUrl += '&';
                        }
                        txtDemoUrl += 'cid=' + record.platform_aunique;
                        hasDemoParams = true;
                    }
                    setDemoUrl(hasDemoParams? txtDemoUrl : null);
                    setDemoHasRotator(record && record.platform_aunique && !!record.platform_aunique.length &&
                        ( (record.sourceCap && !!record.sourceCap.length) ||
                          (record.destinationCaps && !!record.destinationCaps.length) ) );
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    }, [cid, record, notify]);

    const [mobileClubCampaign, setMobileClubCampaign] = useState(null);

    useEffect(() => {
        if (!record) {
            return;
        }

        if (record.purpose && (record.purpose === 'campaign')) {
            if (record.platform_aunique && record.platform_aunique.length) {
                fetch(BACKEND_URL + '/mobile_club_campaigns/' + record.platform_aunique + '?platform_code=' + record.platform, { 
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                        } 
                    })
                    .then(response => response.json())
                    .then(json => {
                        if (json && json.length) {
                            let campaign = json[0];
                            setMobileClubCampaign(campaign);
                            notify('This campaign is present in the <strong>' + record.platform + '</strong> platform DB.');
                        } else {
                            setMobileClubCampaign(null);
                            /*fetch(BACKEND_URL + '/create_empty_mobile_club_campaign/' + record.platform_aunique + '?platform_code=' + record.platform, { 
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                                    } 
                                })
                                .then(response => {
                                    if (response.ok) {
                                        response.json().then(json => {*/
                                            notify('This campaign is not currently present in the <strong>' + record.platform + '</strong> platform DB. Please Save again to update it.', 'warning');
                                /*        });
                                    } else {
                                        response.json().then(e => {
                                            notify(e.message, 'warning');
                                        });
                                    }
                                })
                                .catch(e => { 
                                    notify(e.message, 'warning'); 
                                });*/
                        }
                    })
                    .catch(e => { 
                        notify(e.message, 'warning'); 
                    });            
            } else {
                notify('This campaign is not currently present in the <strong>' + record.platform + '</strong> platform DB. Please Save again to update it.', 'warning');
            }
        }
    }, [record, notify]);

    const [schedulesAndLandingPages, setSchedulesAndLandingPages] = useState([]);

    useEffect(() => {
        if (!record) {
            return;
        }

        if (record.platform_aunique && record.platform_aunique.length && record.purpose && (record.purpose === 'campaign')) {

        fetch(BACKEND_URL + '/get_assigned_schedules_and_landing_pages/' + record.platform_aunique + '?platform_code=' + record.platform, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                setSchedulesAndLandingPages(json);
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });            
        }
    }, [record, notify]);

    const [oldLandingPages, setOldLandingPages] = useState([]);

    useEffect(() => {
        if (!record) {
            return;
        }

        if (record.platform_aunique && record.platform_aunique.length && record.purpose && (record.purpose === 'campaign')) {

        fetch(BACKEND_URL + '/get_old_landing_pages/' + record.platform_aunique + '?platform_code=' + record.platform, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                setOldLandingPages(json);
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });            
        }
    }, [record, notify]);

    //const [usedTmpFakeIds, setUsedTmpFakeIds] = useState([]);

    //const [tmpFakeId, setTmpFakeId] = useState(null);

    /*const regenerateFakeId = () => {
        fetch(BACKEND_URL + '/generate_fake_id', { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                setTmpFakeId(json.tmpFakeId);
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    };*/

    const handleSubmitTestCPA = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        if (!record) {
            return;
        }

        /*if (tmpFakeId && tmpFakeId.length) {
            if (usedTmpFakeIds.indexOf(tmpFakeId) >= 0) {
                notify('This test ID has been used, so the fake will not be resent', 'warning');
                return;
            }
            setUsedTmpFakeIds([...usedTmpFakeIds, tmpFakeId]);
            regenerateFakeId();
        } else {
            regenerateFakeId();
        }*/

        notify(`A <strong>${values.test_or_fake}</strong> has been requested`);

        let country_code, filler_digits;

        switch (record.region) {
            case 'AU':
                country_code = '61';
                filler_digits = '52'; // to get the length right (matching a real MSISDN)
                break;
            case 'CH':
                country_code = '41';
                filler_digits = '34';
                break;
            case 'ES':
                country_code = '34';
                filler_digits = '34';
                break;
            case 'IE':
                country_code = '353';
                filler_digits = '2';
                break;
            case 'IN':
                country_code = '91';
                filler_digits = '34';
                break;
            case 'IT':
                country_code = '39';
                filler_digits = '341';
                break;
            case 'KE':
                country_code = '254';
                filler_digits = '341';
                break;
            case 'MX':
                country_code = '52';
                filler_digits = '34';
                break;
            case 'NO':
                country_code = '47';
                filler_digits = '341';
                break;
            case 'PO':
                country_code = '48';
                filler_digits = '34';
                break;
            case 'PT':
                country_code = '383';
                filler_digits = '34';
                break;
            case 'SE':
                country_code = '46';
                filler_digits = '341';
                break;
            case 'UK':
                country_code = '44';
                filler_digits = '34';
                break;
            case 'ZA':
                country_code = '27';
                filler_digits = '34';
                break;
            case 'ZI':
            default:
                country_code = '263';
                filler_digits = '341';
                break;
        }

        notify(`The network name used for the test CPA was <strong>${values.networkToUse}</strong>`);

        let random_number = rand(1, 999);
        let random_part = '' + random_number;
        if (random_part.length === 1) {
            random_part = '00' + random_part;
        } else if (random_part.length === 2) {
            random_part = '0' + random_part;
        }

        let obj = {};
        obj.aHashcode = '';
        obj.aAmount = '';
        obj.aTransactionid = '';
        obj.aCurrency = '';
        obj.aPayoutType = '';
        obj.aParsedMobile = country_code + values.test_or_fake + random_part + filler_digits;
        obj.aSrc = record.source_object? record.source_object.name : '';
        obj.aCampaign = record.platform_aunique;
        obj.aClubUnique = mobileClub.aUnique;
        //obj.aCreated = now();
        //obj.aNextPush = now();
        obj.status = 0;
        obj.aNetworkCode = values.networkToUse;

        fetch(BACKEND_URL + '/save_cpa_logging', { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.success) {
                            notify(`The <strong>${values.test_or_fake}</strong> was posted, ready to be notified. When notification is successfull it will be seen below.`);
                        }
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                notify(e.message, 'warning');
            });
    };

    const TestCPAToolbar = ({handleSubmitWithRedirect, ...props}) => {
        const form = useForm();

        const handleClickTest = () => {
            form.change('test_or_fake', 'test');
            form.submit();
        };

        const handleClickFake = () => {
            form.change('test_or_fake', 'fake');
            form.submit();
        };

        useEffect(() => {
            if (record && record.cpa_payouts_only_for_network && (record.cpa_payouts_only_for_network !== 'ALL')) {
                form.change('networkToUse', record.cpa_payouts_only_for_network);
            } else {
                form.change('networkToUse', 'test');
            }
        }, [form]);

        return (
            <Toolbar {...props}>
                <SaveButton handleSubmitWithRedirect={handleClickTest} variant="contained" color="primary" label="Test" />
                <SaveButton handleSubmitWithRedirect={handleClickFake} variant="contained" color="primary" label="Fake" />
            </Toolbar>
        );
    };

    const createCampaignFromOffer = (e) => {
        if (!record) {
            return;
        }

        fetch(BACKEND_URL + '/create_campaign_from_offer/' + record.id, { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify({})
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.success) {
                            redirect(`/campaigns/${json.newId}`);
                            notify(`A new campaign was created from the offer. The offer’s ID was <strong>${json.oldId}</strong>. The campaign's ID is <strong>${json.newId}</strong>`);
                        }
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });    
    };

    const useStyles = makeStyles(theme => ({
        root: {
            fontSize: '0.9em'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            { record && (record.live !== 1) && 
            <div style={{ marginBottom: '10px', padding: '3px', backgroundColor: 'red', color: 'white' }}>
                This campaign is <strong>not set to <em>live</em></strong>
            </div> }

            { record && (record.purpose === 'campaign') &&
            <div>
                <h3>Advertised URL</h3>
                <a target="_blank" rel="noopener noreferrer" className="advertised_url" href={advertisedUrl}>{advertisedUrl}</a>
                <p>{notes}</p>
                { demoUrl && !!demoUrl.length &&
                    <DemoComp parentDemoUrl={demoUrl} demoHasRotator={demoHasRotator} />
                }
                <h3>Tableau report</h3>
                <p>
                    <a target="_blank" rel="noopener noreferrer" href={`https://dub01.online.tableau.com/#/site/cliqdigitaleu/views/BIReport/BIReport?:iid=1&Campaign=${cid}`}>View a Tableau report</a> for this campaign
                </p>
            </div> }

            { record && (record.purpose === 'campaign') &&
            <div>
                <h3>Synchronise Records</h3>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content">
                        <Typography className={classes.heading}>Pareva DB State</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Table aria-labelledby="tableTitle">
                            <TableBody>
                                {mobileClubCampaign && Object.keys(mobileClubCampaign).map(k => 
                                    <TableRow key={k}>
                                        <TableCell>{k}</TableCell>
                                        <TableCell>{mobileClubCampaign[k]}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div> }

            { record && (record.purpose === 'campaign') &&
            <div>
                <h3>Assigned Schedules and Landing Pages</h3>
                <ul>
                    {schedulesAndLandingPages.map(slp => 
                        <li key={`${slp.aUnique}_${slp.landingPageUnique}`}>
                            <strong>Schedule:</strong> <abbr title={slp.aUnique}>{slp.scheduleName}</abbr> &nbsp;
                            <strong>LP:</strong> <abbr title={slp.landingPageUnique}>{slp.landingPageName}</abbr>
                        </li>) }
                </ul>
            </div> }

            { record && (record.purpose === 'campaign') &&
            <div>
                <h3>Assigned Landing Pages (old system)</h3>
                <ul>
                    {oldLandingPages.map(olp => <li key={olp.aLandingPageCode}>{olp.aLandingPageCode}</li>) }
                </ul>
            </div> }

            { record && (record.payout_type === 'CPA') && (record.purpose === 'campaign') &&
            <div>
                <h3>Send test CPA notifications</h3>
                <p>Click either Fake or Test. Each click sets up a CPA postback request (which may be charged after the campaign test
                phase - a "fake"). After processing by DynamicCPA (which normally takes up to three minutes), fakes/tests which
                match with your clicks will be shown below in a table.</p>
                <SimpleForm
                    resource="regions"
                    onSubmit={handleSubmitTestCPA}
                    toolbar={<TestCPAToolbar />}
                >
                    <TextInput 
                        source="networkToUse" 
                        label="Network" 
                        validate={required()} 
                    />
                </SimpleForm>
            </div> }

            { record && (record.payout_type === 'CPA') && (record.purpose === 'campaign') &&
                <CPATests record={record} />
            }

            { record && (record.purpose === 'offer') &&
            <div>
                <p>This is an offer which is ready to use as a template for a campaign</p>
                <Button color="primary" variant="contained" onClick={createCampaignFromOffer}>Create a Campaign</Button> based on this Offer.
                <ul>
                    <li><Button color="primary" component={Link} to={{ pathname: '/campaign_screenshots', search:`?filter={"campaign_id":"${record.id}"}` }}>Edit screenshots</Button></li>
                    <li><Button color="primary" component={Link} to={{ pathname: `/campaign_promo/${record.id}` }}>Edit Promo material</Button></li>
                    <li><Button color="primary" component={MuiLink} href={`https://selfservice.red27mobile.com/offers/${record.id}`} target="_blank">Preview in Self-Service</Button></li>
                </ul>
            </div> }
        </div>
    );
};

