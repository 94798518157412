import React, { useEffect, useContext } from 'react';
import { List, Datagrid, TextField, SelectField, ReferenceManyField, DateField, SingleFieldList, Filter, 
    TextInput, SelectInput, ReferenceInput, DateInput, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';   
import { companyCodes, contactMethods, contactReasons } from '../common/constants';
import { SelectInputWithTip, BooleanInputWithTip } from '../components/TooltipComponents';
import { MyContext } from '../common/MyContext';
import Chip from '@material-ui/core/Chip';

const ContactLogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />    
        <ReferenceInput source="aRegionFast" label="Region" reference="regions" perPage={100000} sort={{ field: 'id', order: 'ASC' }} alwaysOn>
            <SelectInput optionText="aRegion" />
        </ReferenceInput>        
        <SelectInputWithTip source="aCompanyCode" label="Entity" choices={companyCodes} optionText="name" optionValue="name" alwaysOn 
            title="WARNING!!! If you select this filter the query will be VERY SLOW. So be patient!" />
        <SelectInput source="aContactMethod" label="Contact Method" choices={contactMethods} optionText="name" optionValue="id" alwaysOn />        
        <DateInput source="startDate" locales="en-GB" label="Start Date" alwaysOn />
        <DateInput source="endDate" locales="en-GB" label="End Date" alwaysOn />   
        <BooleanInputWithTip source="withServices" label="With Service & Entity" default={false} alwaysOn
            title="WARNING!!! If you check this filter the query may be SLOW. So be patient!" />    
        <TextInput source="id" label="ID" />
        <TextInput source="aParsedMobile" label="MSISDN" />
        <SelectInput source="aContactReason" label="Contact Reason" choices={contactReasons} optionText="name" optionValue="id" />        
    </Filter>
);

const ContactLogExporter = (records, fetchRelatedRecords) => {
    if (!(records && !!records.length)) {
        return;
    }
    
    const getReasonName = (reasonId) => {
        let r = contactReasons.find(reason => reason.id === reasonId);
        if (r && r.name && !!r.name.length) {
            return r.name;
        }
        return null;
    };
    
    const data = records.map(record => ({...record, aContactReason: getReasonName(record.aContactReason)}));
    let columns = Object.keys(records[0]);
    
    jsonExport(data, {
        headers: columns // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, `customer_contact_log`);
    });    
};

const ManyChipsField = ({ record, source }) => (
    <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start' }}>
      { record[source].map(ent => <Chip key={ent} label={ent} />) }
    </div>
);

export const ContactLogList = (props) => {
    const myContext = useContext(MyContext);
    useEffect(() => {
        if (myContext) {
            myContext.setShowIndicator(true);
        }
        
        return function cleanup() {
            if (myContext) {
                myContext.setShowIndicator(false);
            }
        };
    }, [myContext]);
    
    return (
    <List {...props} title="Customer Contact Log" filters={<ContactLogFilter />} bulkActionButtons={false} exporter={ContactLogExporter} sort={{ field: 'aCreated', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="aParsedMobile" label="MSISDN" />
            <TextField source="aContactMethod" label="Contact Method" />
            <SelectField source="aContactReason" label="Contact Reason" choices={contactReasons} optionText="name" optionValue="id" />
            <ManyChipsField source="entities" label="Entities" />
            <ManyChipsField source="services" label="Services" />
            <DateField source="aCreated" locales="en-GB" label="Created" />
            <ReferenceManyField source="aCreatedBy" label="Created By" target="username" reference="users">
                <SingleFieldList>
                    <TextField source="fullname" />
                </SingleFieldList>
            </ReferenceManyField>
            {/*<EditButton basePath="/customer_contact_log" />*/}
        </Datagrid>
    </List>
    );
};

