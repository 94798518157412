import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, cloneElement } from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, Filter, TextInput, SelectInput, NumberInput,
         DateInput, DateTimeInput, ReferenceInput, ReferenceArrayInput, BooleanInput, ArrayInput, TopToolbar, sanitizeListRestProps, 
         EditButton, FormDataConsumer, SimpleFormIterator, SimpleForm, Create, Edit, required, 
         useNotify, useRedirect, useRefresh, useDataProvider, fetchStart, fetchEnd, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { useDispatch } from 'react-redux';         
import { parse } from "query-string";
import { Link } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloneIcon from '@material-ui/icons/Queue';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { sublists, offerTypes, purposes, campaignTypes, landingPageModes, payoutTypes, payoutCurrencies, 
         paramsModes, cpaTypes, cpaSpecialRules, /*serviceTypes,*/ categories, flows, /*evinaTypes,*/ BACKEND_URL } from '../common/constants';
import { TextInputWithTip, NumberInputWithTip, SelectInputWithTip, BooleanInputWithTip, AutocompleteInputWithTip, AutocompleteArrayInputWithTip } from '../components/TooltipComponents';
import CPAParams from '../components/CPAParams';
import CampaignManager from '../components/CampaignManager';
import ServiceNotes from '../components/ServiceNotes';
import CPAParamsInTab from '../components/CPAParamsInTab';
import AuditLog from '../components/AuditLog';
import CapRotatorHistory from '../components/CapRotatorHistory';

const Spacer = () => <span style={{ width: '1em' }} />;

const SublistFilter = ({ setSublist, ...props }) => {
    const form = useForm();
    return (
        <FormDataConsumer {...props}>
            { ({formData, ...rest }) =>
                <SelectInput source="sublist" label="Sublist" choices={sublists} optionText="name" optionValue="id" 
                     onChange={event => { 
                         form.change('offer_type', null); 
                         if (event && event.target) 
                             setSublist(event.target.value); 
                     }} />
            }
        </FormDataConsumer>
    );
};
 
const CampaignFilter = ({ setSublist, ...props }) => (
    <Filter {...props} >
        <SublistFilter source="sublist" setSublist={setSublist} alwaysOn />
        <TextInputWithTip label="Free Search" source="q" alwaysOn 
            title="This is the standard search filter that accept all kinds of strings typed by the user. Please DON'T USE this filter together with the 'AutoComplete Search' filter!" />
        <ReferenceInput source="q2" label="AutoComplete Search" reference="campaign_suggestions" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInputWithTip optionText="name" optionValue="id" 
                title="This field filters the query by specific keywords displayed by AutoComplete widget. Please DON'T USE this filter together with the 'Free Search' filter!" />
        </ReferenceInput>
        <TextInput source="id" label="ID" />
        <TextInput source="name" label="Name" />
        <ReferenceInput source="region" label="Region" reference="regions" sort={{ field: 'id', order: 'ASC' }} perPage={100000} alwaysOn>
            <SelectInput optionText="aRegion" />
        </ReferenceInput>
        <FormDataConsumer source="platform_aunique" alwaysOn>
            { ({formData, ...rest }) => formData.sublist && (formData.sublist !== 'offers') &&
                <TextInput source="platform_aunique" label="Platform aUnique" />
            }
        </FormDataConsumer>
        {/*<ReferenceInput source="advertised_domain" label="Advertised Domain" reference="advertised_domains" perPage={100000} sort={{ field: 'link', order: 'ASC' }} alwaysOn>
            <SelectInput optionText="linkText" optionValue="id" /> 
        </ReferenceInput>*/}
        <TextInput source="advertised_domain" label="Advertised Domain" alwaysOn />
        {/*<ReferenceInput source="source" label="Source" reference="sources" perPage={100000} sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>*/}
        <TextInput source="source_name" label="Source" alwaysOn />
        <SelectInput source="payout_type" label="Payout Type" choices={payoutTypes} optionText="name" optionValue="name" />
        <SelectInput source="payout_currency" label="Payout Currency" choices={payoutCurrencies} optionText="name" optionValue="name" />
        <SelectInput source="cpa_type" label="CPA Type" choices={cpaTypes} optionText="name" optionValue="name" />
        <SelectInput source="params_mode" label="Params Mode" choices={paramsModes} optionText="name" optionValue="id" />
        <DateInput source="created_at" label="Created At" />
        <ReferenceInput source="created_by" label="Created By" reference="users" perPage={100000} sort={{ field: 'fullname', order: 'ASC' }}>
            <SelectInput optionText="fullname" optionValue="id" /> 
        </ReferenceInput>
        <FormDataConsumer source="rotators_only" alwaysOn className="breakAfter">  
            { ({formData, ...rest }) => formData.sublist && (formData.sublist !== 'offers') &&
                <BooleanInput source="rotators_only" label="CAP Rotators" defaultValue={false} />
            }
        </FormDataConsumer>
        <FormDataConsumer source="offer_type" alwaysOn>
             { ({formData, ...rest }) => formData.sublist && (formData.sublist === 'offers') &&
                 <SelectInput source="offer_type" label="Offer Type" choices={offerTypes} optionText="name" optionValue="name" />
             }
        </FormDataConsumer>
        <BooleanInputWithTip source="archived" label="Is Archived" defaultValue={false} title="Is offer archived in Self-Service module"/>
    </Filter>
);

const NewSourceButton = (props) => (
    <Button color="primary" variant="contained" component={Link} to={{ pathname: '/sources/create' }}>New Source</Button>
);

const NewCampaignButton = (props) => (
    <Button color="primary" variant="contained" component={Link} to={{ pathname: '/campaigns/create', state: {record: { purpose: 'campaign' }} }}>New Campaign</Button>
);

const NewOfferButton = (props) => (
    <Button color="primary"  variant="contained" component={Link} to={{ pathname: '/campaigns/create', state: {record: { purpose: 'offer' }} }}>New Offer</Button>
);

const CampaignActions = ({ className, permanentFilter, filters, resource, showFilter, displayedFilters, filterValues, ...rest }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <Spacer />
        <NewSourceButton />
        <Spacer />
        <NewCampaignButton />
        <Spacer />
        <NewOfferButton />
    </TopToolbar>
);

const CampaignCloneButton = ({ record, inList, ...props }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    
    const handleClick = (e) => {
        if (!record) {
            return;
        }

        fetch(BACKEND_URL + '/clone_campaign/' + record.id, { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.success) {
                            if (/*inList*/ false) {
                                refresh();
                            } else {
                                redirect(`/campaigns/${json.newId}`);
                            }
                            notify(`Record cloned. The original record’s ID was <strong>${json.oldId}</strong>. The new ID is <strong>${json.newId}</strong>`);
                        }
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    };

    return (
        <Button color="primary" variant={ inList? 'text' : 'contained' } onClick={handleClick}><CloneIcon /> Clone</Button>
    );
};

export const CampaignList = (props) => {
    const [sublist, setSublist] = useState('recent');

    try {
        const obj = parse(props.location.search);
        if (obj && obj.filter) {
            const obj2 = JSON.parse(obj.filter);
            if (obj2 && obj2.sublist) {
               if (sublist !== obj2.sublist) {
                   setSublist(obj2.sublist);
               }
            }
        }
    } catch(e) {
        console.log(e);
    }

    return (
    <List {...props} filters={<CampaignFilter setSublist={setSublist} />} actions={<CampaignActions />}  bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <ReferenceField source="id" label="Name" reference="campaigns">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="region" label="Reg" />
            { (sublist !== 'offers') && <TextField source="platform_aunique" label="Platform aUnique"  className={" spacingShrinked"}/> }
            <TextField source="advertised_domain" label="Advertised domain" />
            <ReferenceField source="source" label="Source" reference="sources">
                <TextField source="name" />
            </ReferenceField>
            { (sublist === 'offers') && <TextField source="offer_type" label="Offer Type" /> }
            <TextField source="payout_type" label="P-t Type" alt="Payout Type" className={"width50"} />
            <TextField source="payout_currency" label="Curr" />
            { (sublist === 'offers') && <TextField source="payout" label="Payout" /> }
            <TextField source="cpa_type" label="CPA type" />
            { (sublist !== 'offers') && <TextField source="params_mode" label="Param mod" /> }
            <DateField source="created_at" showTime label="Created At" className={"trimmedText width70 spacingShrinked"}/>
            <ReferenceField source="created_by" label="Created By" reference="users">
                <TextField source="fullname" />
            </ReferenceField>
            <EditButton basePath="/campaigns" label="Edit"/>
            <CampaignCloneButton inList={true} label="Clone" />
        </Datagrid>
    </List>
    );
};

const CampaignTabs = ({ permissions, record, ...props }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Card>
        <CardContent>
            <MuiTabs value={selectedTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" onChange={handleChange} >
                <MuiTab key={0} value={0} label="Manager" />
                <MuiTab key={1} value={1} label="Service Notes" />
                { record && (record.payout_type === 'CPA') && record.platform_aunique && !!record.platform_aunique.length && <MuiTab key={2} value={2} label="CPA Params" /> }
                <MuiTab key={3} value={3} label="Audit log" />
                { record && record.platform_aunique && !!record.platform_aunique.length && <MuiTab key={4} value={4} label="CAP Rotator History" /> }
            </MuiTabs>
            <Divider />
            { (selectedTab === 0) && permissions && permissions.priv && (permissions.priv >= 5) && record && record.id && <CampaignManager record={record} /> }
            { (selectedTab === 1) && permissions && permissions.priv && (permissions.priv >= 5) && record && record.id && <ServiceNotes record={record} /> }
            { (selectedTab === 2) && permissions && permissions.priv && (permissions.priv >= 5) && record && record.id && (record.payout_type === 'CPA') && record.platform_aunique && record.platform_aunique.length && <CPAParamsInTab record={record} /> }
            { (selectedTab === 3) && permissions && permissions.priv && (permissions.priv >= 5) && record && record.id && <AuditLog record={record} tableName="campaigns" /> }
            { (selectedTab === 4) && permissions && permissions.priv && (permissions.priv >= 5) && record && record.id && <CapRotatorHistory record={record} /> }
        </CardContent>
        </Card>
    );
};

const useStyles = makeStyles({
    standardInput: {
        minWidth: '160px',
        width: '256px'
    },
    inlineBlock: {
        display: 'inline-flex',
        marginRight: '1rem'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    destCaps: {
        maxWidth: '700px'
    },
    hidden: {
        display: 'none',
    },
});

let notEvinaSources = [];

const PurposeInput = (props) => {
    const form = useForm();
    return (
        <SelectInputWithTip {...props} source="purpose" label="Purpose" choices={purposes} optionText="name" optionValue="name" validate={[required()]} 
            title="What is this record for? An offer is a template for a campaign." 
            onChange={event => {
                if (event && event.target && event.target.value && (event.target.value === 'offer')) {
                } else {
                    form.change('multi_sources_ids', []);
                }
            }} />    
    );
};

const RegionInput = (props) => {
    const form = useForm();
    return (
        <FormDataConsumer {...props}>
            { ({ formData, ...rest }) =>
                <ReferenceInput {...rest} source="region" label="Region" reference="regions" sort={{ field: 'id', order: 'ASC' }} perPage={100000} 
                    onChange={event => { 
                        form.change('platform_domain', null); 
                        form.change('landing_pages', null); 
                        form.change('interstitial_landing_page', null);
                        if (formData.purpose === 'campaign') {
                            if (!(event && event.target && event.target.value && (event.target.value === 'FR')) &&
                                notEvinaSources && !!notEvinaSources.filter(s => s.id === formData.source).length) {
                                    form.change('evina', true); //false //regulators changed
                            } else {
                                form.change('evina', true);
                            }
                        } 
                        form.change('destinationCaps', []);
                }}>
                    <SelectInputWithTip optionText="aRegion" validate={[required()]} title="The two-letter region code" />
                </ReferenceInput>
            }
        </FormDataConsumer>
    );
};

const selectOldAdvDomain = (oldAdvDomain, platform_domain, source, form, notify, dispatch) => {
    if (!oldAdvDomain || !form) {
        return;
    }
    let url = BACKEND_URL + '/advertised_domains?_start=0&_end=100000&_sort=link&_order=ASC';
    if (platform_domain && !!platform_domain.length) {
        url += '&platform_domain=' + platform_domain;
    }
    if (source) {
        url += '&source=' + source;
    }
    
    dispatch(fetchStart());
    fetch(url, { 
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('api_token')
            } 
        })
        .then(response => {
            if (response.ok) {
                response.json().then(json => {
                    dispatch(fetchEnd());
                    if (json) {
                        let filtered = json.filter(ad => ad.id === oldAdvDomain);
                        if (filtered && !!filtered.length) {
                            form.change('advertised_domain', oldAdvDomain);
                        } else {
                            form.change('advertised_domain', null);
                        }
                    } else {
                        form.change('advertised_domain', null);
                    }
                });
            } else {
                response.json().then(e => {
                    dispatch(fetchEnd());
                    notify(e.message, 'warning');
                });
            }
        })
        .catch(e => {
            dispatch(fetchEnd()); 
            notify(e.message, 'warning'); 
        });        
};

const ServiceInput = (props) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const form = useForm();
    return (
        <FormDataConsumer {...props}>
            { ({ formData, ...rest }) =>
                <ReferenceInput {...rest} source="platform_domain" label="Service" reference="services" filter={{ region: formData.region }} perPage={100000} /*sort={{ field: 'name', order: 'ASC' }}*/
                    onChange={event => { 
                        selectOldAdvDomain(formData.advertised_domain, event.target.value, formData.source, form, notify, dispatch);
                        form.change('schedule', null); 
                        form.change('schedule_landing_pages', null); 
                        form.change('landing_pages', null);
                        form.change('destinationCaps', []); 
                    }}>
                    <SelectInputWithTip optionText="name" optionValue="id" 
                        title="The service within Pareva, as you would select in the WAP site editor. Actually stores the platform Domain record unique" />
                </ReferenceInput>
            }
        </FormDataConsumer>
    );
};

const SourceInput = ({allSources, ...props}) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const form = useForm();
    const classes = useStyles();
    return (
        <FormDataConsumer {...props}>
            { ({formData, ...rest }) => 
                <ReferenceInput 
                    source="source" 
                    label={(formData.purpose && (formData.purpose === 'offer'))? 'Main Source' : 'Source'} 
                    className={classes.standardInput} 
                    reference="sources" 
                    perPage={100000} 
                    sort={{ field: 'name', order: 'ASC' }}
                    onChange={event => {
                        selectOldAdvDomain(formData.advertised_domain, formData.platform_domain, event.target.value, form, notify, dispatch); 
                        if (formData.purpose === 'campaign') {
                            if ((formData.region !== 'FR') &&
                                event && event.target && event.target.value && notEvinaSources && !!notEvinaSources.filter(s => s.id === event.target.value).length) {
                                    form.change('evina', true); //false //regulators changed
                            } else {
                                form.change('evina', true);
                            }

                            if (event && event.target && event.target.value && notEvinaSources && !!notEvinaSources.filter(s => s.id === event.target.value).length) {
                                    form.change('monitor_logic', false);
                            } else {
                                form.change('monitor_logic', true);
                            }
                        } 
                        if (event && event.target && event.target.value && allSources && !!allSources.length && !!allSources.filter(s => s.id === parseInt(event.target.value, 10)).length) {
                            form.change('multi_sources_ids', []);
                        }
                    }}>
                    <SelectInputWithTip optionText="name" optionValue="id" 
                        title={(!formData.purpose || (formData.purpose === 'campaign'))? 
                            'Where traffic on this campaign comes from (the advertiser)' : 
                            'Offer is visible to the advertiser and specific additional affiliates (see below), or everyone (ALL)'} />
                </ReferenceInput>
            }
        </FormDataConsumer>
    );
};

const DestinationCaps = ({ hidden_all, ...props }) => {
    const form = useForm();
    const classes = useStyles();
    return (
        <ArrayInput {...props} source="destinationCaps" label="Destination CAPs" className={classnames(classes.destCaps, {[classes.hidden]: hidden_all })}>
            <SimpleFormIterator>
                <FormDataConsumer formClassName={classes.inlineBlock}>
                    { ({formData, scopedFormData, getSource, ...restClub }) =>        
                    <ReferenceInput {...restClub} 
                        source={getSource('destinationClubUnique')} 
                        label="Destination Club" 
                        reference="services_for_cap_rotator" 
                        perPage={100000} 
                        filter={{ 
                            region: formData.region, 
                            not_domain: formData.platform_domain
                        }} 
                        onChange={event => { 
                            //form.change(getSource('destinationCampaignUnique'), null);
                            form.change(getSource('destinationLandingPageUnique'), null);
                            form.change(getSource('destinationScheduleUnique'), null);
                        }}>
                        <SelectInput optionText="name" optionValue="id" validate={[required()]} />
                    </ReferenceInput>
                    }
                </FormDataConsumer>

                <NumberInput source="destinationCap" label="Destination CAP" validate={[required()]} formClassName={classes.inlineBlock} />

                {/*<FormDataConsumer>
                    { ({formData, scopedFormData, getSource, ...restCampaign }) => 
                    scopedFormData && scopedFormData.destinationClubUnique && !!scopedFormData.destinationClubUnique.length &&       
                    <ReferenceInput {...restCampaign} 
                        key={`camp_for_club_${scopedFormData.destinationClubUnique}`} 
                        source={getSource('destinationCampaignUnique')} 
                        label="Destination Campaign" 
                        reference="campaigns_for_cap_rotator" 
                        filter={{ 
                            purpose: 'campaign', 
                            region: formData.region, 
                            platform_club: scopedFormData.destinationClubUnique 
                        }} 
                        filterToQuery={searchText => ({ 
                            q: [searchText], 
                            platform_club: scopedFormData.destinationClubUnique 
                        })} 
                        perPage={100000}>
                        <AutocompleteInput optionText="name" optionValue="id" validate={[required()]} />
                    </ReferenceInput>
                    }
                </FormDataConsumer>*/}

                <FormDataConsumer formClassName={classes.inlineBlock}>
                    { ({formData, scopedFormData, getSource, ...restLandingPageMode }) => 
                    !(formData.type && !!formData.type.length && (formData.type === 'api')) && 
                    scopedFormData && scopedFormData.destinationClubUnique && !!scopedFormData.destinationClubUnique.length &&        
                    <SelectInput {...restLandingPageMode} 
                        key={`lpmode_for_club_${scopedFormData.destinationClubUnique}`} 
                        source={getSource('destinationLandingPageMode')} 
                        label="Destination Landing Page Mode" 
                        choices={landingPageModes} 
                        optionText="name" 
                        optionValue="name" 
                        defaultValue={'Direct Link'} 
                        validate={[required()]}
                        onChange={event => { 
                            form.change(getSource('destinationLandingPageUnique'), null);
                            form.change(getSource('destinationScheduleUnique'), null);
                        }} />
                    }
                </FormDataConsumer>
                
                <FormDataConsumer formClassName={classes.inlineBlock}>
                    { ({formData, scopedFormData, getSource, ...restLandingPage }) => 
                    !(formData.type && !!formData.type.length && (formData.type === 'api')) && 
                    scopedFormData && scopedFormData.destinationClubUnique && !!scopedFormData.destinationClubUnique.length &&
                    scopedFormData.destinationLandingPageMode && (scopedFormData.destinationLandingPageMode === 'Direct Link') && 
                    <ReferenceInput {...restLandingPage} 
                        key={`lp_for_club_${scopedFormData.destinationClubUnique}`} 
                        source={getSource('destinationLandingPageUnique')} 
                        label="Destination Landing Page" 
                        reference="schedule_landing_pages" 
                        filter={{ 
                            platform_club: scopedFormData.destinationClubUnique 
                        }} 
                        perPage={100000}>
                        <SelectInput optionText="name" optionValue="id" validate={[required()]} />
                    </ReferenceInput>
                    }
                </FormDataConsumer>

                <FormDataConsumer formClassName={classes.inlineBlock}>
                    { ({formData, scopedFormData, getSource, ...restSchedule }) => 
                    !(formData.type && !!formData.type.length && (formData.type === 'api')) && 
                    scopedFormData && scopedFormData.destinationClubUnique && !!scopedFormData.destinationClubUnique.length &&        
                    scopedFormData.destinationLandingPageMode && (scopedFormData.destinationLandingPageMode === 'Schedule') && 
                    <ReferenceInput {...restSchedule} 
                        key={`schedule_for_club_${scopedFormData.destinationClubUnique}`} 
                        source={getSource('destinationScheduleUnique')} 
                        label="Destination Schedule" 
                        reference="schedules" 
                        filter={{ 
                            platform_club: scopedFormData.destinationClubUnique 
                        }} 
                        perPage={100000}>
                        <SelectInput optionText="name" optionValue="id" validate={[required()]} />
                    </ReferenceInput>
                    }
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const CampaignForm = ({ withId, ...props }) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchStart());
        fetch(BACKEND_URL + '/sources?not_evina=true', { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json) {
                            notEvinaSources = json;
                        } else {
                            notEvinaSources = [];
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });        
    }, [dispatch, notify]);

    const [allSources, setAllSources] = useState([]);
    
    useEffect(() => {
        dispatch(fetchStart());
        fetch(BACKEND_URL + '/sources?_start=0&_end=100000&_sort=name&_order=ASC&name_exact=ALL', { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json) {
                            setAllSources(json);
                        } else {
                            setAllSources([]);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });        
    }, [dispatch, notify]);

    const classes = useStyles();
    return (
    <SimpleForm {...props}>
        <TextInput source="platform" label="Platform" disabled />
        { withId && <TextInput disabled source="id" label="ID" /> }
        <PurposeInput />
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'offer')) &&
                <SelectInputWithTip source="category" label="Category" className={classes.standardInput} choices={categories} optionText="name" optionValue="name" validate={[required()]} 
                    title="The offer's category. Shown in Self Service." />
            }
        </FormDataConsumer>
        <TextInputWithTip source="name" style={{ width: "90%" }} label="Name" validate={[required()]} title="The campaign's name" />
        <TextInputWithTip source="description" label="Description" multiline title="A brief description used in reports" />
        <RegionInput /> 

        <ServiceInput formClassName={classes.inlineBlock} />
        <FormDataConsumer formClassName={classes.inlineBlock}>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) &&
                <NumberInput {...rest} source="sourceCap" label="Source CAP" />
            }
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.inlineBlock}>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) &&
                <BooleanInputWithTip {...rest} source="cap_active" label="Is CAP Rotator Active" defaultValue={false} 
                    title="You can temporary turn on/off CAP Rotator functionality for this campaign." />
            }
        </FormDataConsumer>

        <SelectInput source="landing_page_mode" label="Landing Page Mode" choices={landingPageModes} optionText="name" optionValue="name" validate={[required()]} />
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.landing_page_mode || (formData.landing_page_mode === 'Schedule')) && 
                <ReferenceInput source="schedule" label="Schedule" className={classes.standardInput} reference="schedules" filter={{ platform_domain: formData.platform_domain }}
                    perPage={100000} /*sort={{ field: 'aName', order: 'ASC' }}*/>
                    <SelectInputWithTip optionText="name" optionValue="id" validate={[required()]} title="The schedule to use, as set in Pareva WSA" />
                </ReferenceInput>
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.landing_page_mode || (formData.landing_page_mode === 'Direct Link')) &&  
                <ReferenceInput source="schedule_landing_pages" label="Schedule Landing Pages" className={classes.standardInput} reference="schedule_landing_pages" 
                    filter={{ platform_domain: formData.platform_domain }} perPage={100000} /*sort={{ field: 'aName', order: 'ASC' }}*/>
                    <SelectInputWithTip optionText="name" optionValue="id" validate={[required()]} title="The selected landing page from the new schedule system" />
                </ReferenceInput>
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) && (formData.region === 'UK') && (formData.platform_domain !== '2316175081151llun') && 
                (!formData.landing_page_mode || (formData.landing_page_mode === 'Direct Link')) && formData.platform_domain && 
                <ReferenceInput source="landing_pages" label="Old Landing Pages" className={classes.standardInput} reference="domain_landing_pages" filter={{ platform_domain: formData.platform_domain }}
                    perPage={100000} /*sort={{ field: 'aName', order: 'ASC' }}*/>
                    <SelectInputWithTip optionText="name" optionValue="id" 
                        title="The NON-SCHEDULE landing pages that will be cycled in accordance with the times and networks set in Pareva" />
                </ReferenceInput>
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => (formData.region === 'ZZZ') && 
                <ReferenceInput source="interstitial_landing_page" label="Interstitial Landing Page" className={classes.standardInput} 
                    reference="interstitial_landing_pages" perPage={100000} /*sort={{ field: 'aName', order: 'ASC' }}*/>
                    <SelectInputWithTip optionText="name" optionValue="id" title="If an interstitial is required, select it from the landing page list here" />
                </ReferenceInput>
            }
        </FormDataConsumer>

        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) && 
                <DestinationCaps {...rest} hidden_all={!formData.cap_active} />
            }
        </FormDataConsumer>
                 
        <SourceInput allSources={allSources} />        
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'offer')) && formData.source &&  
                !(allSources && !!allSources.length && !!allSources.filter(s => s.id === parseInt(formData.source, 10)).length) &&
                <ReferenceArrayInput {...rest} source="multi_sources_ids" label="Additional Sources" reference="sources" perPage={100000} filter={{ not_name: 'ALL' }} sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteArrayInputWithTip optionText="name" optionValue="id" 
                        title="You can select additional sources besides the main source (above) and this offer will be visible for that additional sources. If you want to select ALL sources, select ALL in the main source (above)" 
                    />
                </ReferenceArrayInput>        
            }
        </FormDataConsumer>
        
        <FormDataConsumer>
            { ({formData, ...rest }) =>
                <ReferenceInput {...rest} source="advertised_domain" label="Advertised Domain" reference="advertised_domains" filter={{ platform_domain: formData.platform_domain, source: formData.source }}
                    perPage={100000} sort={{ field: 'link', order: 'ASC' }}>
                    <SelectInputWithTip optionText="linkText" optionValue="id" 
                        title="The advertised domain that visitors will hit. If left blank, it will be set to the platform service domain." /> 
                </ReferenceInput>
            }
        </FormDataConsumer>
        <SelectInputWithTip source="payout_type" label="Payout Type" choices={payoutTypes} optionText="name" optionValue="name" validate={[required()]} 
            title="The type of campaign provided by the source/advertisers" />
        <SelectInputWithTip source="payout_currency" label="Payout Currency" formClassName={classes.inlineBlock} choices={payoutCurrencies} optionText="name" optionValue="name" 
            title="The currency code as expected by the CPA provider." />
        <FormDataConsumer formClassName={classes.inlineBlock}>
            { ({formData, ...rest}) => ((!formData.purpose || (formData.purpose === 'offer')) || (['CPC', 'CPM', 'CPL'].indexOf(formData.payout_type) >= 0)) &&
	        <NumberInputWithTip source="payout" label="Payout" className={classes.standardInput} title="What we pay for a CPA acquisition or bill. It is a currency value." />
            }
        </FormDataConsumer>
        <FormDataConsumer formClassName={classes.inlineBlock}>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') &&
	        <SelectInput source="params_mode" label="Params Mode" className={classes.standardInput} choices={paramsModes} optionText="name" optionValue="id" validate={[required()]} />
            }
        </FormDataConsumer>
        {/*TODO CPA PARAMS*/}
        <FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') && formData.params_mode && 
                <CPAParams {...rest} mode={formData.params_mode} region={formData.region} source={formData.source} />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') &&
                <SelectInputWithTip source="cpa_type" label="CPA Type" className={classes.standardInput} choices={cpaTypes} optionText="name" optionValue="name" 
                    title="When a notification is sent, either on subscription or on successful billing. This also affects logging in BI, so it must be set (not assumed)" />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') &&
                <SelectInputWithTip source="cpa_special_rules" label="CPA Special Rules" className={classes.standardInput} choices={cpaSpecialRules} optionText="name" optionValue="name" validate={[required()]} 
                    title="Currently only Sonic supported. Causes &quot;billing&quot; or &quot;subscription&quot; above to be appended to the type above." />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') &&
                <ReferenceInput source="cpa_payouts_only_for_network" label="CPA Payout Only For" className={classes.standardInput} reference="mobile_networks" perPage={100000} sort={{ field: 'aNetworkCode', order: 'ASC' }}>
                    <SelectInputWithTip optionText="name" optionValue="id" validate={[required()]} 
                        title="Select which network should be the only one for which CPA notifications will be sent for conversions on this campaign. Or something." />
                </ReferenceInput>
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'RevShare') &&
                <NumberInputWithTip source="revshare_payout_pc" label="RevShare Payout Percentage" className={classes.standardInput} 
                    title="This is the percentage of the payout from the aggregator which will go to the rev share partner for the given tariff class" />
            }
        </FormDataConsumer>
        {/*TODO - Display Priv. Level = 20, Edit Priv. Level = 20 */}
        {/*<FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') &&
                <TextInputWithTip source="skip_notifications" label="Skip notifications" multiline 
                    title="Use a fraction value to express how many notifications should be skipped: network,x/y" />
            }
        </FormDataConsumer>*/}
        {/*TODO - Display Priv. Level = 20, Edit Priv. Level = 20 */}
        {/*<FormDataConsumer>
            { ({formData, ...rest}) => (formData.payout_type === 'CPA') &&
                <TextInputWithTip source="cpa_network_payouts" label="CPA Network Payouts" multiline
                    title="In the format (one per line): network_code,4.00" />
            }
        </FormDataConsumer>*/}
        <FormDataConsumer>
            { ({formData, ...rest}) => (['CPA', 'CPC'].indexOf(formData.payout_type) >= 0) &&
                <TextInputWithTip source="alternative_callback_code" label="Alternative Callback Code" className={classes.standardInput} 
                    title="These are provided by Alex S for those sources which have different callback URLs for certain campaign types - please ask him if one should be used" />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest}) => (['CPA', 'CPC'].indexOf(formData.payout_type) >= 0) &&
                <TextInputWithTip source="partner_campaign_id" label="Partner Campaign ID" className={classes.standardInput} 
                    title="What a CPC partner calls this campaign so that we can match it when using their API" />
            }
        </FormDataConsumer>
        <DateTimeInput source="created_at" disabled label="Created At" className={classes.hidden} />
        <ReferenceInput source="created_by" disabled label="Created By" reference="users" className={classes.hidden}>
            <SelectInput optionText="fullname" optionValue="id" /> 
        </ReferenceInput>
        {/*<SelectInputWithTip source="service_type" label="Service Type" choices={serviceTypes} optionText="name" optionValue="name"
            title="These are a list of secondary settings which should cause adjustments in the URL." />*/}
        <BooleanInputWithTip source="live" label="Live" title="Controls whether Pareva shows a site for the given CID" />
        <FormDataConsumer>
            { ({formData, ...rest}) => !formData.live &&
                <TextInputWithTip source="redirect_url" label="Redirect URL (when not live)" className={classes.standardInput} 
                    title="If the campaign is not live hits will be redirected to this URL" />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'offer')) &&
                <SelectInput source="flow" label="Flow" className={classes.standardInput} choices={flows} optionText="name" optionValue="name" />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'offer')) &&
                <SelectInput source="offer_type" label="Offer Type" className={classes.standardInput} choices={offerTypes} optionText="name" optionValue="name" />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) &&
                <BooleanInput source="evina" label="Evina" className={classes.standardInput} />
            }
        </FormDataConsumer>
{/*        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) &&
                <SelectInput source="evina_type" label="Type of Evina" className={classes.standardInput} 
                    choices={evinaTypes} optionText="name" optionValue="name" validate={[required()]} />
            }
        </FormDataConsumer>*/}
        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) &&
                <BooleanInput source="monitor_logic" label="Monitor logic" className={classes.standardInput} />
            }
        </FormDataConsumer>

        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'offer')) &&
                <BooleanInputWithTip source="archived" label="Archived in Self-Service" className={classes.standardInput} 
                    title="If this option is enabled, the current offer won't be visible to regular affiliates in Self-Service module. Only Red27Users will be able to see it in Self-service." />
            }
        </FormDataConsumer>

{/*BEGIN OF ADVANCED AREA*/}
        <BooleanInputWithTip source="show_all" label="SHOW ADVANCED" title="SHOW ADVANCED SETTINGS" />

        <FormDataConsumer>
            { ({formData, ...rest }) => 
                <SelectInputWithTip source="type" label="Type" choices={campaignTypes} optionText="name" optionValue="name" 
                    className={classnames({ [classes.standardInput]: formData.show_all, [classes.hidden]: !formData.show_all })} title="The campaign's type" tipHidden={!formData.show_all} />
            }
        </FormDataConsumer>

        <FormDataConsumer>
            { ({formData, ...rest }) => (!formData.purpose || (formData.purpose === 'campaign')) &&
                <TextInputWithTip source="platform_aunique" label="Platform aUnique" disabled 
                    className={classnames({ [classes.standardInput]: formData.show_all, [classes.hidden]: !formData.show_all })} 
                    title="The ID as used in Pareva. If left unset, a plausible one will be generated." tipHidden={!formData.show_all} />
            }
        </FormDataConsumer>

        <FormDataConsumer>
            { ({formData, ...rest }) => 
                <BooleanInputWithTip source="disable_daytime_3g_flow" label="Disable Daytime 3G Flow" 
                    className={classnames({ [classes.standardInput]: formData.show_all, [classes.hidden]: !formData.show_all })} 
                    title="Enable this to prevent the 3G flow being used for this campaign during the club's daytime hours (as set by Madan)" tipHidden={!formData.show_all} />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { ({formData, ...rest }) => 
                <BooleanInputWithTip source="disable_initial_redirect" label="Disable Initial Redirect" 
                className={classnames({ [classes.standardInput]: formData.show_all, [classes.hidden]: !formData.show_all })} 
                title="Ticking this box disables BYB-style initial redirect and subsequent backlinking" tipHidden={!formData.show_all} />
            }
        </FormDataConsumer>

        <FormDataConsumer>
            { ({formData, ...rest }) => 
                <TextInputWithTip source="content_deeplink" label="Content Deeplink" 
                    className={classnames({ [classes.standardInput]: formData.show_all, [classes.hidden]: !formData.show_all })} 
                    title="Content page code for Melodi to give a deeplinking effect. Find the contentId in the URL of the page you want to link to in the Melodi content site and enter it here" 
                    tipHidden={!formData.show_all} />
            }
        </FormDataConsumer>

        <FormDataConsumer>
            { ({formData, ...rest }) => 
                <TextInput source="template_id" label="Template ID" 
                    className={classnames({ [classes.standardInput]: formData.show_all, [classes.hidden]: !formData.show_all })} 
                />
            }
        </FormDataConsumer>        
{/*END OF ADVANCED AREA*/}
    </SimpleForm>
    );
};

const CampaignTitle = ({ record }) => {
    return <span>Campaign/Offer {record ? `"${record.name}"` : ''}</span>;
};

const CampaignSaveConfirmDialog = forwardRef(({parentSaveConfirm, ...props}, ref) => {
    const [isOpen, setOpen] = useState(false);
    
    const handleClose = () => {
        setOpen(false);
    }

    const handleSaveConfirm = () => {
        setOpen(false);
        
        if (parentSaveConfirm) {
            parentSaveConfirm();
        }
    }

    useImperativeHandle(ref, () => ({
       setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        }
    }));
    
    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="campaign-save-confirm-dialog-title"
            >
                <DialogTitle id="campaign-save-confirm-dialog-title">{"Campaign Save Confirm"}</DialogTitle>
                <DialogContent>
                    <div>
                        You are saving default landing page(s), this may not perform. Are you sure you want to proceed?
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        No
                    </Button>
                    <Button onClick={handleSaveConfirm} variant="contained" color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});
    
const CampaignSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const form = useForm();
    const formState = useFormState();
    const campSaveConfirmRef = useRef(null);
    const dataProvider = useDataProvider();
    
    const handleSaveConfirm = () => {
        if (!formState.valid) {
            handleSubmitWithRedirect(redirect);
            return;
        }
        if (!formState.values) {
            return;
        }

        let platform_aunique = formState.values['platform_aunique'];
        // if not a campaign - skip CAP Rotator history logic
        if (!(platform_aunique && !!platform_aunique.length)) {
            handleSubmitWithRedirect(redirect);
            return;            
        }
        let camp_type = formState.values['type'];
        let platform_domain = formState.values['platform_domain'];
        let sourceCap = formState.values['sourceCap'];
        let cap_active = formState.values['cap_active'];
        let destCaps = formState.values['destinationCaps'];
        // if no data for CAP Rotator - skip CAP Rotator history logic
        if (!sourceCap && !(destCaps && !!destCaps.length)) {
            handleSubmitWithRedirect(redirect);
            return;
        }
        
        let platform_domain_name = null;
        let clubs = [];
        let landers = [];
        let schedules = [];
        
        dispatch(fetchStart());
        dataProvider.getMany('services', { ids: [platform_domain] })
            .then(({ data }) => {
                dispatch(fetchEnd());
                
                if (data && !!data.length) {
                    platform_domain_name = data[0].name;
                }
                
                const clubIds = destCaps.map(d => d.destinationClubUnique);
                dispatch(fetchStart());
                return dataProvider.getMany('services_for_cap_rotator', { ids: clubIds });
            })
            .then(({ data }) => {
                dispatch(fetchEnd());
                
                if (data && !!data.length) {
                    clubs = data;
                }
                
                const landerIds = destCaps.map(d => d.destinationLandingPageUnique).filter(l => l);
                dispatch(fetchStart());
                if (landerIds && !!landerIds.length) {
                    return dataProvider.getMany('schedule_landing_pages', { ids: landerIds });
                } else {
                    return Promise.resolve([]);
                }
            })
            .then(({ data }) => {
                dispatch(fetchEnd());
                
                if (data && !!data.length) {
                    landers = data;
                }
                
                const scheduleIds = destCaps.map(d => d.destinationScheduleUnique).filter(s => s);
                dispatch(fetchStart());
                if (scheduleIds && !!scheduleIds.length) {
                    return dataProvider.getMany('schedules', { ids: scheduleIds });
                } else {
                    return Promise.resolve([]);
                }
            })
            .then(({ data }) => {
                dispatch(fetchEnd());
                
                if (data && !!data.length) {
                    schedules = data;
                }
                
                let history_obj = {};
                history_obj.service = { id: platform_domain, name: platform_domain_name };
                history_obj.sourceCap = sourceCap;
                history_obj.cap_active = cap_active;
                
                let destinationCaps = destCaps.map(d => {
                    let filtClub = clubs.filter(c => c.id === d.destinationClubUnique);
                    let club = { id: d.destinationClubUnique, name: null };
                    if (filtClub && !!filtClub.length) {
                        club.name = filtClub[0].name;
                    }
                    
                    let cap = d.destinationCap;
                    
                    let mode = null;
                    if (!(camp_type && !!camp_type.length && (camp_type === 'api'))) {
                        mode = d.destinationLandingPageMode;
                    }
                    
                    let lander = null;
                    if (!(camp_type && !!camp_type.length && (camp_type === 'api'))) {
                        let filtLander = landers.filter(l => l.id === d.destinationLandingPageUnique);
                        lander = { id: d.destinationLandingPageUnique, name: null };
                        if (filtLander && !!filtLander.length) {
                            lander.name = filtLander[0].name;
                        }
                    }

                    let schedule = null;
                    if (!(camp_type && !!camp_type.length && (camp_type === 'api'))) {
                        let filtSchedule = schedules.filter(s => s.id === d.destinationScheduleUnique);
                        schedule = { id: d.destinationScheduleUnique, name: null };
                        if (filtSchedule && !!filtSchedule.length) {
                            schedule.name = filtSchedule[0].name;
                        }
                    }
                    
                    let dcap = { club, cap, mode, lander, schedule };
                    return dcap;
                });
                history_obj.destinationCaps = destinationCaps;
                
                if (form) {
                    form.change('history_obj', JSON.stringify(history_obj));
                }
                handleSubmitWithRedirect(redirect);
            })
            .catch(e => {
                dispatch(fetchEnd());
                notify(e.message, 'warning');
            })
    };
    
    const handleClick = () => {
        if (!formState.valid) {
            handleSaveConfirm();
            return;
        }

        let camp_type = null;
        let destCaps = [];
        let schedule_landing_pages = null;
        if (formState.values) {
            camp_type = formState.values['type'];
            destCaps = formState.values['destinationCaps'];
            schedule_landing_pages = formState.values['schedule_landing_pages'];
        }
        let ids = [];
        if (schedule_landing_pages && !!schedule_landing_pages.length) {
            ids.push(schedule_landing_pages);
        }
        if (!(camp_type && !!camp_type.length && (camp_type === 'api')) && destCaps) {
            const landerIds = destCaps.map(d => d.destinationLandingPageUnique);
            if (landerIds && !!landerIds.length) {
                ids.push(...landerIds);
            }
        }
        if (ids && !!ids.length) {
            const strIds = ids.map(id => ('id=' + id)).join('&');
            fetch(BACKEND_URL + '/are_default_landing_pages?' + strIds, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            if (json && json.success && json.is_default) {
                                if (campSaveConfirmRef && campSaveConfirmRef.current) {
                                    campSaveConfirmRef.current.setOpenPublic(true);
                                }
                            } else {
                                handleSaveConfirm();
                            }
                        });
                    } else {
                        response.json().then(e => {
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => { 
                    notify(e.message, 'warning'); 
                });
        } else {
            handleSaveConfirm();
        }
    };
    
    return (
        <>
            <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
            <CampaignSaveConfirmDialog ref={campSaveConfirmRef} parentSaveConfirm={handleSaveConfirm} />
        </>
    );
};

const CampaignCreateToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <CampaignSaveButton redirect="edit" />
        </Toolbar>
    );
};

export const CampaignCreate = ({ permissions, ...props }) => (
    <Create title="Create New Campaign/Offer" aside={<CampaignTabs permissions={permissions} />} permissions={permissions} {...props}>
        <CampaignForm withId={false} redirect="edit" toolbar={<CampaignCreateToolbar />} initialValues={{ platform: 'Pareva', live: true, evina: true, monitor_logic: true, archived: false }} />
    </Create>
);

const CampaignEditToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <CampaignSaveButton redirect="list" />
            <CampaignCloneButton inList={false} />
            <DeleteButton undoable={false} />
        </Toolbar>
    );
};

export const CampaignEdit = ({ permissions, ...props }) => (
    <Edit undoable={false} title={<CampaignTitle />} aside={<CampaignTabs permissions={permissions} />} permissions={permissions} {...props} className="sidePanelFix">
        <CampaignForm withId={true} toolbar={<CampaignEditToolbar />} />
    </Edit>
);

 
