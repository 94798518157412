import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import { parse } from 'query-string';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { useNotify, fetchStart, fetchEnd, required, 
         Title, TextInput, BooleanInput, SelectInput, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { BooleanInputWithTip } from './TooltipComponents';         
import { BACKEND_URL, scrollToRef, containsIgnoreCase /*, ukServiceTypes, webfactionApps, webfactionDomains*/ } from '../common/constants';
import { MyContext } from '../common/MyContext';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MuiLink from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const NameserversDialog = forwardRef((props, ref) => {
//    const notify = useNotify();
//    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [nameservers, setNameservers] = useState(null);

    const [formNameservers, setFormNameservers] = useState(null);
    
    const handleClose = () => {
        setOpen(false);
    }

/*    const handleNameservers = () => {
        formNameservers.submit();
    }
*/
    const handleCreateNameservers = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        console.log(values);
    }
    
    const NameserversToolbar = (nstbprops) => {
        const form = useForm();

        useEffect(() => {
            setFormNameservers(form);
        }, [form]);

        return null;
    };

    useEffect(() => {
        if (formNameservers && nameservers) {
            formNameservers.change('nameservers', nameservers);
        }
    }, [formNameservers, nameservers]);
        
    useImperativeHandle(ref, () => ({
        setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },

        setNameserversPublic: (pNameservers) => {
            setNameservers(pNameservers.join("\r\n"));
        }
    }));

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="nameservers-dialog-title"
            >
                <DialogTitle id="nameservers-dialog-title">{"Nameservers list"}</DialogTitle>
                <DialogContent>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleCreateNameservers}
                        toolbar={<NameserversToolbar />}
                    >
                        <TextInput 
                            source="nameservers" 
                            label="Nameservers" 
                            multiline
                            rows={10}
                        />

                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default ({ location, ...props}) => {
    const myContext = useContext(MyContext);
    useEffect(() => {
        if (myContext) {
            myContext.setShowIndicator(true);
        }
        
        return function cleanup() {
            if (myContext) {
                myContext.setShowIndicator(false);
            }
        };
    }, [myContext]);
    const notify = useNotify();
    const dispatch = useDispatch();
    const { hz: hzParam } = parse(location.search);
    
    const [forceRefreshDomains, setForceRefreshDomains] = useState(true);
    const [forceRefreshRecords, setForceRefreshRecords] = useState(true);
        
    const [selectedTab, setSelectedTab] = useState(false);
    const [visibleTabs, setVisibleTabs] = useState([]);

    const [subdomainTargetHosts, setSubdomainTargetHosts] = useState([]);
    const [platformServicesOptions, setPlatformServicesOptions] = useState([]);
    const [advertisedSubdomains, setAdvertisedSubdomains] = useState([]);
    const [hostedZones, setHostedZones] = useState([]);
    const [hostedZonesOptions, setHostedZonesOptions] = useState([]);
    const [hzRecords, setHzRecords] = useState([]);
    const [output, setOutput] = useState(null);

    const [formFilterHZ, setFormFilterHZ] = useState(null);
    const [formFilterASD, setFormFilterASD] = useState(null);
    const [filterHostedZones, setFilterHostedZones] = useState([]);
    const [filterAdvSubDoms, setFilterAdvSubDoms] = useState([]);
        
    const topRef = useRef(null);
    
    const handleTabChange = (event, value) => {
        setSelectedTab(value);
    };

    useEffect(() => {
            if (!forceRefreshDomains) {
                return;
            }
            
            let url = BACKEND_URL + '/route53_domains';
            
            dispatch(fetchStart());
            fetch(url, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            setForceRefreshDomains(false);
                            //setOldTab(selectedTab);
                            setSelectedTab(false);
                            if (json) {
                                setSubdomainTargetHosts(json.subdomain_target_hosts);
                                setPlatformServicesOptions(json.platorm_services_options);
                                setAdvertisedSubdomains(json.advertised_subdomains);
                                setHostedZones(json.hosted_zones);
                                setHostedZonesOptions(json.hosted_zones_options);

                                if (formFilterHZ) {
                                    formFilterHZ.change('searchVal', null);
                                }
                                if (formFilterASD) {
                                    formFilterASD.change('searchVal', null);
                                }
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            setForceRefreshDomains(false);
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    setForceRefreshDomains(false);
                    notify(e.message, 'warning'); 
                });
    
    }, [forceRefreshDomains, formFilterHZ, formFilterASD, dispatch, notify]);

    const nameserversDialog = useRef(null);
    
    const AWSEditMenu = ({hz, ...aemprops}) => {
        const [anchorEl, setAnchorEl] = useState(null);
        
        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };

        const removeEndDot = (ns) => {
            if (ns && ns.endsWith('.')) {
                return ns.slice(0, -1);
            }
            return ns;
        };
        
        const showNameservers = (records) => {
            const nsRecords = records.filter(hz => hz.Type === 'NS');
            let nameservers = [].concat.apply([], nsRecords.map(nsr => nsr.ResourceRecords));
            nameservers = nameservers.map(removeEndDot);
                
            if (nameserversDialog && nameserversDialog.current) {
                nameserversDialog.current.setNameserversPublic(nameservers);
                nameserversDialog.current.setOpenPublic(true); 
            }
        };
        
        const handleShowNameservers = (hzId) => {
            if (hzId && !!hzId.length) {
                let url = BACKEND_URL + '/route53_hz_records?hz=' + hzId;

                dispatch(fetchStart());
                fetch(url, { 
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                        } 
                    })
                    .then(response => {
                        if (response.ok) {
                            response.json().then(json => {
                                dispatch(fetchEnd());
                                if (json && json.hz_records) {
                                    showNameservers(json.hz_records);                                
                                } else {
                                    showNameservers([]);
                                }
                            });
                        } else {
                            response.json().then(e => {
                                dispatch(fetchEnd());
                                notify(e.message, 'warning');
                            });
                        }
                    })
                    .catch(e => {
                        dispatch(fetchEnd()); 
                        notify(e.message, 'warning'); 
                    });
            } else {
                showNameservers([]);
            }
        };
                                                    
        return (
            <div>
                <Button color="primary" variant="outlined" aria-controls="aws-edit-menu" aria-haspopup="true" onClick={handleClick}>
                    Edit
                </Button>
                <Menu
                    id="aws-edit-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <Button {...aemprops} variant="contained" color="primary" component={Link}
                            to={{ pathname: `/aws_route53_domains`, search: `hz=${hz.Id}`}}>DNS records</Button>                    
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Button {...aemprops} variant="contained" color="primary" onClick={() => handleShowNameservers(hz.Id)}>Nameservers List</Button>                    
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <a {...aemprops} target="_blank" rel="noopener noreferrer" title="123-Reg management admin (new window)" 
                            href={`https://www.123-reg.co.uk/secure/cpanel/domain/management/${hz.NameReadable}`}>[Management]</a>                    
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <a {...aemprops} target="_blank" rel="noopener noreferrer" title="123-Reg contacts admin (new window)" 
                            href={`https://www.123-reg.co.uk/cp/domain/contact/${hz.NameReadable}`}>[Contacts]</a>                    
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <a {...aemprops} target="_blank" rel="noopener noreferrer" title="123-Reg nameserver admin (new window)" 
                            href={`https://www.123-reg.co.uk/cp/domain/nameservers/${hz.NameReadable}`}>[Nameservers]</a>                    
                    </MenuItem>
                </Menu>
            </div>        
        );
    };
    
    useEffect(() => {
            let url = BACKEND_URL + '/route53_hz_records';
            if (hzParam && !!hzParam.length) {
                url += '?hz=' + hzParam;
            } else {
                setHzRecords([]);
                scrollToRef(topRef);
                return;
            }
            
            dispatch(fetchStart());
            fetch(url, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json) {
                                setHzRecords(json.hz_records);                                
                                scrollToRef(topRef);
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
    
    }, [forceRefreshRecords, hzParam, dispatch, notify]);
    
    const filterHostedZoneRecords = (records) => {
        if (!(records && !!records.length)) {
            return [];
        }
        
        return records.filter(hz => 
            (hz.Type === 'A' || hz.Type === 'CNAME') && 
            !(hz.ResourceRecords && !!hz.ResourceRecords.length && (hz.ResourceRecords[0].indexOf('acm-validations.aws') >= 0)) );
    };
    
    useEffect(() => {
        let result;
        if (output) {
            result = [0, 1, 2, 3, 4];
        } else {
            result = [1, 2, 3, 4];
        }
        
        setVisibleTabs(result);

        if (result && !!result.length) {
            /*if (oldTab && result.includes(oldTab)) {
                setSelectedTab(oldTab);
            } else {*/
                const firstTab = result[0];
                setSelectedTab(firstTab);
           /* } */
        } else {
            setSelectedTab(false);
        }
    }, [subdomainTargetHosts, platformServicesOptions, advertisedSubdomains, hostedZones, hostedZonesOptions, output]);

    const handleAddApex = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.domainname && !!values.domainname.length) {
            const obj = { 
                create_hz: values.create_hz, 
                domainname: values.domainname,
            };
            
            dispatch(fetchStart());
            fetch(BACKEND_URL + '/route53_create_apex_domain', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json) {
                                setForceRefreshDomains(true);
                                if (json.output) {
                                    setOutput(json.output);
                                } else {
                                    setOutput(null);
                                }
                                
                                if (json.errorMessages && !!json.errorMessages.length) {
                                     json.errorMessages.forEach(e => notify(e));
                                }
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        }
        
    }
    
    const AddApexToolbar = (aatbprops) => {
        return (
            <Toolbar {...aatbprops}>
                <SaveButton variant="contained" color="primary" label="Add" />
            </Toolbar>
        );
    };

    const handleAddSubdomain = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.subdomain && !!values.subdomain.length &&
            values.hosted_zone && !!values.hosted_zone.length &&
            values.target_host && !!values.target_host.length) {
            const obj = { 
                subdomain: values.subdomain, 
                hosted_zone: values.hosted_zone,
                target_host: values.target_host,
                skip_domains: values.skip_domains,
            };
            
            dispatch(fetchStart());
            fetch(BACKEND_URL + '/route53_create_subdomain', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json) {
                                setForceRefreshDomains(true);
                                setForceRefreshRecords(!forceRefreshRecords);
                                if (json.output) {
                                    setOutput(json.output);
                                } else {
                                    setOutput(null);
                                }
                                
                                if (json.errorMessages && !!json.errorMessages.length) {
                                     json.errorMessages.forEach(e => notify(e));
                                }
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
        }

    }
    
    const AddSubdomainToolbar = (astbprops) => {
        return (
            <Toolbar {...astbprops}>
                <SaveButton variant="contained" color="primary" label="Add" />
            </Toolbar>
        );
    };
    
    //const MyLabel = ({basePath, label, ...lblProps}) => <span dangerouslySetInnerHTML={{ __html: label }} />;

     
    const handleSubmitFilterHZ = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        const searchHZ = values? values.searchVal : null;
        
        if (hostedZones && !!hostedZones.length) {
            const newFilterHostedZones = hostedZones.filter(hz => {
                if (containsIgnoreCase(hz.NameReadable, searchHZ)) {
                    return true;
                }
                if (containsIgnoreCase(hz.Id, searchHZ)) {
                    return true;
                }
                return containsIgnoreCase((hz.Config? hz.Config.Comment : null), searchHZ); 
            });
            setFilterHostedZones(newFilterHostedZones);
        } else {
            setFilterHostedZones([]);
        }
    };

    const FilterHZToolbar = (fhztbprops) => {
        const form = useForm();

        useEffect(() => {
            setFormFilterHZ(form);
        }, [form]);

        return null;
    };
    
    const handleChangeFilterHostedZones = () => {
        if (formFilterHZ) {
            formFilterHZ.submit();
        }
    };
    
    useEffect(() => {
        if (formFilterHZ) {
            formFilterHZ.submit();
        }
    }, [formFilterHZ]);


    const handleSubmitFilterASD = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        const searchASD = values? values.searchVal : null;

        if (advertisedSubdomains && !!advertisedSubdomains.length) {
            const newFilterAdvSubDoms = advertisedSubdomains.filter(asd => containsIgnoreCase(asd, searchASD));
            setFilterAdvSubDoms(newFilterAdvSubDoms);
        } else {
            setFilterAdvSubDoms([]);
        }
    };

    const FilterASDToolbar = (fasdtbprops) => {
        const form = useForm();

        useEffect(() => {
            setFormFilterASD(form);
        }, [form]);

        return null;
    };
    
    const handleChangeFilterAdvSubDoms = () => {
        if (formFilterASD) {
            formFilterASD.submit();
        }
    };

    useEffect(() => {
        if (formFilterASD) {
            formFilterASD.submit();
        }
    }, [formFilterASD]);

    
    return (
        <Card>
            <Title title={`Domains Administration`} />
            <CardContent ref={topRef}>
                <div>
                    <MuiTabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                        { visibleTabs.includes(0) && <MuiTab key={0} value={0} label="Output" /> }
                        { visibleTabs.includes(1) && <MuiTab key={1} value={1} label="Our Domains" /> }
                        { visibleTabs.includes(2) && <MuiTab key={2} value={2} label="Currently Advertised Subdomains" /> }
                        { visibleTabs.includes(3) && <MuiTab key={3} value={3} label="Add a New Apex Domain (example.com)" /> }
                        { visibleTabs.includes(4) && <MuiTab key={4} value={4} label="Add or Update a Subdomain (subbie.example.com)" /> }
                    </MuiTabs>
                    <Divider />
                    { (selectedTab === 0) && visibleTabs.includes(0) && 
                        <div>
                            <h3>Output</h3>
                            
                            { output.create_hz &&
                            <div>
                                <h4>New Hosted Zone was created</h4>
                                <p><pre>{ JSON.stringify(output.response_to_hz_creation, null, 4) }</pre></p>

                                <h3>Work for you! (Or ask me to do it and e-mail me all the above)</h3>
                                <ul>
                                    <li>Put the nameservers you see above into <a target="_blank" rel="noopener noreferrer" href={`https://www.123-reg.co.uk/cp/domain/nameservers/${output.domain_name}`}>123-reg's nameserver page</a>.</li>
                                    <li>We will have received an e-mail from this page to remind us to set up a security certificate so we can support https:// on this domain</li>
                                    <li>Add the required subdomains (we normally run several services on a domain, on different subdomains)</li>
                                    <li>Add these subdomains as Advertised Domains in BI</li>
                                </ul>
                            </div>
                            }
                            
                            { output.pareva_domain_aliases_updated &&
                            <div>
                                <h4>Pareva domain aliases were updated</h4>
                                <p><pre>{ JSON.stringify(output.response_to_updating_domain_aliases, null, 4) }</pre></p>
                                <p><pre>{ JSON.stringify(output.alias_url_list, null, 4) }</pre></p>
                            </div>
                            }
                            
                            { output.response_to_dns_record_creation &&
                            <div>
                                <h4>DNS records were updated</h4>
                                <p><pre>{ JSON.stringify(output.response_to_dns_record_creation, null, 4) }</pre></p>
                            </div>
                            }
                            
                            { output.add_domain_to_webfaction &&
                            <div>
                                <h4>Domain was added to Webfaction</h4>
                                <p><pre>{ JSON.stringify(output.response_to_adding_domain_to_webfaction, null, 4) }</pre></p>
                            </div>
                            }
                            
                            { output.add_email_to_webfaction &&
                            <div>
                                <h4>Email was added to Webfaction</h4>
                                <p><pre>{ JSON.stringify(output.response_to_adding_email_to_webfaction, null, 4) }</pre></p>
                            </div>
                            }
                            
                            { output.webfaction_website_updated &&
                                <h4>Webfaction website was updated</h4>
                            }
                            
                            { output.add_subdomain &&
                            <div>
                                <h4>Adding a subdomain</h4>
                                <p><pre>{ JSON.stringify(output.response_to_dns_record_creation, null, 4) }</pre></p>
                            </div>
                            }
                            
                            { output.pareva_domain_id && !!output.pareva_domain_id.length &&
                            <div>
                                <h4>New Pareva 2 Domain created</h4>
                                <p>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component={MuiLink} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`http://admin2.pareva.umelimited.com/#/domains/${output.pareva_domain_id}`}>
                                        Go to Pareva 2 Domain
                                    </Button>
                                </p>
                            </div>
                            }
                            
                            { output.adv_domain_id &&
                            <div>
                                <h4>New Advertised Domain created</h4>
                                <p>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        component={Link} 
                                        to={{ pathname: `/advertised_domains_menu/${output.adv_domain_id}` }}>
                                        Go to Advertised Domain
                                    </Button>
                                </p>
                            </div>
                            }
                        </div>
                    }

                    { (selectedTab === 1) && visibleTabs.includes(1) && 
                        <div>
                            { hzRecords && !!hzRecords.length &&
                            <>
                                <h3>Records of { hzRecords[0].Name }</h3>
                                <div style={{ overflowX: 'auto' }}>
                                    <Table aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>TTL</TableCell>
                                                <TableCell>Records</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        { filterHostedZoneRecords(hzRecords).map((hz, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{hz.Name}</TableCell>
                                                <TableCell>{hz.Type}</TableCell>
                                                <TableCell>{hz.TTL}</TableCell>
                                                <TableCell>
                                                    <p>{hz.AliasTargetDns}</p>
                                                    { hz.ResourceRecords && !!hz.ResourceRecords.length &&
                                                      hz.ResourceRecords.map((rr, rrindex) => (
                                                        <p key={rrindex}>{rr}</p>
                                                    )) }
                                                </TableCell>
                                            </TableRow>
                                        )) }
                                        </TableBody>
                                    </Table>
                                </div>
                            </>    
                            }
                            
                            <h3>Our Domains</h3>
                            { hostedZones && !!hostedZones.length && 
                                <SimpleForm
                                    resource="regions"
                                    onSubmit={handleSubmitFilterHZ}
                                    toolbar={<FilterHZToolbar />}
                                >
                                    <TextInput source="searchVal" label="Search" onChange={handleChangeFilterHostedZones} />
                                </SimpleForm>
                            }
                            
                            { hostedZones && !!hostedZones.length && 
                                <div style={{ overflowX: 'auto' }}>
                                    <Table aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Comment</TableCell>
                                                <TableCell>Tools</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        { filterHostedZones && filterHostedZones.map((hz, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Link to={{ pathname: `/aws_route53_domains`, search: `hz=${hz.Id}`}}>{hz.NameReadable}</Link></TableCell>
                                                <TableCell>{hz.Id}</TableCell>
                                                <TableCell>{(hz && hz.Config)? hz.Config.Comment : ''}</TableCell>
                                                <TableCell>
                                                    <AWSEditMenu hz={hz} />
                                                </TableCell>
                                            </TableRow>
                                        )) }
                                        </TableBody>
                                    </Table>
                                </div>
                            }
                        </div>
                    }

                    { (selectedTab === 2) && visibleTabs.includes(2) && 
                        <div>
                            <h3>Currently Advertised Subdomains</h3>

                            { advertisedSubdomains && !!advertisedSubdomains.length && 
                                <SimpleForm
                                    resource="regions"
                                    onSubmit={handleSubmitFilterASD}
                                    toolbar={<FilterASDToolbar />}
                                >
                                    <TextInput source="searchVal" label="Search" onChange={handleChangeFilterAdvSubDoms} />
                                </SimpleForm>
                            }                            
                            
                            { advertisedSubdomains && !!advertisedSubdomains.length && 
                                <div style={{ overflowX: 'auto' }}>
                                    <Table aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Subdomain</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        { filterAdvSubDoms && filterAdvSubDoms.map((asd, index) => (
                                            <TableRow key={index}>
                                                <TableCell><a target="_blank" rel="noopener noreferrer" href={`http://${asd}`}>{asd}</a></TableCell>
                                            </TableRow>
                                        )) }
                                        </TableBody>
                                    </Table>
                                </div>
                            }                            
                        </div>
                    }

                    { (selectedTab === 3) && visibleTabs.includes(3) && 
                        <div>
                            <h3>Add a new hosted zone to Route 53</h3>
                            <p>You'll want to do this for a new UK domain, for example. Please put the nameservers that are returned
                            straight into 123-reg's control panel for this domain. Then the Amazon DNS records will have effect.</p>
                            <p>You'll be charged $0.50 immediately, and then every month that this domain is live on Amazon
                            even if you don't use it. Let me know and I'll delete the oldest periodically.</p>
                            <p>At this stage, I'll also immediately create an A record which points the domain at the UK
                            redirect service I created.</p>
                                
                            <SimpleForm
                                resource="regions"
                                onSubmit={handleAddApex}
                                toolbar={<AddApexToolbar />}
                            >
                                <BooleanInput source="create_hz" label="Create an HZ" defaultValue={true} />
                                <TextInput source="domainname" label="With this name (no subdomain)" helperText="example.com" validate={[required()]} />
                                
                                {/*<SelectInput source="hosted_zone" label="OR update this one" choices={hostedZonesOptions} optionText="name" optionValue="id" />
                                <SelectInput source="uk_service_type" label="UK Service Type" choices={ukServiceTypes} optionText="name" optionValue="id" />
                                <BooleanInput source="add_domain_to_webfaction" label="Add domains to Webfaction disposable (UK only!)" />
                                <BooleanInput source="update_webfaction_website" label="Update the Webfaction disposable site redirects" />
                                <SelectInput source="webfaction_app" label="Webfaction App" choices={webfactionApps} optionText="name" optionValue="id" />
                                <BooleanInput source="update_platform_domain_aliases" label="Update platform domain aliases" defaultValue={true} />
                                <MyLabel label="Update Webfaction disposable domains website redirect (<strong><em>Definitely</em> UK only!</strong>)" />
                                <SelectInput source="domain_unique" label="Domains" choices={webfactionDomains} 
                                    optionText="name" optionValue="id" />
                                <MyLabel label="Add the appropriate domain aliases to Pareva (<strong>UK only!</strong>)" />*/}
                            </SimpleForm>
                        </div>
                    }

                    { (selectedTab === 4) && visibleTabs.includes(4) && 
                        <div>
                            <h3>Add or update new Record Set pointed at one of our platforms or server instances</h3>
                            <p>Use this to add subdomains for services. <span style={{ color: 'red' }}>Changes you make here will overwrite the current records for
                            the subdomain. Check that it isn't advertised!</span>. <strong>Please note</strong> this tool can no longer add aliases to services:
                            please put new subdomains for existing services using: &nbsp;
                            <a target="_blank" rel="noopener noreferrer" href="http://admin2.pareva.umelimited.com">Pareva Admin 2</a></p>
                            
                            <SimpleForm
                                resource="regions"
                                onSubmit={handleAddSubdomain}
                                toolbar={<AddSubdomainToolbar />}
                            >
                                <>
                                <TextInput source="subdomain" label="Subdomain" validate={[required()]} />  
                                <SelectInput source="hosted_zone" label="Hosted zone" choices={hostedZonesOptions} optionText="name" optionValue="mixed_id" validate={[required()]} />
                                <SelectInput source="target_host" label="Target host" choices={subdomainTargetHosts} optionText="label" optionValue="target_host" validate={[required()]} />
                                {/*<SelectInput source="platform_service" label="Platform Service" choices={platformServicesOptions} optionText="name" optionValue="domain" />*/}
                                <BooleanInputWithTip source="skip_domains" label="Skip Pareva Domain and Advertised Domain"
                                    title="If this option is checked, the creation of new Pareva Domain and Advertised Domain will be skipped. You'll have to configure them manually. (This is usually used when creating an ALIAS)" />
                                </>
                            </SimpleForm>
                        </div>
                    }

                    <NameserversDialog ref={nameserversDialog} />
                </div>
            </CardContent>
        </Card>
    );    
};
