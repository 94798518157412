import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { TextInput, SelectInput, SimpleForm, required, useNotify } from 'react-admin';
import { createForm } from 'final-form';
import { useForm } from 'react-final-form';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTextField from '@material-ui/core/TextField';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BACKEND_URL, isInt } from '../common/constants';

const AddParamDialog = forwardRef(({parentAddParam, ...props}, ref) => {
    const [isOpen, setOpen] = useState(false);

    const [params, setParams] = useState([]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleAddParam = () => {
        formInstance.submit();
    }
    
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        if (values && values.availableParam && values.availableParam.length) {
            if (parentAddParam) {
                parentAddParam(values.availableParam);
            }
        }
        setOpen(false);
    };

    const formInstance = createForm({ onSubmit: handleSubmit });

    useImperativeHandle(ref, () => ({
        setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },

        setParamsPublic: (pParams) => {
            setParams(pParams);
        }
    }));

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="add-param-dialog-title"
            >
                <DialogTitle id="add-param-dialog-title">{"Add New CPA Parameter"}</DialogTitle>
                <DialogContent>
                    <SimpleForm 
                        form={formInstance}
                        resource="regions"
                        toolbar={null}
                    >
                        <SelectInput 
                            source="availableParam" 
                            label="Network" 
                            choices={params} 
                            optionText="aNetwork" 
                            optionValue="aNetwork"  
                            validate={required()} 
                        />
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddParam} variant="contained" color="primary" autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default ({mode, region, source, ...props}) => {
    const [params, setParams] = useState([]);

    /*const parseCSV = (csv) => {
        if (!(csv && csv.length)) {
            return;
        }
        let lines = csv.split("\r\n");
        let result = [];
        for (let i = 0; i < lines.length; i++) {
            let l = lines[i];
            let cells = l.split(',');
            let obj = {};
            obj.id = null;
            obj.network = cells[0];
            obj.payout = cells[1];
            obj.skips = cells[2];
            obj.enabled = cells[3] === '1';
            result.push(obj);  
        }
        setParams(result);
    };*/

    const { record } = props;

    /*useEffect(() => {
        parseCSV(record.custom_params);
    }, [record]);*/

    const [defaultParams, setDefaultParams] = useState([]);

    const notify = useNotify();

    useEffect(() => {
        let isCancelled = false;
        fetch(BACKEND_URL + '/campaign_params?aunique=' + record.platform_aunique + '&mode=DEFAULT&region=' + region + '&source=' + source /*'&with_defaults=1'*/, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                if (!isCancelled) {
                    setDefaultParams(json);
                } 
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
            
        return function cleanup() { 
            isCancelled = true;
        };
    }, [region, source, record, notify]);

    useEffect(() => {
        let isCancelled = false;
        fetch(BACKEND_URL + '/campaign_params?aunique=' + record.platform_aunique + '&mode=' + mode + '&region=' + region + '&source=' + source, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                if (isCancelled) {
                    return;
                }
                if (json && json.length) {
                    let pars = json.map(par => ({
                        id: par.id,
                        network: par.aNetwork, 
                        payout: par.aPayoutValue, 
                        skips: par.aSkipNotifications, 
                        enabled: (par.aEnabled === 1) 
                    }));
                    setParams(pars); 
                } else {
                    if (mode === 'BLENDED') {
                        let obj = {
                            id: null,
                            network: 'ALL',
                            payout: '',
                            skips: '',
                            enabled: false
                        };
                        setParams([obj]);
                    } else {
                        setParams([]);
                    }
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
            
        return function cleanup() { 
            isCancelled = true;
        };
    }, [mode, region, source, record, notify]);

    const [availableParams, setAvailableParams] = useState([]);

    useEffect(() => {
        let ap = defaultParams.filter(dp => { let filtered = params.filter(p => p['network'] === dp['aNetwork']); return !(filtered && filtered.length); });
        setAvailableParams(ap);
    }, [params, defaultParams]);

    const [hasAvailableParams, setHasAvailableParams] = useState(false);

    useEffect(() => {
        setHasAvailableParams(!!(availableParams && availableParams.length));
    }, [availableParams]);

    const mainForm = useForm();

    useEffect(() => {
        let text = params.map(par => par.network + ',' + par.payout + ',' + par.skips + ',' + (par.enabled? '1': '0') ).join("\r\n");
        mainForm.change('custom_params', text);
    }, [params, mainForm]);

    const handleChangeValue = (obj, name) => (event) => {
        obj[name] = event.target.value;
        setParams([...params]);
    };

    const handleChangeChecked = (obj, name) => (event) => {
        obj[name] = event.target.checked;
        setParams([...params]);
    };

    const handleDelete = (e, par) => {
        setParams(params.filter(p => !(p.network === par.network && p.id === par.id)));
    };

    const validatePayout = (par) => {
        return !(par.payout && par.payout.length) || isNaN(par.payout);
    };

    const validatePayoutMsg = (par) => {
        if (!(par.payout && par.payout.length)) {
            return 'Required';
        }
        if (isNaN(par.payout)) {
            return 'Incorrect Number';
        }
        return null;
    };

    const validateSkips = (par) => {
        if (!(par.skips && par.skips.length)) {
            return true;
        }
        if (par.skips.indexOf('/') < 0) {
            return true;
        }
        var arr = par.skips.trim().split('/');
        if (arr && (arr.length === 2)) {
            var num1 = arr[0].trim();
            var num2 = arr[1].trim();
            if (!isInt(num1) || !isInt(num2)) {
                return true;
            }            
        } else {
            return true;
        }
        return false;
    };

    const validateSkipsMsg = (par) => {
        if (!(par.skips && par.skips.length)) {
            return 'Required';
        }
        if (par.skips.indexOf('/') < 0) {
            return 'Incorrect Fraction';
        }
        var arr = par.skips.split('/');
        if (arr && (arr.length === 2)) {
            var num1 = arr[0].trim();
            var num2 = arr[1].trim();
            if (!isInt(num1) || !isInt(num2)) {
                return 'Incorrect Fraction';
            }
        } else {
            return 'Incorrect Fraction';
        }
        return null;
    };

    const addParamDialog = useRef(null);

    const handleAddParam = (e) => {
        addParamDialog.current.setParamsPublic(availableParams);
        addParamDialog.current.setOpenPublic(true);
    };

    const parentAddParam = (network) => {
        let pars = defaultParams.filter(dp => dp['aNetwork'] === network);
        if (pars && pars.length) {
            let par = pars[0];
            let obj = { 
                id: par.id,
                network: par.aNetwork, 
                payout: par.aPayoutValue,
                skips: par.aSkipNotifications,
                enabled: (par.aEnabled === 1)
            };
            setParams([...params, obj]);
        }
    };

    return (
        <div>
            <TextInput {...props} source="custom_params" label="CPA Params" multiline style={{ display: 'none' }} />
            <div style={{ overflowX: 'auto' }}>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            <TableCell>Network</TableCell>
                            <TableCell>Payout</TableCell>
                            <TableCell>Skips</TableCell>
                            { (mode === 'CUSTOM') && <TableCell>Remove</TableCell> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { params.map(p => (
                        <TableRow key={`${p.network}_${p.id}`}>
                            <TableCell>
                                <FormControlLabel label={p.network} control={
                                    <MuiCheckbox disabled={mode === 'DEFAULT'} checked={p.enabled} onChange={handleChangeChecked(p, 'enabled')} color="secondary" />
                                } />
                            </TableCell>
                            <TableCell>
                                <MuiTextField value={p.payout} onChange={handleChangeValue(p, 'payout')} disabled={(mode === 'DEFAULT') || !p.enabled} error={validatePayout(p)} helperText={validatePayoutMsg(p)} />
                            </TableCell>
                            <TableCell>
                                <MuiTextField value={p.skips} onChange={handleChangeValue(p, 'skips')} disabled={(mode === 'DEFAULT') || !p.enabled} error={validateSkips(p)} helperText={validateSkipsMsg(p)} />
                            </TableCell>
                            { (mode === 'CUSTOM') &&
                                <TableCell>
                                    <Button color="secondary" variant="contained" onClick={(e) => handleDelete(e, p)}>X</Button>
                                </TableCell>
                            }
                        </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </div>

            <Divider />

            { (mode === 'CUSTOM') && hasAvailableParams && <Button color="secondary" variant="contained" onClick={handleAddParam}>Add Network</Button> }
            <AddParamDialog ref={addParamDialog} parentAddParam={parentAddParam} />
        </div>
    );
};
