import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { BACKEND_URL } from '../common/constants';

const MobileClubCampaignParam = ({id, ...props}) => (
    <Button 
        color="primary" 
        component={Link}
        to={{
            pathname: `/campaign_params/${id}`,
        }}
    >
        {id}
    </Button>
);

export default ({ record, ...props }) => {
    const [defaultParams, setDefaultParams] = useState([]);

    const notify = useNotify();

    useEffect(() => {
        if (!record) {
            return;
        }

        fetch(BACKEND_URL + '/campaign_params?aunique=' + record.platform_aunique + '&with_defaults=1', { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                if (json && json.length) {
                    setDefaultParams(json.filter(dp => dp.aEnabled === 1));
                } else {
                    setDefaultParams([]);
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    }, [record, notify]);

    const useStyles = makeStyles(theme => ({
        root: {
            maxWidth: '500px'
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h4>CPA payout mode in force: <strong>{record.params_mode}</strong></h4>
            <div style={{ overflowX: 'auto' }}>
                <Table aria-labelledby="tableTitle" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Network</TableCell>
                            <TableCell>Payout</TableCell>
                            <TableCell>Skips</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { defaultParams.map(dp => (
                        <TableRow key={dp.id}>
                            <TableCell><MobileClubCampaignParam id={dp.id} /></TableCell>
                            <TableCell>{dp.aRegion}</TableCell>
                            <TableCell>{dp.aNetwork}</TableCell>
                            <TableCell>{dp.aPayoutValue} {record.payout_currency}</TableCell>
                            <TableCell>{dp.aSkipNotifications}</TableCell>
                        </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
