import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNotify, usePermissions, Title, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { BACKEND_URL } from '../common/constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchIcon from '@material-ui/icons/Search';

export default (props) => {
    const { permissions } = usePermissions();

    const hasAccess = permissions && permissions.priv && (permissions.priv >= 9) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);

    const { msisdn } = useParams();

    const notify = useNotify();

    const [output, setOutput] = useState([]);

    useEffect(() => {
        setOutput([]);
    }, [msisdn]);

    const BlockToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton variant="contained" color="primary" label="Yes, approve" icon={<SearchIcon />} />
        </Toolbar>
    );

    const handleBlock = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        const obj = {};

        fetch(BACKEND_URL + '/user_total_block?msisdn=' + msisdn, { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.output && json.output.length) {
                            setOutput(json.output);
                        } else {
                            setOutput([]);
                        }
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    };

    return (
        <Card>
            <Title title={`Block MSISDN ${msisdn}`} />
            <CardContent>
                { hasAccess && <div>
                     <p>This block is total, and simultaneously unsubscribes a user, setting the account type to 99 to prevent resubscription.
                     It also deactivates any billingplans associated with this MSISDN.</p>

                     <h3>Are you certain that you want to do this for {decodeURIComponent(msisdn)}?</h3>

                     <SimpleForm resource="regions" submitOnEnter={true} onSubmit={handleBlock} toolbar={<BlockToolbar />} />

                     <p>Otherwise, please close this tab/window</p>

                    { output && !!output.length &&
                        <div>
                            <p>Attempting to block...</p>

                            <p>
                                <ul>
                                    { output.map((output_item, index) => <li key={index}>{output_item}</li>) } 
                                </ul>
                            </p>

                            <p>...done. Please check and report any errors. You can now close this tab/window.</p>
                        </div> }
                </div> }
            </CardContent>
        </Card>
    );
};
