import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { List, Datagrid, TextField, NumberField, SelectField, ReferenceField, DateField, Filter, 
    TextInput, NumberInput, SelectInput, DateInput, ReferenceInput, EditButton, FormDataConsumer, SimpleForm, Create, Edit /*, required*/ } from 'react-admin';
import { payoutSublists, payoutTypesPayout } from '../common/constants';
import { /*TextInputWithTip,*/ NumberInputWithTip, SelectInputWithTip /*, BooleanInputWithTip*/ } from '../components/TooltipComponents';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import AuditLog from '../components/AuditLog';

const PayoutFilter = ({setSublist, ...props}) => (
    <Filter {...props}>
        <SelectInput source="sublist" label="Sublist" choices={payoutSublists} optionText="name" optionValue="name" alwaysOn 
            onChange={event => { 
                if (event && event.target) {
                    setSublist(event.target.value);
                }
            }} />
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <ReferenceInput source="region" label="Region" reference="regions" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="aRegion" />
        </ReferenceInput>
        <TextInput source="network" label="Network Name" />
        <TextInput source="networkcode" label="Network Code" />
        <SelectInput source="payout_type" label="Payout Type" choices={payoutTypesPayout} optionText="name" optionValue="name" />
        <ReferenceInput source="aggregator" label="Aggregator" reference="aggregators" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="tariff" label="Tariff" />
        <NumberInput source="local" label="Local currency payout value" />
        <NumberInput source="percentage" label="Percentage" />
        <ReferenceInput source="currency" label="Currency" reference="mobile_club_currencies" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="updated" label="Updated At" />
    </Filter>
);

export const PayoutList = (props) => {
    const [sublist, setSublist] = useState('unfiltered');
    
    return (
    <List {...props} filters={<PayoutFilter setSublist={setSublist} />} filterDefaultValues={{ sublist: sublist }} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="region" label="Region" reference="regions" link={false}>
                <TextField source="aRegion" />
            </ReferenceField>
            <TextField source="network" label="Network Name" />
            <TextField source="networkcode" label="Network Code" />
            <SelectField source="payout_type" label="Payout Type" choices={payoutTypesPayout} optionText="name" optionValue="name" />
            <ReferenceField source="aggregator" label="Aggregator" reference="aggregators" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="tariff" label="Tariff" />
            { (sublist !== 'filtered') && <NumberField source="local" label="Local currency payout value" /> } 
            <NumberField source="percentage" label="Percentage" />
            <ReferenceField source="currency" label="Currency" reference="mobile_club_currencies" link={false}>
                <TextField source="name" />
            </ReferenceField>
            { (sublist !== 'filtered') && <DateField source="updated" label="Updated At" /> }
            { (sublist !== 'filtered') && <EditButton basePath="/payouts" /> }
        </Datagrid>
    </List>
    );
};

const PayoutTabs = ({ record, ...props }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Card>
        <CardContent>
            <MuiTabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
                <MuiTab key={0} value={0} label="Audit log" />
            </MuiTabs>
            <Divider />
            { (selectedTab === 0) && record && record.id && <AuditLog record={record} tableName="mobileNetworkPayouts" /> }
        </CardContent>
        </Card>
    );
};

const TariffInput = (props) => {
    const form = useForm();
    
    return (
        <FormDataConsumer {...props}>
            { ({formData, ...rest }) =>
                <NumberInputWithTip {...rest} source="tariff" label="Tariff" title="This should match the club's tariff." 
                    onChange={event => {
                        if (event && event.target) {
                            let tariff = parseFloat(event.target.value);
                            let local = parseFloat(formData.local);
                            if (isNaN(tariff)) {
                                return;
                            }
                            if (isNaN(local)) {
                                return;
                            }
                            let percentage;
                            if (tariff === 0) {
                                percentage = 0;
                            } else {
                                percentage = local / tariff;
                            }
                            form.change('percentage', percentage.toFixed(4));
                        } 
                    }} />
            }
        </FormDataConsumer>
    );
};

const LocalInput = (props) => {
    const form = useForm();
    
    return (
        <FormDataConsumer {...props}>
            { ({formData, ...rest }) =>
                <NumberInputWithTip {...rest} source="local" label="Local currency payout value" 
                    title="This value is not used directly but should be divided by the tariff to produce the percentage (which is). If percentage is not given, this division happens automatically."    
                    onChange={event => {
                        if (event && event.target) {
                            let tariff = parseFloat(formData.tariff);
                            let local = parseFloat(event.target.value);
                            if (isNaN(tariff)) {
                                return;
                            }
                            if (isNaN(local)) {
                                return;
                            }
                            let percentage;
                            if (tariff === 0) {
                                percentage = 0;
                            } else {
                                percentage = local / tariff;
                            }
                            form.change('percentage', percentage.toFixed(4));
                        } 
                    }} />
            }
        </FormDataConsumer>
    );
};

/*
const PercentageInput = (props) => {
    const form = useForm();
    
    return (
        <FormDataConsumer {...props}>
            { ({formData, ...rest }) =>
                <NumberInputWithTip {...rest} source="percentage" label="Percentage" title="This is the value Pareva uses by multiplying it by the obtained value when a bill succeeds to identify our payout."    
                    onChange={event => {
                        if (event && event.target) {
                            let tariff = parseFloat(formData.tariff);
                            let percentage = parseFloat(event.target.value);
                            if (isNaN(tariff)) {
                                return;
                            }
                            if (isNaN(percentage)) {
                                return;
                            }
                            let local = tariff * percentage;
                            form.change('local', local.toFixed(2));
                        } 
                    }} />
            }
        </FormDataConsumer>
    );
};
*/

const PayoutForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" /> }
        <ReferenceInput source="region" label="Region" reference="regions" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="aRegion" />
        </ReferenceInput>
        <TextInput source="network" label="Network Name" />
        <TextInput source="networkcode" label="Network Code" />
        <SelectInputWithTip source="payout_type" label="Payout Type" choices={payoutTypesPayout} optionText="name" optionValue="name" 
            title="Either Direct Carrier Billing, or Premium SMS (regardless of whether we or the aggregator bills)" />
        <ReferenceInput source="aggregator" label="Aggregator" reference="aggregators" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInputWithTip optionText="name" title="Taken from the club configurations" />
        </ReferenceInput>
        <TariffInput />
        {/*<NumberInputWithTip source="tariff" label="Tariff" title="This should match the club's tariff." />*/}
        <LocalInput />
        {/*<NumberInputWithTip source="local" label="Local currency payout value" 
            title="This value is not used directly but should be divided by the tariff to produce the percentage (which is). If percentage is not given, this division happens automatically." />*/}
        <NumberInputWithTip source="percentage" label="Percentage" title="This is the value Pareva uses by multiplying it by the obtained value when a bill succeeds to identify our payout." /> 
        <ReferenceInput source="currency" label="Currency" reference="mobile_club_currencies" perPage={100000} sort={{ field: 'id', order: 'ASC' }}>
            <SelectInputWithTip optionText="name" title="The options here come from the clubs list - make sure that the correct currency is available in the club before setting up payouts." />
        </ReferenceInput>
        <DateInput source="updated" disabled label="Updated At" />
    </SimpleForm>
);

const PayoutTitle = ({ record }) => {
    return <span>Payout {record ? `#${record.id}` : ''}</span>;
};

export const PayoutCreate = (props) => (
    <Create title="Create New Payout" {...props} style={{maxWidth: '550px'}}>
        <PayoutForm withId={false} redirect="list" />
    </Create>
);

export const PayoutEdit = (props) => (
    <Edit undoable={false} title={<PayoutTitle />} aside={<PayoutTabs />} {...props} className="maxWidthEdit960 sidePanelFix">
        <PayoutForm withId={true} />
    </Edit>
);
