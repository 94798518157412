import React from 'react';
import { List, Datagrid, TextField, BooleanField, NumberField, ReferenceField, Filter, TextInput, NumberInput, BooleanInput, SelectInput, ReferenceInput, 
        EditButton, SimpleForm, Create, Edit, required } from 'react-admin';
import { cpaParamsSublists } from '../common/constants';

const CampaignParamFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SelectInput source="sublist" label="Sublist" choices={cpaParamsSublists} optionText="name" optionValue="id" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="aCampaignUnique" label="Campaign" />
        <ReferenceInput source="aSrc" label="Source" reference="sources" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>        
        <ReferenceInput source="aClubUnique" label="Club" reference="mobile_clubs_for_params" perPage={100000}>
            <SelectInput optionText="club_name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="aNetwork" label="Network" reference="mobile_networks" perPage={100000} sort={{ field: 'aNetworkCode', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="aRegion" label="Region" reference="regions" sort={{ field: 'id', order: 'ASC' }} perPage={100000}>
            <SelectInput optionText="aRegion" optionValue="id" />
        </ReferenceInput>
        <NumberInput source="aPayoutValue" label="Payout" />
        <TextInput source="aSkipNotifications" label="Skip Notifications" />
        <BooleanInput source="aEnabled" label="Enabled" />
    </Filter>
);

export const CampaignParamList = (props) => (
    <List {...props} title="Campaign Defaults" filters={<CampaignParamFilter />}>
        <Datagrid>
            <ReferenceField source="id" label="ID" reference="campaign_params_menu">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField source="aCampaignUnique" label="Campaign" reference="campaigns_for_params" link={false}>
                <TextField source="camp_name" />
            </ReferenceField>
            <TextField source="aSrc" label="Source" />
            <ReferenceField source="aClubUnique" label="Club" reference="mobile_clubs_for_params" link={false}>
                <TextField source="club_name" />
            </ReferenceField>
            <TextField source="aNetwork" label="Network" />
            <TextField source="aRegion" label="Region" />
            <NumberField source="aPayoutValue" label="Payout" />
            <TextField source="aSkipNotifications" label="Skip Notifications" />
            <BooleanField source="aEnabled" label="Enabled" />
            <EditButton basePath="/campaign_params_menu" />
        </Datagrid>
    </List>
);

const CampaignParamForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" /> }
        <ReferenceInput source="aCampaignUnique" label="Campaign" reference="campaigns_for_params" perPage={100000}>
            <SelectInput optionText="camp_name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="aSrc" label="Source" reference="sources" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="aClubUnique" label="Club" reference="mobile_clubs_for_params" perPage={100000}>
            <SelectInput optionText="club_name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="aNetwork" label="Network" reference="mobile_networks" perPage={100000} sort={{ field: 'aNetworkCode', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput source="aRegion" label="Region" reference="regions" sort={{ field: 'id', order: 'ASC' }} perPage={100000}>
            <SelectInput optionText="aRegion" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <NumberInput source="aPayoutValue" label="Payout" validate={[required()]} />
        <TextInput source="aSkipNotifications" label="Skip Notifications" validate={[required()]} />
        <BooleanInput source="aEnabled" label="Enabled" />
    </SimpleForm>
);

const CampaignParamTitle = ({ record }) => {
    return <span>Campaign Param {record ? `#${record.id}` : ''}</span>;
};

export const CampaignParamCreate = (props) => (
    <Create title="Create New Campaign Param" {...props}>
        <CampaignParamForm withId={false} redirect="edit" />
    </Create>
);

export const CampaignParamEdit = (props) => (
    <Edit undoable={false} title={<CampaignParamTitle />} {...props}>
        <CampaignParamForm withId={true} redirect={false} />
    </Edit>
);
