import React, { cloneElement, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { List, Datagrid, TextField, DateField, Filter, TopToolbar, sanitizeListRestProps,
    TextInput, SelectInput, BooleanInput, DateInput, DateTimeInput, ReferenceInput, EditButton, SimpleForm, Create, Edit, required,  
    useNotify, fetchStart, fetchEnd } from 'react-admin';
import { TextInputWithTip } from '../components/TooltipComponents';    
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { BACKEND_URL } from '../common/constants';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const SelectSourceDialog = forwardRef(({parentHandleCopyLink, ...props}, ref) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSelectSource = () => {
        formSelectSource.submit();
    }

    const handleCreateInvite = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.source && !!values.source.length) {
            const obj = { 
                source: values.source
            };
            
            dispatch(fetchStart());
            fetch(BACKEND_URL + '/selfservice_invites', { 
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    },
                    body: JSON.stringify(obj)
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(fetchEnd());
                            if (json) {
                                setOpen(false);
                                if (parentHandleCopyLink) {
                                    parentHandleCopyLink(json.url, values.source);
                                }
                            }
                        });
                    } else {
                        response.json().then(e => {
                            dispatch(fetchEnd());
                            notify(e.message, 'warning');
                        });
                    }
                })
                .catch(e => {
                    dispatch(fetchEnd()); 
                    notify(e.message, 'warning'); 
                });
            
        }
    }
            
    const [formSelectSource, setFormSelectSource] = useState(null);

    const SelectSourceToolbar = ({handleSubmitWithRedirect, ...sstbprops}) => {
        const form = useForm();

        useEffect(() => {
            setFormSelectSource(form);
        }, [form]);

        return null;
    };
    
    useImperativeHandle(ref, () => ({
       setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        }
    }));

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="select-source-dialog-title"
            >
                <DialogTitle id="select-source-dialog-title">{"Create an Invite"}</DialogTitle>
                <DialogContent>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleCreateInvite}
                        toolbar={<SelectSourceToolbar />}
                    >
                        <ReferenceInput source="source" label="Source" reference="sources_names" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
                            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
                        </ReferenceInput>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSelectSource} variant="contained" color="primary" autoFocus>
                        Get Invite
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );    
});

const CopyLinkDialog = forwardRef(({parentHandleInviteUser, ...props}, ref) => {
    const notify = useNotify();
    const [isOpen, setOpen] = useState(false);
    const [partnerSource, setPartnerSource] = useState(null);
    
    const handleClose = () => {
        setOpen(false);
    }

    const handleCopyLink = () => {
        formCopyLink.submit();
    }

    const handleAnotherInvite = () => {
        setOpen(false);
        if (parentHandleInviteUser) {
            parentHandleInviteUser();
        }
    };
    
    const handleCopyToClipboard = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.inviteUrl && !!values.inviteUrl.length) {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(values.inviteUrl);
                notify('URL Copied to Clipboard!');
            }
        }
    }
            
    const [formCopyLink, setFormCopyLink] = useState(null);

    const CopyLinkToolbar = ({handleSubmitWithRedirect, ...cltbprops}) => {
        const form = useForm();

        useEffect(() => {
            setFormCopyLink(form);
        }, [form]);

        return null;
    };
    
    useImperativeHandle(ref, () => ({
       setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },
        
        setInviteUrlPublic: (pInviteUrl) => {
            if (formCopyLink) {
                formCopyLink.change('inviteUrl', pInviteUrl);
            }
        },
        
       setPartnerSourcePublic: (pPartnerSource) => {
            setPartnerSource(pPartnerSource);
        }        
    }));

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="copy-link-dialog-title"
            >
                <DialogTitle id="copy-link-dialog-title">{"Invitation Link"}</DialogTitle>
                <DialogContent>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleCopyToClipboard}
                        toolbar={<CopyLinkToolbar />}
                    >
                        <TextInput source="inviteUrl" label="Url" validate={[required()]} fullWidth />
                    </SimpleForm>
                    
                    <p>Copy the invite link and send it to your <strong>{partnerSource}</strong> partner contact.</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyLink} variant="contained" color="primary" autoFocus>
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleAnotherInvite} variant="contained" color="secondary">
                        Create another
                    </Button>                    
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );    
});

const InviteUserButton = (props) => {
    const selectSourceDialog = useRef(null);
    const copyLinkDialog = useRef(null);
     
    const handleInviteUser = () => {
        selectSourceDialog.current.setOpenPublic(true);
    };
    
    const handleCopyLink = (inviteUrl, partnerSource) => {
        copyLinkDialog.current.setOpenPublic(true);
        copyLinkDialog.current.setInviteUrlPublic(inviteUrl);
        copyLinkDialog.current.setPartnerSourcePublic(partnerSource);
    };
    
    return (
        <>
            <Button color="primary" variant="contained" onClick={handleInviteUser} size="large"><PersonAddIcon /> Invite a new user</Button>
            <SelectSourceDialog ref={selectSourceDialog} parentHandleCopyLink={handleCopyLink} />
            <CopyLinkDialog ref={copyLinkDialog} parentHandleInviteUser={handleInviteUser} />
        </>
    );
};

const SSUserActions = ({ className, permanentFilter, filters, resource, showFilter, displayedFilters, filterValues, ...rest }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <InviteUserButton />
    </TopToolbar>
);

const SSUserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <TextInput source="name" label="Name" />
        <TextInput source="email" label="Email" type="email" />
        <ReferenceInput source="source" label="Source" reference="sources_names" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <DateInput source="created_at" label="Created At" />
        <DateInput source="updated_at" label="Updated At" />
        <BooleanInput source="not_approved" label="Not approved" alwaysOn />
    </Filter>
);

const BecomeUserButton = (props) => {
    const { record } = props;
    let url = '#';
    if (record && record.email && !!record.email.length) {
        url = BACKEND_URL + '/become_user?email=' + record.email + '&api_token=' + localStorage.getItem('api_token');
    }
    return (
        <Button color="primary" size="small" variant="contained" component={MuiLink} href={url}>Become User</Button>
    );
};

export const SSUserList = (props) => (
    <List {...props} title="Self-Service Users" filters={<SSUserFilter />} actions={<SSUserActions />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
            <TextField source="source" label="Source" />
            <DateField source="created_at" locales="en-GB" label="Created" />
            <DateField source="updated_at" locales="en-GB" label="Updated" />
            <EditButton basePath="/ss_users" />
            <BecomeUserButton />
        </Datagrid>
    </List>
);

const PasswordWarningMessage = (props) => (
    <label><strong>PLEASE LEAVE "PASSWORD" AND "REPEAT PASSWORD" EMPTY IF YOU DON'T WANT TO CHANGE THE PASSWORD!<br/>
        YOU DON'T NEED TO ENTER THE PASSWORD IF YOU WANT TO CHANGE SOME OTHER SETTINGS OF THIS USER.</strong></label>
);

const SSUserForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        { withId && <TextInput disabled source="id" label="ID" /> }
        <TextInput source="name" label="Name" validate={[required()]} options={{ autoComplete: 'off' }} />
        <TextInput source="email" label="Email" type="email" validate={[required()]} options={{ autoComplete: 'off' }} />
        { withId && <PasswordWarningMessage /> }
        <TextInput source="password" label="Password" type="password" options={{ autoComplete: 'off' }} />
        <TextInput source="password_confirmation" label="Repeat Password" type="password" options={{ autoComplete: 'off' }} />
        <TextInputWithTip source="source" label="Source" title="Set this field and save to ACTIVATE this account. Clear this field and save to DEACTIVATE this account." />
        <DateTimeInput source="created_at" disabled label="Created At" />
        <DateTimeInput source="updated_at" disabled label="Updated At" />
    </SimpleForm>
);

const SSUserTitle = ({ record }) => {
    return <span>Self-Service User {record? `"${record.name}"` : ''}</span>;
};

export const SSUserCreate = (props) => (
    <Create title="Create New Self-Service User" {...props}>
        <SSUserForm withId={false} redirect="list" />
    </Create>
);

export const SSUserEdit = (props) => (
    <Edit undoable={false} title={<SSUserTitle />} {...props}>
        <SSUserForm withId={true} />
    </Edit>
);
