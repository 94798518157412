import React from 'react';
import {
    List, Datagrid, TextField, ReferenceField, ReferenceManyField, SingleFieldList, Filter,
    TextInput, BooleanInput, SelectInput, ReferenceInput, EditButton, SimpleForm, Create, Edit, required
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    cellCallbackPattern: {
        maxWidth: '400px',
        overflow: 'auto',
        textOverflow: 'inherit',
    },
    callbackPattern: {
        fontSize: '0.75rem',
        letterSpacing: '-0.5px',
    },
}));

const CPAAdvertiserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput source="id" label="ID" />
        <ReferenceInput source="aSrc" label="Source" reference="sources_names" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <TextInput source="aAlternativeCallbackCode" label="Alternative Callback Code" />
        <TextInput source="callBackPattern" label="Callback Pattern" />
        <TextInput source="aParameterToTrack" label="Parameter to Track" />
        <TextInput source="parameter1" label="Parameter1" />
        <TextInput source="parameter2" label="Parameter2" />
        <TextInput source="parameter3" label="Parameter3" />
        <TextInput source="generatedIDName" label="Generated ID Name" />
        <TextInput source="aPubIdTest" label="PubID Test" />
    </Filter>
);

export const CPAAdvertiserList = (props) => {
    const classes = useStyles();
    return (
        <List {...props} title="CPA Advertisers" filters={<CPAAdvertiserFilter />} sort={{ field: 'aSrc', order: 'ASC' }}>
            <Datagrid >
                <ReferenceField source="id" label="ID" reference="cpa_advertisers">
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceManyField source="aSrc" label="Source" reference="sources" target="name_exact" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
                    <SingleFieldList>
                        <TextField source="name" />
                    </SingleFieldList>
                </ReferenceManyField>
                <TextField source="aAlternativeCallbackCode" label="Alternative Callback Code" />
                <TextField source="callBackPattern" size="small" label="Callback Pattern" className={"trimmedText"} cellClassName={classes.cellCallbackPattern} />
                <TextField source="aParameterToTrack" label="Parameter to Track" />
                <TextField source="parameter1" label="Parameter1" />
                <TextField source="parameter2" label="Parameter2" />
                <TextField source="parameter3" label="Parameter3" />
                <TextField source="generatedIDName" label="Generated ID Name" />
                <TextField source="aPubIdTest" label="PubID Test" />
                <EditButton basePath="/cpa_advertisers" />
            </Datagrid>
        </List>
    );
};

const CPAAdvertiserForm = ({ withId, ...props }) => (
    <SimpleForm {...props} className="cpaAdvertiserForm">
        {withId && <TextInput disabled source="id" label="ID" />}
        <ReferenceInput source="aSrc" label="Source" reference="sources_names" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="aAlternativeCallbackCode" label="Alternative Callback Code" />
        <TextInput source="aParameterToTrack" label="Parameter to Track" />
        <TextInput source="auth" label="Auth" />
        <TextInput source="parameter1" label="Parameter1" />
        <TextInput source="parameter2" label="Parameter2" />
        <TextInput source="parameter3" label="Parameter3" />
        <TextInput source="callBackPattern" label="Callback Pattern" multiline rows={5} />
        <TextInput source="generatedIDName" label="Generated ID Name" />
        <TextInput source="aRecordedHashcode" label="Recorded Hashcode" />
        <TextInput source="aRecordedTransactionID" label="Recorded Transaction ID" />
        <TextInput source="aCredentials" label="Credentials" />
        <BooleanInput source="aCloseConnection" label="Close Connection" />
        <TextInput source="aPubIdTest" label="PubID Test" />
        <TextInput source="aRequieredParameter" label="Required Parameter" />
    </SimpleForm>
);

const CPAAdvertiserTitle = ({ record }) => {
    return <span>CPAAdvertiser {record ? `#${record.id}` : ''}</span>;
};

export const CPAAdvertiserCreate = (props) => (
    <Create title="Create New CPAAdvertiser" {...props}>
        <CPAAdvertiserForm withId={false} redirect="list" />
    </Create>
);

export const CPAAdvertiserEdit = (props) => (
    <Edit undoable={false} title={<CPAAdvertiserTitle />} {...props}>
        <CPAAdvertiserForm withId={true} />
    </Edit>
);
