import React, { cloneElement } from 'react';
import { List, Datagrid, TextField, ReferenceField, FileField, Filter, TextInput, SelectInput, ReferenceInput, FileInput, 
        EditButton, SaveButton, DeleteButton, SimpleForm, Toolbar, Create, Edit, required, useNotify, useRedirect, FormDataConsumer,
        TopToolbar, sanitizeListRestProps } from 'react-admin';
import { Link } from 'react-router-dom';
import { BACKEND_URL, connectionsForShots } from '../common/constants';
import Button from '@material-ui/core/Button';
import CloneIcon from '@material-ui/icons/Queue';
import { makeStyles } from '@material-ui/core/styles';

const AddScreenshotButton = ({ campaign_id, ...props }) => (
    <Button color="primary" variant="contained" component={Link} to={{ pathname: '/campaign_screenshots/create', state: {record: { campaign_id }} }}>Add New Screenshots</Button>
);

const CampaignScreenshotFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Campaign" source="campaign_id" reference="campaigns_for_screenshots" perPage={100000} filter={{ sublist: 'offers' }} alwaysOn>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <FormDataConsumer source="new_screenshot" alwaysOn>
             { ({formData, ...rest }) => formData.campaign_id &&
                 <AddScreenshotButton {...rest} campaign_id={formData.campaign_id} />
             }
        </FormDataConsumer>
        <TextInput source="id" label="ID" />
        <SelectInput source="connection" label="Connection" choices={connectionsForShots} optionText="name" optionValue="name" />
        <ReferenceInput source="network_id" label="Network" reference="mobile_networks_for_screenshots" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>        
    </Filter>
);

const CampaignScreenshotActions = ({ className, permanentFilter, filters, resource, showFilter, displayedFilters, filterValues, ...rest }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}        
    </TopToolbar>
);

export const CampaignScreenshotList = (props) => (
    <List {...props} filters={<CampaignScreenshotFilter />} actions={<CampaignScreenshotActions />}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="campaign_id" label="Campaign" reference="campaigns_for_screenshots" filter={{ sublist: 'offers' }} link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="connection" />
            <ReferenceField source="network_id" label="Network" reference="mobile_networks_for_screenshots" sort={{ field: 'name', order: 'ASC' }} link={false}>
                <TextField source="name" />
            </ReferenceField>  
            <TextField source="filename" />
            <TextField source="caption" />
            <TextField source="step2" />
            <TextField source="caption2" />
            <TextField source="step3" />
            <TextField source="caption3" />
            <TextField source="step4" />
            <TextField source="caption4" />
            <TextField source="step5" />
            <TextField source="caption5" />
            <EditButton basePath="/campaign_screenshots" />
        </Datagrid>
    </List>
);

const BackToScreenshotsListButton = ({ record, ...props }) => (
    <Button color="primary" variant="contained" component={Link} to={{ pathname: `/campaign_screenshots`, search: `?filter={"campaign_id":"${record.campaign_id}"}` }}>Back to Screenshots List</Button>
);

const UploadSizeWarningMessage = (props) => (
    <label><strong>WARNING! TOTAL SIZE OF UPLOADED SCREENSHOTS SHOULDN'T EXCEED 10MB!</strong></label>
);

const CampaignScreenshotForm = ({ withId, ...props }) => (
    <SimpleForm {...props}>
        <BackToScreenshotsListButton />
        { withId && <TextInput disabled source="id" /> }
        <ReferenceInput source="campaign_id" label="Campaign" reference="campaigns_for_screenshots" perPage={100000} filter={{ sublist: 'offers' }}>
            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <SelectInput source="connection" label="Connection" choices={connectionsForShots} optionText="name" optionValue="name" validate={[required()]} />
        <ReferenceInput source="network_id" label="Network" reference="mobile_networks_for_screenshots" perPage={100000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" optionValue="id" validate={[required()]} />
        </ReferenceInput>
        <UploadSizeWarningMessage />
        <FileInput source="url1" accept="image/*">
            <FileField source="url" title="title" target="_blank" />
        </FileInput>
        <TextInput source="caption" />
        <FileInput source="url2" accept="image/*">
            <FileField source="url" title="title" target="_blank" />
        </FileInput>
        <TextInput source="caption2" />
        <FileInput source="url3" accept="image/*">
            <FileField source="url" title="title" target="_blank" />
        </FileInput>
        <TextInput source="caption3" />
        <FileInput source="url4" accept="image/*">
            <FileField source="url" title="title" target="_blank" />
        </FileInput>
        <TextInput source="caption4" />
        <FileInput source="url5" accept="image/*">
            <FileField source="url" title="title" target="_blank" />
        </FileInput>
        <TextInput source="caption5" />
    </SimpleForm>
);

const CampaignScreenshotTitle = ({ record }) => {
    return <span>CampaignScreenshot {record ? `#${record.id}` : ''}</span>;
};

export const CampaignScreenshotCreate = (props) => (
    <Create title="Create New CampaignScreenshot" {...props}>
        <CampaignScreenshotForm withId={false} redirect="list" />
    </Create>
);

const ScreenshotCloneButton = ({ record, ...props }) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClick = (e) => {
        if (!record) {
            return;
        }

        fetch(BACKEND_URL + '/clone_campaign_screenshot/' + record.id, { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.success) {
                            redirect(`/campaign_screenshots/${json.newId}`);
                            notify('You have cloned a campaign screenshots record. Duplicate combinations of <br/>(OfferID,Connection,NetworkID) cause problems in Self-Service.<br/>Please change the Connection or the Network of This record AS SOON AS POSSIBLE!');
                        }
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    };

    return (
        <Button color="primary" variant="contained" onClick={handleClick}><CloneIcon /> Clone ALL</Button>
    );
};

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const CampaignScreenshotToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton />
            <ScreenshotCloneButton />
            <DeleteButton undoable={false} />
        </Toolbar>
    );
};


export const CampaignScreenshotEdit = (props) => (
    <Edit undoable={false} title={<CampaignScreenshotTitle />} {...props}>
        <CampaignScreenshotForm withId={true} toolbar={<CampaignScreenshotToolbar />} />
    </Edit>
);

