import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNotify, useRedirect } from 'react-admin';
import { BACKEND_URL } from '../common/constants';

export default (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { platform_aunique } = useParams();

    useEffect(() => {
        if (!(platform_aunique && !!platform_aunique.length)) {
            return;
        }

        let url = `${BACKEND_URL}/campaigns?_end=10&_order=DESC&_sort=id&_start=0&platform_aunique=${platform_aunique}&rotators_only=false&sublist=all`;
        fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                }
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let found = false;
                        if (json && !!json.length) {
                            let campaign = json[0];
                            if (campaign && campaign.id) {
                                found = true;
                                redirect(`/campaigns/${campaign.id}`);
                                //notify('Campaign found.');
                            } 
                        }
                        if (!found) {
                            redirect(`/campaigns?filter={"sublist":"all"}`);
                            notify('Campaign not found.');
                        }    
                    });
                } else {
                    response.json().then(e => {
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });            
    }, [platform_aunique, redirect, notify]);
    return null;
};