import React, { useState, useEffect, Fragment } from 'react';
import { useNotify } from 'react-admin';
import { BACKEND_URL, convertPHPDateToJS } from '../common/constants';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export default ({ record, tableName, ...props }) => {
    const [logs, setLogs] = useState([]);
    const [columnNames, setColumnNames] = useState([]);

    const notify = useNotify();

    useEffect(() => {
        if (!tableName) {
            return;
        }
        
        if (!record) {
            return;
        }

        let isCancelled = false;
        fetch(BACKEND_URL + '/audit_log?entity=' + tableName + '&entity_id=' + record.id, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                let newLogs = [];
                if (json && json.logs) {
                    newLogs = json.logs.map(log => ({ ...log, diff: JSON.parse(log.diff) }));
                }
                let newColumnNames = [];
                if (json && json.columnNames) {
                    newColumnNames = json.columnNames;
                }
                if (!isCancelled) {
                    setLogs(newLogs);
                    setColumnNames(newColumnNames);
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
            
        return function cleanup() { 
            isCancelled = true;
        };      
    }, [record, tableName, notify]);

    const getFieldName = (key) => {
        if (key && !!key.length && columnNames && (key in columnNames)) {
            let newKey = columnNames[key];
            if (newKey && !!newKey.length) {
                return newKey;
            }
        }
        return key;
    };
    
    const useStyles = makeStyles(theme => ({
        root: {
            //width: '500px'
            marginTop: '1em'
        },
        noWrap: {
            //whiteSpace: 'nowrap'
        },
        noPadding: {
            padding: 0
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            { logs.map(l => 
                <ExpansionPanel key={l.id} defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content">
                        <Typography className={classes.heading}>{ l.action } at <b>{ convertPHPDateToJS(l.datetime).toLocaleString('en-GB') }</b> by <b>{ l.user.fullname }</b></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.noPadding}>
                        <Table aria-labelledby="tableTitle" size="small">
                            <TableHead>
                            { (l.action === 'CREATE') &&
                                <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell>New Value</TableCell>
                                </TableRow>
                            }

                            { (l.action === 'UPDATE') &&
                                <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell>Old Value</TableCell>
                                    <TableCell>New Value</TableCell>
                                </TableRow>
                            }

                            { (l.action === 'DELETE') &&
                                <TableRow>
                                    <TableCell>Field</TableCell>
                                    <TableCell>Old Value</TableCell>
                                </TableRow>
                            }
                            </TableHead>
                            <TableBody>
                            { Object.keys(l.diff).map(k =>
                                <Fragment key={k}>
                                { (l.action === 'CREATE') &&
                                    <TableRow>
                                        <TableCell className={classes.noWrap}>{getFieldName(k)}</TableCell>
                                        <TableCell>{l.diff[k]}</TableCell>
                                    </TableRow>
                                }

                                { (l.action === 'UPDATE') &&
                                    <TableRow>
                                        <TableCell className={classes.noWrap}>{getFieldName(k)}</TableCell>
                                        <TableCell>{l.diff[k].old}</TableCell>
                                        <TableCell>{l.diff[k].new}</TableCell>
                                    </TableRow>
                                }

                                { (l.action === 'DELETE') &&
                                    <TableRow>
                                        <TableCell className={classes.noWrap}>{getFieldName(k)}</TableCell>
                                        <TableCell>{l.diff[k]}</TableCell>
                                    </TableRow>
                                }
                                </Fragment>
                            )}
                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )}
        </div>
    );
};
