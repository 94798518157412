import React, { useEffect } from 'react';
import { Login } from 'react-admin';

export default (props) => {
    useEffect(() => {
        document.title = 'BI3: Login';
    });  
      
    return (
        <Login style={{ backgroundColor: '#000077' }} backgroundImage={null} />
    );
};
