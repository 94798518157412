import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNotify, useRedirect, Title, SimpleForm, TextInput, required, Toolbar, SaveButton } from 'react-admin';
import { BACKEND_URL } from '../common/constants';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

import CardWithIcon from './ui/CardWithIcon';
import CampaignIcon from '@material-ui/icons/Ballot';
import SourceIcon from '@material-ui/icons/BusinessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SsUsersIcon from '@material-ui/icons/PeopleAlt';
import SsAddUsersIcon from '@material-ui/icons/PersonAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

export default ({ permissions, ...rest }) => {
    const [dashboard, setDashboard] = useState({});

    const notify = useNotify();

    const redirect = useRedirect();

    useEffect(() => {
        fetch(BACKEND_URL + '/dashboard', { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                setDashboard(json);
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
    }, [notify]);

    const handleSubmitSearch = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values && values.id) {
            fetch(BACKEND_URL + '/campaigns?sublist=all&platform_aunique=' + values.id, { 
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                    } 
                })
                .then(response => response.json())
                .then(json => {
                    if (json && json.length) {
                        let cmp = json[0];
                        redirect('/campaigns/' + cmp.id);
                    } else {
                        redirect('/campaigns/' + values.id);
                    }
                })
                .catch(e => { 
                    notify(e.message, 'warning'); 
                });
        }
    }

    const useStyles = makeStyles(theme => ({
        firstRow: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
        },
        secondRow: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
        },
        searchToolbar: {
            background: 'none',
            padding: '0',
            margin: '0',
        },
        buttons: {
            margin: 'auto',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            flex: '1',
            minWidth: '220px',
            margin: '1rem',
            alignItems: 'center',
        },
         root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center'
        },
        input: {
            flex: 1,
        },
        h2heading: {
            marginBottom: '0'
        }
        
    }));

    const classes = useStyles();

    const SearchToolbar = (props) => (
        <Toolbar {...props} className={classes.searchToolbar}>
            <SaveButton variant="contained" color="primary" size="large" label="Go" icon={<SearchIcon />} />
        </Toolbar>
    );

    const CPAQueueStatusButton = (props) => (
        <Button variant="contained" color="primary" component={Link} to={{ pathname: '/run_adhoc_query/108' }}>CPA queue status</Button>
    );

    const NewSourceButton = (props) => (
        <Button component={Link} startIcon={<AddToQueueIcon />} to={{ pathname: '/sources/create' }}>New Source</Button>
    );

    const NewCampaignButton = (props) => (
        <Button component={Link} startIcon={<NoteAddIcon />} to={{ pathname: '/campaigns/create', state: {record: { purpose: 'campaign' }} }}>New Campaign</Button>
    ); 

    const NewOfferButton = (props) => (
        <Button component={Link} startIcon={<PostAddIcon />} to={{ pathname: '/campaigns/create', state: {record: { purpose: 'offer' }} }}>New Offer</Button>
    );
    
    const NewSSUserButton = (props) => (
        <Button component={Link} startIcon={<SsAddUsersIcon />} to={{ pathname: '/ss_users', search: `?filter={}` }}>New SS User</Button>
    );

    const hasAccess = permissions && permissions.priv && (permissions.priv >= 7) && permissions.areas && (permissions.areas.indexOf('stats') >= 0);

    return (
        <Card>
            <Title title="Dashboard" />
            <CardContent>
                
                { hasAccess && 
                <div className={classes.firstRow}>
                    <CardWithIcon
                        to={{ pathname: "/campaigns", search: `?filter={"sublist":"live"}` }}
                        icon={CampaignIcon} 
                        title={'Live Campaigns'}
                        subtitle={dashboard.live_campaigns}
                        children={<NewCampaignButton />}
                    />
                   
                    <CardWithIcon
                        to="/sources"
                        icon={SourceIcon} 
                        title={'Sources'}
                        subtitle={dashboard.sources}
                        children={<NewSourceButton />}
                    />
                   
                    <CardWithIcon
                        to={{ pathname: "/campaigns", search: `?filter={"sublist":"offers"}` }}
                        icon={AssignmentIcon} 
                        title={'Offers'}
                        subtitle={dashboard.offers}
                        children={<NewOfferButton />}
                    />

                    <CardWithIcon 
                        to={{ pathname: "/ss_users", search: `?filter={"not_approved":"true"}` }}
                        icon={SsUsersIcon} 
                        title={'Pending SS Users'}
                        subtitle={dashboard.pending_ss_users}
                        children={<NewSSUserButton />}
                    />
                    
                    
                </div>}
                { hasAccess && 
                <div className={classes.secondRow}>
                    <Card className={classes.card}>
                        <h2 align="center" className={classes.h2heading} >Jump to Campaign by Unique or ID</h2>
                       <CardContent>
                            
                                <SimpleForm
                                    className={classes.root}
                                    resource="regions"
                                    submitOnEnter={true}
                                    onSubmit={handleSubmitSearch}
                                    toolbar={<SearchToolbar  className={classes.iconButton} />}
                                >
                                
                                <TextInput 
                                    source="id" 
                                    label="ID"
                                    className={classes.input}
                                    helperText="Jump to Campaign by Unique or ID" 
                                    validate={required()}
                                />
                                </SimpleForm>
                            
                        </CardContent>
                    </Card>

                    <Card  className={classes.card}>
                        <div className={classes.buttons}>
                            <CPAQueueStatusButton />
                        </div>
                    </Card>
                </div>}
                
            </CardContent>
        </Card>
    );
};
