import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Layout, useLoading } from 'react-admin';
import { MyContext } from './MyContext';
import MyAppBar from './MyAppBar';
import MyMenu from './MyMenu';
import MyNotification from './MyNotification';

export default (props) => {
    const [ showIndicator, setShowIndicator ] = useState(false);
    const loading = useLoading();
    
    const getPageTitle = () => {
        let titleElem = document.getElementById('react-admin-title');
        if (!titleElem) {
            return 'BI3';
        }
        if (titleElem.children && !!titleElem.children.length) {
            titleElem = titleElem.children[0];
        }
        return 'BI3: ' + titleElem.innerHTML;
    };
    
    useEffect(() => {
        document.title = getPageTitle();
    });
        
    return (
        <MyContext.Provider value={{showIndicator, setShowIndicator}}> 
            <Layout {...props} appBar={MyAppBar} menu={MyMenu} notification={MyNotification} className={classnames({ 'disabled-overlay': (showIndicator && loading) })}/>
        </MyContext.Provider>
    );
};

