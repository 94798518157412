import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useForm } from 'react-final-form';
import { useNotify, usePermissions, fetchStart, fetchEnd, TextInput, Title, SimpleForm, Toolbar, SaveButton, required } from 'react-admin';
import { BACKEND_URL } from '../common/constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BooleanInputWithTip } from './TooltipComponents';

export default (props) => {
    const { permissions } = usePermissions(); 
    
    const hasAccess = permissions && permissions.priv && (permissions.priv >= 6) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    
    const notify = useNotify();
    const dispatch = useDispatch();
    
    const [tables, setTables] = useState(null);
    const [formClear, setFormClear] = useState(null);
    const [resultMsisdn, setResultMsisdn] = useState(null);
        
    const ClearMsisdnToolbar = (cmtbprops) => { 
        const form = useForm();

        useEffect(() => {
            setFormClear(form);
        }, [form]);
                
        return (
            <Toolbar {...cmtbprops}>
                <SaveButton variant="contained" color="primary" label="Clear" icon={<ClearIcon />} />
            </Toolbar>
        );
    };

    const { msisdnParam } = useParams();
    useEffect(() => {
        if (formClear && msisdnParam && !!msisdnParam.length) {
            formClear.change('msisdn', msisdnParam);
        }
    }, [msisdnParam, formClear]);    
        
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        if (!(values && values.msisdn && !!values.msisdn.length)) {
            return;
        }
        
        const obj = { msisdn: values.msisdn, i_understand: values.i_understand };

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/clear_test_msisdn', { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json) {
                            setResultMsisdn(json.msisdn);
                        }
                        if (json && json.tables) {
                            setTables(json.tables);
                        } else {
                            setTables(null);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });
    };
        
    return (
        <Card>
            <Title title="Clear Test MSISDN" />
            <CardContent>
                { hasAccess && 
                <div>
                    <SimpleForm
                        resource="regions"
                        onSubmit={handleSubmit}
                        toolbar={<ClearMsisdnToolbar />}
                    >
                        <TextInput
                            source="msisdn"
                            label="Enter a MSISDN"
                            validate={required()}
                        />
                        
                        <BooleanInputWithTip
                            source="i_understand"
                            label="I Understand"
                            title="I understand that this tool should NEVER be used on a non-test MSISDN or customer MSISDN as most records of their interaction with our services will be lost forever!"
                        />
                    </SimpleForm>
                    
                    { tables && <div style={{ overflowX: 'auto' }}>
                        <Table aria-labelledby="tableResults">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Query</TableCell>
                                    <TableCell>Success</TableCell>
                                    <TableCell>Exec time</TableCell>
                                    <TableCell>Error</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { Object.keys(tables).map(k => 
                                <TableRow key={k}>
                                    <TableCell>{ k }</TableCell>
                                    <TableCell>{ tables[k].return_val? <CheckIcon /> : <ClearIcon /> }</TableCell>
                                    <TableCell><em>{tables[k].query_execution_time} ms</em></TableCell>
                                    <TableCell>{ tables[k].error }</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </div> }
                    
                    { tables && resultMsisdn && !!resultMsisdn.length && 
                        <div>
                            <p>The URL below can now be shared. If an authorised user clicks it, it will bring him back to this page.
                            If he clears this MSISDN, that will be logged against his username. 
                            He still has to check <strong>"I understand"</strong> checkbox and then click the <strong>"Clear"</strong> button.</p>
                            <Link to={{ pathname: `/clear_test_msisdn/${resultMsisdn}` }}>Clear Test MSISDN: {resultMsisdn}</Link>
                        </div> }
                             
                </div> }
            </CardContent>
        </Card>
    );
};
