import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';

const styles = {
    listItem: {
        paddingLeft: '1rem',
    },
    listItemIcon: {
        minWidth: 40,
    },
    listItemIcon2: {
        minWidth: 30,
    },
    listItemText: {
        paddingLeft: 2,
        fontSize: '1rem',
        whiteSpace: 'nowrap',
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
};

const MySubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    classes,
    children,
    translate,
}) => {
    const header = (
        <ListItem
            dense
            button
            onClick={handleToggle}
            className={classes.listItem}
        >
            <ListItemIcon className={classes.listItemIcon}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
            { sidebarIsOpen && !isOpen && <ListItemIcon className={classes.listItemIcon2}><ChevronRight /></ListItemIcon> }
            <ListItemText
                inset
                primary={isOpen ? name : ''}
                secondary={isOpen ? '' : name}
                className={classes.listItemText}
            />
        </ListItem>
    );
    
    return (
    <Fragment>
        {(sidebarIsOpen || isOpen) ? (
            header
        ) : (
            <Tooltip title={name} placement="right">
                {header}
            </Tooltip>
        )}
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List
                dense
                component="div"
                disablePadding
                className={
                    sidebarIsOpen
                        ? classes.sidebarIsOpen
                        : classes.sidebarIsClosed
                }
            >
                {children}
            </List>
            <Divider />
        </Collapse>
    </Fragment>
    );
};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(MySubMenu);
