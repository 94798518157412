import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useForm } from 'react-final-form';
import { useNotify, useRedirect, usePermissions, fetchStart, fetchEnd, Title, FileField, FileInput, SimpleForm, Toolbar, SaveButton, DeleteButton, required } from 'react-admin';
import { BACKEND_URL, convertFileToBase64 } from '../common/constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

export default (props) => {
    const { permissions } = usePermissions();

    const hasAccess = permissions && permissions.priv && (permissions.priv >= 9) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    
    const { campaign_id } = useParams();

    const dispatch = useDispatch();    
    const notify = useNotify();
    const redirect = useRedirect();
    
    const [formPromo, setFormPromo] = useState(null);

    const CampaignPromoToolbar = (cptbprops) => { 
        const form = useForm();

        useEffect(() => {
            setFormPromo(form);
        }, [form]);
                
        return (
            <Toolbar {...cptbprops} record={{ id: campaign_id }}>
                <SaveButton />
                <DeleteButton undoable={false} redirect={`/campaigns/${campaign_id}`} />
            </Toolbar>
        );
    };
        
    useEffect(() => {
        if (!campaign_id) {
            return;
        }
        if (!formPromo) {
            return;
        }
        
        dispatch(fetchStart());
        fetch(BACKEND_URL + '/campaign_promo?campaign_id=' + campaign_id, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                }
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (formPromo) {
                            if (json && !!json.length) {
                                formPromo.change('url1', json[0]);
                            } else {
                                formPromo.change('url1', null);
                            }
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });        
    }, [formPromo, campaign_id, dispatch, notify]);
    
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        if (campaign_id && values && values.url1 && values.url1.rawFile && (values.url1.rawFile instanceof File)) {
            let url1 = values.url1;
            convertFileToBase64(url1)
            .then(picture64 => {
                url1.picture64 = picture64;
                
                const obj = {
                    campaign_id,
                    url1
                };        
        
                dispatch(fetchStart());
                fetch(BACKEND_URL + '/campaign_promo', { 
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                        },
                        body: JSON.stringify(obj)
                    })
                    .then(response => {
                        if (response.ok) {
                            response.json().then(json => {
                                dispatch(fetchEnd());
                                if (json && json.success) {
                                    redirect(`/campaigns/${campaign_id}`);
                                    notify('File(s) uploaded successfully.');
                                }
                            });
                        } else {
                            response.json().then(e => {
                                dispatch(fetchEnd());
                                notify(e.message, 'warning');
                            });
                        }
                    })
                    .catch(e => {
                        dispatch(fetchEnd()); 
                        notify(e.message, 'warning'); 
                    });        
            });
        }    
    };


    const BackToOfferButton = ({ campaign_id, ...btobprops }) => (
        <div><Button color="primary" variant="contained" component={Link} to={{ pathname: `/campaigns/${campaign_id}` }}>Back to Offer</Button></div>
    );
    
    const UploadSizeWarningMessage = (uswmprops) => (
        <div><label><strong>PLEASE CREATE A ZIP FILE CONTAINING ALL THE PROMO MATERIALS AND THEN CHOOSE THAT ZIP FILE FOR UPLOAD.<br/>
        WARNING! THE SIZE OF THE UPLOADED ZIP FILE SHOULDN'T EXCEED 7MB!</strong></label></div>
    );
        
    return (
        <Card>
            <Title title={`Promo material for offer: #${campaign_id}`} />
            <CardContent>
                { hasAccess && campaign_id && 
                <div>
                    <BackToOfferButton campaign_id={campaign_id} />
                    <UploadSizeWarningMessage />
                    <SimpleForm 
                        resource="campaign_promo"
                        onSubmit={handleSubmit}
                        toolbar={<CampaignPromoToolbar />}
                    >
                        <FileInput source="url1" accept="application/zip" validate={[required()]}>
                            <FileField source="url" title="title" target="_blank" />
                        </FileInput>
                    </SimpleForm>
                </div> }
            </CardContent>
        </Card>
    );
};        
