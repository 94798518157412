import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { useNotify } from 'react-admin';
import { BACKEND_URL, convertPHPDateToJS } from '../common/constants';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const CompareLogDialog = forwardRef((props, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [currLog, setCurrLog] = useState(null);
    const [prevLog, setPrevLog] = useState(null);
    
    let hobj = currLog? currLog.history_obj : null;
    let prev_hobj = prevLog? prevLog.history_obj : null;

    const [cmpCurr, setCmpCurr] = useState( {dest: {}} );
    const [cmpPrev, setCmpPrev] = useState( {dest: {}} );
    
    useEffect(() => {
        if (!currLog || !prevLog) {
            return;
        }
        if (!currLog.history_obj || !prevLog.history_obj) {
            return; 
        }
        
        let curr = currLog.history_obj;
        let prev = prevLog.history_obj;
        
        let newCmpCurr = {dest: {}};
        let newCmpPrev = {dest: {}};
        
        if (curr.service && prev.service && 
            curr.service.id && !!curr.service.id.length &&
            prev.service.id && !!prev.service.id.length &&
            (curr.service.id === prev.service.id)) {
            newCmpCurr.service = 'none';
            newCmpPrev.service = 'none';
        } else {
            newCmpCurr.service = 'updated';
            newCmpPrev.service = 'updated';
        }
        
        if ((curr.sourceCap !== null) && 
            (prev.sourceCap !== null) && 
            (curr.sourceCap === prev.sourceCap) ) {
            newCmpCurr.sourceCap = 'none';
            newCmpPrev.sourceCap = 'none';
        } else {
            newCmpCurr.sourceCap = 'updated';
            newCmpPrev.sourceCap = 'updated';
        }
        
        if ((curr.cap_active !== null) && 
            (prev.cap_active !== null) &&
            (curr.cap_active === prev.cap_active) ) {
            newCmpCurr.cap_active = 'none';
            newCmpPrev.cap_active = 'none';
        } else {
            newCmpCurr.cap_active = 'updated';
            newCmpPrev.cap_active = 'updated';
        }
        
        if (curr.destinationCaps && prev.destinationCaps /*&&
            !!curr.destinationCaps.length && !!prev.destinationCaps.length*/) {
            let destCaps = curr.destinationCaps;
            let prevDestCaps = prev.destinationCaps;
            
            for (let i = 0; i < destCaps.length; i++) {
                let d = destCaps[i];
                
                for (let j = 0; j < prevDestCaps.length; j++) {
                    let pd = prevDestCaps[j];
                    
                    if (d.club && d.club.id && !!d.club.id.length &&
                        pd.club && pd.club.id && !!pd.club.id.length &&
                        (d.club.id === pd.club.id) ) {
                        d.found = true;
                        pd.found = true;
                        if ((d.cap !== null) && 
                            (pd.cap !== null) && 
                            (d.cap === pd.cap) &&
                            (d.mode === pd.mode) && 
                            (
                                (
                                d.lander && d.lander.id && !!d.lander.id.length &&
                                pd.lander && pd.lander.id && !!pd.lander.id.length &&
                                (d.lander.id === pd.lander.id)
                                ) ||
                                (
                                d.schedule && d.schedule.id && !!d.schedule.id.length &&
                                pd.schedule && pd.schedule.id && !!pd.schedule.id.length &&
                                (d.schedule.id === pd.schedule.id)
                                )
                            ) ) {
                            newCmpCurr.dest[d.club.id] = 'none';
                            newCmpPrev.dest[pd.club.id] = 'none';
                        } else {
                            newCmpCurr.dest[d.club.id] = 'updated';
                            newCmpPrev.dest[pd.club.id] = 'updated';
                        }
                        break;
                    }
                        
                }
            }
            
            for (let i = 0; i < destCaps.length; i++) {
                let d = destCaps[i];
                if (!d.found) {
                    newCmpCurr.dest[d.club.id] = 'created';
                } else {
                    d.found = false;
                }
            }
            for (let i = 0; i < prevDestCaps.length; i++) {
                let pd = prevDestCaps[i];
                if (!pd.found) {
                    newCmpPrev.dest[pd.club.id] = 'deleted';
                } else {
                    pd.found = false;
                }
            }
        }
        
        setCmpCurr(newCmpCurr);
        setCmpPrev(newCmpPrev);
    }, [currLog, prevLog]);
    
    const handleClose = () => {
        setOpen(false);
    }

    useImperativeHandle(ref, () => ({
        setOpenPublic: (pOpen) => {
            setOpen(pOpen);
        },

        setCurrLogPublic: (pCurrLog) => {
            setCurrLog(pCurrLog);
        },

        setPrevLogPublic: (pPrevLog) => {
            setPrevLog(pPrevLog);
        }
    }));

    const useStyles = makeStyles(theme => ({
        statusNone: {
        },
        statusCreated: {
            color: '#00FF00'
        },
        statusUpdated: {
            color: '#0000FF'
        },
        statusDeleted: {
            color: '#FF0000'
        },
        panelLegend: {
            display: 'flex', 
            flexFlow: 'row wrap', 
            justifyContent: 'space-around'
        },
        panelLogs: {
            display: 'flex', 
            flexFlow: 'row nowrap', 
            justifyContent: 'space-between'
        },
        panelLeftLog: {
            display: 'flex', 
            flex: 1, 
            flexFlow: 'column nowrap', 
            borderRight: '1px solid black'
        },
        panelRightLog: {
            display: 'flex', 
            flex: 1, 
            flexFlow: 'column nowrap', 
            borderLeft: '1px solid black'
        }
    }));

    const classes = useStyles();

    const getStatusClass = (status) => {
        switch (status) {
            case 'created':
                return classes.statusCreated;
            case 'updated':
                return classes.statusUpdated;
            case 'deleted':
                return classes.statusDeleted;
            default:
                return classes.statusNone;
        }
    };

    return (
        <div>
            <Dialog
                {...props}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="compare-log-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="compare-log-dialog-title">{"Compare Current and Previous Logs:"}</DialogTitle>
                <DialogContent>
                    <ul className={classes.panelLegend}>
                        <li className={classes.statusCreated}>Created</li>
                        <li className={classes.statusUpdated}>Updated</li>
                        <li className={classes.statusDeleted}>Deleted</li>
                    </ul>

                    <div className={classes.panelLogs}>
                        <div className={classes.panelLeftLog}>

                        { hobj &&
                        <Table aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>CURRENT LOG:</TableCell>
                                </TableRow>
                                <TableRow>
                                   <TableCell>Name</TableCell>
                                   <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={getStatusClass(cmpCurr.service)}>Service:</TableCell>
                                    <TableCell className={getStatusClass(cmpCurr.service)}>{hobj.service? hobj.service.name : '' }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={getStatusClass(cmpCurr.sourceCap)}>Source CAP:</TableCell>
                                    <TableCell className={getStatusClass(cmpCurr.sourceCap)}>{hobj.sourceCap}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={getStatusClass(cmpCurr.cap_active)}>Is Active:</TableCell>
                                    <TableCell className={getStatusClass(cmpCurr.cap_active)}>{hobj.cap_active? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        }
                        
                        { hobj && hobj.destinationCaps && !!hobj.destinationCaps.length &&
                        <Table aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4}>Destination CAPs</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Index</TableCell>
                                    <TableCell>Dest. Club</TableCell>
                                    <TableCell>Dest. CAP</TableCell>
                                    <TableCell>Dest. Mode</TableCell>
                                    <TableCell>Dest. Landing Page</TableCell>
                                    <TableCell>Dest. Schedule</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { hobj.destinationCaps.map((d, index) => 
                                <TableRow key={index}>
                                    <TableCell className={getStatusClass(d.club? cmpCurr.dest[d.club.id] : null)}>{index + 1}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpCurr.dest[d.club.id] : null)}>{d.club? d.club.name : ''}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpCurr.dest[d.club.id] : null)}>{d.cap}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpCurr.dest[d.club.id] : null)}>{d.mode}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpCurr.dest[d.club.id] : null)}>{d.lander? d.lander.name : ''}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpCurr.dest[d.club.id] : null)}>{d.schedule? d.schedule.name : ''}</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table> }

                        </div>
                        
                        <div className={classes.panelRightLog}>

                        { prev_hobj &&
                        <Table aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>PREVIOUS LOG:</TableCell>
                                </TableRow>
                                <TableRow>
                                   <TableCell>Name</TableCell>
                                   <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={getStatusClass(cmpPrev.service)}>Service:</TableCell>
                                    <TableCell className={getStatusClass(cmpPrev.service)}>{prev_hobj.service? prev_hobj.service.name : '' }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={getStatusClass(cmpPrev.sourceCap)}>Source CAP:</TableCell>
                                    <TableCell className={getStatusClass(cmpPrev.sourceCap)}>{prev_hobj.sourceCap}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={getStatusClass(cmpPrev.cap_active)}>Is Active:</TableCell>
                                    <TableCell className={getStatusClass(cmpPrev.cap_active)}>{prev_hobj.cap_active? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        }
                        
                        { prev_hobj && prev_hobj.destinationCaps && !!prev_hobj.destinationCaps.length &&
                        <Table aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4}>Destination CAPs</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Index</TableCell>
                                    <TableCell>Dest. Club</TableCell>
                                    <TableCell>Dest. CAP</TableCell>
                                    <TableCell>Dest. Mode</TableCell>
                                    <TableCell>Dest. Landing Page</TableCell>
                                    <TableCell>Dest. Schedule</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { prev_hobj.destinationCaps.map((d, index) => 
                                <TableRow key={index}>
                                    <TableCell className={getStatusClass(d.club? cmpPrev.dest[d.club.id] : null)}>{index + 1}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpPrev.dest[d.club.id] : null)}>{d.club? d.club.name : ''}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpPrev.dest[d.club.id] : null)}>{d.cap}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpPrev.dest[d.club.id] : null)}>{d.mode}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpPrev.dest[d.club.id] : null)}>{d.lander? d.lander.name : ''}</TableCell>
                                    <TableCell className={getStatusClass(d.club? cmpPrev.dest[d.club.id] : null)}>{d.schedule? d.schedule.name : ''}</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table> }

                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default ({ record, ...props }) => {
    const [logs, setLogs] = useState([]);

    const notify = useNotify();

    useEffect(() => {
        if (!record) {
            return;
        }

        if (!(record.platform_aunique && !!record.platform_aunique.length)) {
            return;
        }
        
        let isCancelled = false;
        fetch(BACKEND_URL + '/cap_rotator_history/' + record.platform_aunique, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                } 
            })
            .then(response => response.json())
            .then(json => {
                if (json.history_logs) {
                    let newLogs = json.history_logs.map(log => ({ ...log, history_obj: JSON.parse(log.history_obj) }));
                    //console.log(newLogs);
                    if (!isCancelled) {
                        setLogs(newLogs);
                    }
                }
            })
            .catch(e => { 
                notify(e.message, 'warning'); 
            });
            
        return function cleanup() { 
            isCancelled = true;
        };      
    }, [record, notify]);

    const useStyles = makeStyles(theme => ({
        root: {
            marginTop: '1em'
        },
        panelDetails: {
            display: 'flex', 
            flexFlow: 'column nowrap',
            padding: 0,
        }
    }));

    const classes = useStyles();
    
    const compareLogDialog = useRef(null);
    
    const handleCompare = (currLog, prevLog) => {
        if (compareLogDialog && compareLogDialog.current) {
            compareLogDialog.current.setCurrLogPublic(currLog);
            compareLogDialog.current.setPrevLogPublic(prevLog);
            compareLogDialog.current.setOpenPublic(true);
        }
    };
    
    return (
        <div className={classes.root}>
            { logs.map((l, index_l) => {
                let prevLog = null;
                if ((index_l + 1) < logs.length) {
                    prevLog = logs[index_l + 1];
                } else {
                    prevLog = null;
                }
                let hobj = l.history_obj;
                
                return (
                <ExpansionPanel key={l.id} defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content">
                        <Typography className={classes.heading}>On <b>{ convertPHPDateToJS(l.datetime).toLocaleString('en-GB') }</b> <b>{ l.user.fullname }</b> saved these settings:</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        { hobj &&
                        <Table aria-labelledby="tableTitle" size="small">
                            <TableHead>
                                <TableRow>
                                   <TableCell>Name</TableCell>
                                   <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Service:</TableCell>
                                    <TableCell>{hobj.service? hobj.service.name : '' }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Source CAP:</TableCell>
                                    <TableCell>{hobj.sourceCap}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Is Active:</TableCell>
                                    <TableCell>{hobj.cap_active? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        }
                        
                        { hobj && hobj.destinationCaps && !!hobj.destinationCaps.length && 
                        <Table aria-labelledby="tableTitle" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4}>Destination CAPs</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Index</TableCell>
                                    <TableCell>Dest. Club</TableCell>
                                    <TableCell>Dest. CAP</TableCell>
                                    <TableCell>Dest. Mode</TableCell>
                                    <TableCell>Dest. Landing Page</TableCell>
                                    <TableCell>Dest. Schedule</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { hobj.destinationCaps.map((d, index) => 
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{d.club? d.club.name : ''}</TableCell>
                                    <TableCell>{d.cap}</TableCell>
                                    <TableCell>{d.mode}</TableCell>
                                    <TableCell>{d.lander? d.lander.name : ''}</TableCell>
                                    <TableCell>{d.schedule? d.schedule.name : ''}</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table> }
                        
                        { prevLog && <Button color="primary" varinat="contained" onClick={() => handleCompare(l, prevLog)}>Compare with Previous Log</Button> }

                    </ExpansionPanelDetails>
                </ExpansionPanel> ); }
            )}
            
            <CompareLogDialog ref={compareLogDialog} />
        </div>
    );
};
