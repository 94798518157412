import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, usePermissions, fetchStart, fetchEnd, TextInput, Title, SimpleForm, Toolbar, SaveButton, required } from 'react-admin';
import { BACKEND_URL } from '../common/constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BlockIcon from '@material-ui/icons/Block';

export default (props) => {
    const { permissions } = usePermissions();
    
    const hasAccess = permissions && permissions.priv && (permissions.priv >= 9) && permissions.areas && (permissions.areas.indexOf('clients') >= 0);
    
    const notify = useNotify();
    const dispatch = useDispatch();
    
    const [stoppedList, setStoppedList] = useState([]);
    
    const BulkStopToolbar = (bstbprops) => (
        <Toolbar {...bstbprops}>
            <SaveButton variant="contained" color="primary" label="Stop these MSISDNs" icon={<BlockIcon />} />
        </Toolbar>
    );
    
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        if (!(values && values.msisdn && !!values.msisdn.length)) {
            return;
        }
        
        const obj = { msisdn: values.msisdn };

        dispatch(fetchStart());
        fetch(BACKEND_URL + '/bulk_stop', { 
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.result && !!json.result.length) {
                            setStoppedList(json.result);
                        } else {
                            setStoppedList([]);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });
    };
        
    return (
        <Card>
            <Title title="Bulk Stop" />
            <CardContent>
                { hasAccess && 
                <div>
                    { stoppedList && !!stoppedList.length &&
                    <div>
                        <h4>Stop results</h4>
                        <ul>
                            { stoppedList.map((stoppedLine, index) =>
                                <li key={index}><b>{stoppedLine.msisdn}</b>: on club <b>{stoppedLine.club}</b>: <b>{stoppedLine.message}</b></li>
                            )}
                        </ul>
                    </div>
                    }
                    
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleSubmit}
                        toolbar={<BulkStopToolbar />}
                    >
                        <TextInput source="msisdn" label="MSISDN List" multiline rows={10} columns={50} validate={[required()]} />
                    </SimpleForm>
                </div> }
            </CardContent>
        </Card>
    );
};
