import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import jsonExport from 'jsonexport/dist';
import { useDataProvider, useNotify, usePermissions, fetchStart, fetchEnd, TextInput, SelectArrayInput, Title, SimpleForm, Toolbar, SaveButton, downloadCSV } from 'react-admin';
import { BACKEND_URL } from '../common/constants';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ExportIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

const SelectFieldsGroup = ({ columns, setFields, ...props }) => {
    const columnChoices = columns.map(col => ({ name: col }));

    const [formSelectFields, setFormSelectFields] = useState(null);

    const SelectFieldsToolbar = (sftbprops) => {
        const form = useForm();
        
        useEffect(() => {
            setFormSelectFields(form);
        }, [form]);
    
        return null;
    };

    const handleChangeSelectFields = (event) => {
        if (formSelectFields) {
            formSelectFields.submit();
        }
    };
    
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }
        
        if (values.fields && setFields) {
            setFields(values.fields);
        }
    };
        
    return (
        <SimpleForm
            resource="regions"
            onSubmit={handleSubmit}
            toolbar={<SelectFieldsToolbar />}
        >
            <SelectArrayInput 
                name="fields" 
                label="Select Fields" 
                choices={columnChoices} 
                optionText="name" 
                optionValue="name" 
                defaultValue={columns} 
                onChange={handleChangeSelectFields}
                fullWidth
            />
        </SimpleForm>
    );
};

export default (props) => {
    const { permissions } = usePermissions();
    
    const hasAccess = permissions && permissions.priv && (permissions.priv >= 10);
    
    const notify = useNotify();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    
    const { id } = useParams();
    const [/*adhocQuery*/, setAdhocQuery] = useState(null);
    const [formRunQuery, setFormRunQuery] = useState(null);
    
    useEffect(() => {
        if (!id || !formRunQuery || !dataProvider) {
            return;
        }
        
        dispatch(fetchStart());
        dataProvider.getOne('adhoc_queries', { id })
            .then(({ data }) => { 
                dispatch(fetchEnd());
                if (data) {
                    setAdhocQuery(data);
                    
                    if (formRunQuery) {
                        formRunQuery.change('query', data.query);
                    }
                } else {
                    setAdhocQuery(null);
                }
            })
            .catch(e => {
                dispatch(fetchEnd());
                notify(e.message, 'warning');
            })
    }, [dataProvider, id, formRunQuery, dispatch, notify]);
        
    const [result, setResult] = useState([]);
    const [searchFilter, setSearchFilter] = useState({});
    const [resultFilter, setResultFilter] = useState([]);
    const [resultPage, setResultPage] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const ExportResultsButton = (erbprops) => (
        <Button variant="contained" color="primary" style={{ marginLeft: '1em' }} onClick={handleExport} disabled={!(result && !!result.length)}><ExportIcon /> Export Results</Button>
    );
    
    const RunQueryToolbar = (rqtbprops) => {
        const form = useForm();
        
        useEffect(() => {
            setFormRunQuery(form);
        }, [form]);
        
        return (
        <Toolbar {...rqtbprops}>
            <SaveButton variant="contained" color="primary" label="Run Adhoc Query" icon={<PlayArrowIcon />} />
            <ExportResultsButton />
        </Toolbar>
        );
    };
    
    const handleSubmit = (values) => {
        //https://stackoverflow.com/questions/44030187/correct-way-to-check-if-any-object-is-a-syntheticevent
        if (values.nativeEvent && values.nativeEvent instanceof Event) {
            values.preventDefault();
            return false;
        }

        let url = null;
        if (values && values.query && !!values.query.length) {
            url = BACKEND_URL + '/run_adhoc_query/' + id + '?sql=' + encodeURIComponent(values.query);
        } else {
            url = BACKEND_URL + '/run_adhoc_query/' + id;
        }
                
        //const obj = { };

        dispatch(fetchStart());
        fetch(url, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('api_token')
                },
                //body: JSON.stringify(obj)
            })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(fetchEnd());
                        if (json.result && !!json.result.length) {
                            setResult([]); // to clear filters - which are uncontrolled components
                            setResult(json.result);
                            setSearchFilter({});
                            setResultFilter(json.result);
                            setPage(0);
                            setResultPage(json.result.slice(0, rowsPerPage));
                            setVisibleColumns(Object.keys(json.result[0]));
                        } else {
                            setResult([]);
                            setSearchFilter({});
                            setResultFilter([]);
                            setPage(0);
                            setResultPage([]);
                            setVisibleColumns([]);
                        }
                    });
                } else {
                    response.json().then(e => {
                        dispatch(fetchEnd());
                        notify(e.message, 'warning');
                    });
                }
            })
            .catch(e => {
                dispatch(fetchEnd()); 
                notify(e.message, 'warning'); 
            });
    };

    const handleExport = () => {
        if (!(result && !!result.length)) {
            return;
        }
        
        let columns = Object.keys(result[0]);
        
        jsonExport(result, {
            headers: columns // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, `adhoc_query_${id}`); // download as 'adhoc_query_XXX.csv` file
        });            
    };
    
    const useStylesPagination = makeStyles((theme) => ({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }));

    const TablePaginationActions = (tpaProps) => {
        const classes = useStylesPagination();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = tpaProps;

        const handleFirstPageButtonClick = (event) => {
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setResultPage(resultFilter.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage));
    };

    const handleChangeRowsPerPage = (event) => {
        let rpp = parseInt(event.target.value, 10);
        setRowsPerPage(rpp);
        setPage(0);
        setResultPage(resultFilter.slice(0, rpp));
    };
    
    const handleFilterChange = (event, key) => {
        if (key && !!key.length && event && event.target) {
            const newSearchFilter = Object.assign({}, searchFilter, { [key]: event.target.value });
            setSearchFilter(newSearchFilter);
            
            let newResultFilter = result.filter(r => {
                let ok = true;
                Object.keys(newSearchFilter).forEach(k => {
                    const fval = newSearchFilter[k];
                    const val = r[k];
                    if (fval && !!fval.length) {
                        if (((typeof val === 'string') || (val === null)) && !(val && !!val.length && (val.toLowerCase().indexOf(fval.toLowerCase()) >= 0))) {
                            ok = false;
                        }
                        if ((typeof val === 'number') && !(!isNaN(fval) && !isNaN(val) && (Number(fval) === Number(val)) )) {
                            ok = false;
                        }
                    }
                });
                return ok;
            });
            setResultFilter(newResultFilter);
            setPage(0);
            setResultPage(newResultFilter.slice(0, rowsPerPage));
        }
    }
    
    const [visibleColumns, setVisibleColumns] = useState([]);

    return (
        <Card>
            <Title title="Run AdHoc Query" />
            <CardContent>
                { hasAccess && 
                <div>
                    <SimpleForm 
                        resource="regions"
                        onSubmit={handleSubmit}
                        toolbar={<RunQueryToolbar />}
                    >
                        <TextInput source="query" label="Adhoc Query" multiline fullWidth rows={10} />
                    </SimpleForm>
                    
                    { (result && !!result.length)?
                    <div style={{ overflowX: 'auto' }}>
                        <SelectFieldsGroup columns={Object.keys(result[0])} setFields={setVisibleColumns} />

                        <Table aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                    {Object.keys(result[0]).map(k => 
                                        <TableCell style={{ display: visibleColumns.includes(k)? 'table-cell' : 'none' }} key={k}>{k}<br/><MuiTextField style={{ minWidth: '120px' }} onChange={(e) => handleFilterChange(e, k)}/></TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { resultPage.map((row, index) => (
                                <TableRow key={index}>
                                {Object.keys(row).map(k => {
                                    let val = row[k];
                                    if (val && !!val.length && (val.indexOf('href') >= 0)) {
                                        return (<TableCell style={{ display: visibleColumns.includes(k)? 'table-cell' : 'none' }} key={k} dangerouslySetInnerHTML={{__html: val }} />);
                                    } else {
                                        return (<TableCell style={{ display: visibleColumns.includes(k)? 'table-cell' : 'none' }} key={k}>{val}</TableCell>);
                                    }
                                })}
                                </TableRow>
                                )) }
                            </TableBody>
                        </Table>
                        <TablePagination 
                            rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]} 
                            component="div"
                            count={resultFilter.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div> :
                    
                    <div style={{ textAlign: 'center' }}>No data returned from query.</div>
                    }
                </div> }
            </CardContent>
        </Card>
    );
};
